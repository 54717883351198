import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import styled from '@emotion/styled';

import CategoryPickerProps from './CategoryPickerProps';
import { convertToTree } from '../../models/HierarchicalCategory';
import HierarchicalTreePicker from './HierarchicalTreePicker';

const CategoryPicker = ({
  className,
  categories,
  selectedCategories,
  onCategoryClick
}: CategoryPickerProps) => {
  const categoryTree = convertToTree(categories);

  return (
    <div className={className}>
      <Typography variant='h5'>Categories</Typography>
      <HierarchicalTreePicker
        categories={categoryTree}
        selectedCategories={selectedCategories}
        onCategoryClick={onCategoryClick}
        depth={0}
      />
    </div>
  );
};

const StyledCategoryPicker = styled(CategoryPicker)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 300px;
`;

export default StyledCategoryPicker;
