import { Close } from '@mui/icons-material';
import { Alert, IconButton } from '@mui/material';

const Toast = ({ label, onClose }: { label: string; onClose: () => void }) => {
  return (
    <Alert
      severity='error'
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={onClose}
        >
          <Close fontSize='inherit' />
        </IconButton>
      }
    >
      {label}
    </Alert>
  );
};

export default Toast;
