import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateUserMutation } from './__generated__/CreateUserMutation.graphql';

const createUserMutation = graphql`
  mutation CreateUserMutation(
    $email: String!
    $username: String
    $fullName: String
    $title: String
    $password: String!
    $role: String!
    $regionIds: [ID]
    $signatureImage: String
  ) {
    createUser(
      input: {
        email: $email
        username: $username
        fullName: $fullName
        title: $title
        password: $password
        role: $role
        regionIds: $regionIds
        signatureImage: $signatureImage
      }
    ) {
      id
      email
      username
      fullName
      title
      role
      signatureImage
      regions {
        id
        name
      }
    }
  }
`;

const useCreateUserMutation = () =>
  useMutation<CreateUserMutation>(createUserMutation);

export { useCreateUserMutation };
