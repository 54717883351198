import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import { useState } from 'react';

import MultiSelectInputProps from './MultiSelectInputProps';

const MultiSelectInput = ({
  label,
  options,
  selectedOptions,
  disabled,
  onSelect
}: MultiSelectInputProps) => {
  const [values, setValues] = useState<string[]>(selectedOptions);
  return (
    <FormControl margin='dense' fullWidth>
      <InputLabel id='multi-select-label'>{label}</InputLabel>
      <Select
        labelId='multi-select-label'
        multiple
        disabled={disabled}
        // This component's props cause a type error, which is a bug in MUI.
        // Select components with the multiselect prop set to true must take
        // arrays as their value, but the type wants a string.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={values}
        onChange={(event: SelectChangeEvent) => {
          const newValues = event.target.value as unknown as string[];
          setValues(newValues);
          if (onSelect) onSelect(newValues);
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          const selectedValues = selected as unknown as string[];
          return selectedValues.join(', ');
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={values.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectInput;
