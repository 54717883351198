import { useEffect, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';

import DataToolbar from '../../components/DataToolbar';
import DataTable from '../../components/DataTable';
import JobDialog from '../../dialogs/JobDialog/JobDialog';
import JobCategoriesDialog from '../../dialogs/JobCategoriesDialog';

import { useGetJobCategoriesQuery } from '../../api/jobCategories/GetJobCategoriesQuery';
import JobStatus from '../../models/JobStatus';
import Button from '../../components/Button';
import HierarchicalCategory from '../../models/HierarchicalCategory';
import { useGetJobsQuery } from '../../api/jobs/GetJobsQuery';
import { useCreateJobMutation } from '../../api/jobs/CreateJobMutation';
import { useUpdateJobMutation } from '../../api/jobs/UpdateJobMutation';
import { useDeleteJobMutation } from '../../api/jobs/DeleteJobMutation';
import { useAuthentication } from '../../api/authentication';
import { useGetSitesQuery } from '../../api/sites/GetSitesQuery';
import Customer from '../../models/Customer';
import TestObjectsDialog from '../../dialogs/TestObjectsDialog/TestObjectsDialog';
import GeneratePDFDialog from '../../dialogs/GeneratePDFDialog';
import { useCopyJobMutation } from '../../api/jobs/CopyJobMutation';
import CopyJobDialog from '../../dialogs/CopyJobDialog';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';
import { DeleteConfirmationDialog } from '../../dialogs/AlertDialog';

const statuses = [
  'All',
  ...Object.keys(JobStatus).filter((x) => isNaN(Number(x)))
];

const JobPage = () => {
  const { user } = useAuthentication();

  const [selectedId, setSelectedId] = useState('');
  const [showAddJobDialog, setShowAddJobDialog] = useState(false);
  const [showEditJobDialog, setShowEditJobDialog] = useState(false);
  const [showCopyJobDialog, setShowCopyJobDialog] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const [showJobCategoriesDialog, setShowJobCategoriesDialog] = useState(false);
  const [showTestObjectsDialog, setShowTestObjectsDialog] = useState(false);
  const [showGeneratePDFDialog, setShowGeneratePDFDialog] = useState(false);
  const [searchFilterCondition, setSearchFilterCondition] = useState('');
  const [statusFilterCondition, setStatusFilterCondition] =
    useState('in process');

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const jobs = useGetJobsQuery({ fetchKey: queryFetchKey });
  const sites = useGetSitesQuery({ fetchKey: queryFetchKey });
  const jobCategories = useGetJobCategoriesQuery({
    fetchKey: queryFetchKey
  });

  const [createJob, createJobLoading] = useCreateJobMutation();
  const [updateJob, updateJobLoading] = useUpdateJobMutation();
  const [deleteJob, deleteJobLoading] = useDeleteJobMutation();
  const [copyJob, copyJobLoading] = useCopyJobMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (
      !createJobLoading &&
      !updateJobLoading &&
      !deleteJobLoading &&
      !copyJobLoading
    ) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createJobLoading, updateJobLoading, deleteJobLoading, copyJobLoading]);

  // if user is a proctor, limit the jobs visible to only jobs that match the user's region.
  let regionLimitedJobs = jobs;
  if (user?.role === 'proctor') {
    const regionRestrictedJobs = jobs.filter((job) => {
      if (job.regions && user?.regions && user?.regions !== null) {
        let matchFound = false;
        // use for loop so we can break out as soon as a match is found.
        for (let i = 0; i < user?.regions.length; i++) {
          const userReg = user?.regions[i];
          const matches = job.regions.filter((jobReg) => {
            return jobReg?.id.toString() === userReg?.id?.toString();
          });
          if (matches.length > 0) {
            matchFound = true;
            break;
          }
        }
        return matchFound;
      }
      return false;
    });
    regionLimitedJobs = regionRestrictedJobs;
  }

  const cellClassName = (params: GridCellParams) =>
    params.row.status?.toLowerCase() === 'closed' ? 'disabled' : 'enabled';

  const columns = [
    { field: 'key', headerName: 'ID', flex: 1, cellClassName },
    { field: 'description', headerName: 'Description', flex: 1, cellClassName },
    {
      field: 'customerKey',
      headerName: 'Customer Key',
      flex: 1,
      cellClassName,
      valueGetter: ({ row }: { row: { customer: Customer } }) =>
        row.customer.key
    },
    {
      field: 'jobCategory',
      headerName: 'Category',
      flex: 1,
      valueGetter: ({ row }: { row: { jobCategoryId: string } }) => {
        return (
          jobCategories.find((category) => category.id === row.jobCategoryId)
            ?.name ?? 'None'
        );
      },
      cellClassName
    },
    { field: 'status', headerName: 'Status', flex: 1, cellClassName }
  ];

  const categories = [
    { id: '0', name: 'All', parentId: null },
    ...jobCategories
  ];

  const isDescendantOf = (
    row: any,
    categories: HierarchicalCategory[]
  ): boolean => {
    return (
      categories.some((category) => category.id === row.jobCategoryId) ||
      categories.some(
        (category) =>
          category.children && isDescendantOf(row, category.children)
      )
    );
  };

  const categoryFilterCondition = (
    row: any,
    selectedCategories: HierarchicalCategory[]
  ) => {
    return selectedCategories.some(
      (category) =>
        category.id === row.jobCategoryId ||
        (category.children && isDescendantOf(row, category.children))
    );
  };

  const filteredJobs = regionLimitedJobs.filter((job) => {
    return (
      job.key.toLocaleLowerCase().includes(searchFilterCondition) &&
      (job.status.toLocaleLowerCase().includes(statusFilterCondition) ||
        statusFilterCondition === 'all')
    );
  });

  const selectedJob = regionLimitedJobs?.find((job) => job.id === selectedId);

  return (
    <>
      {showAddJobDialog && (
        <JobDialog
          categories={jobCategories}
          onCancel={() => setShowAddJobDialog(false)}
          onSubmit={(job) => {
            createJob({
              variables: { ...job },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddJobDialog(false);
          }}
          sites={sites}
          jobs={filteredJobs}
        />
      )}
      {showEditJobDialog && (
        <JobDialog
          job={regionLimitedJobs?.find((job) => job.id === selectedId)}
          categories={jobCategories}
          onCancel={() => setShowEditJobDialog(false)}
          onSubmit={(job) => {
            updateJob({
              variables: { ...job },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowEditJobDialog(false);
          }}
          sites={sites}
          jobs={filteredJobs}
        />
      )}
      {showCopyJobDialog && selectedJob && (
        <CopyJobDialog
          job={selectedJob}
          onCancel={() => setShowCopyJobDialog(false)}
          onSubmit={(variables) => {
            copyJob({
              variables,
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowCopyJobDialog(false);
          }}
        />
      )}
      {showDeleteConfirmationDialog && (
        <DeleteConfirmationDialog
          objectName={selectedJob?.key ?? ''}
          onCancel={() => setShowDeleteConfirmationDialog(false)}
          onDelete={() => {
            if (selectedJob) {
              deleteJob({
                variables: { id: selectedJob.id },
                onCompleted: (_response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                  }
                }
              });
            }
            setShowDeleteConfirmationDialog(false);
          }}
        />
      )}
      {showJobCategoriesDialog && (
        <JobCategoriesDialog
          onSubmit={() => setShowJobCategoriesDialog(false)}
        />
      )}
      {showTestObjectsDialog && (
        <TestObjectsDialog onSubmit={() => setShowTestObjectsDialog(false)} />
      )}
      {/* 
      // Removed since this already exists in the job dialog, which aligns with EBMS.
      // It seemed to be an unnecessary additional thing to maintain it out in the job page.
      // Leaving commented out in case customer wants to re-enable in the future.

      {selectedJob && showGeneratePDFDialog && (
        <GeneratePDFDialog
          jobId={selectedJob.id}
          onCancel={() => setShowGeneratePDFDialog(false)}
          onSubmit={() => setShowGeneratePDFDialog(false)}
          participantData={selectedJob.participants}
          job={selectedJob}
        />
      )} */}
      <DataToolbar
        dataType='jobs'
        statuses={statuses}
        defaultStatus='In Process'
        selectedId={selectedId}
        onSearchChange={(value) =>
          setSearchFilterCondition(value.toLocaleLowerCase())
        }
        onStatusChange={(value) =>
          setStatusFilterCondition(value.toLocaleLowerCase())
        }
        onAdd={() => setShowAddJobDialog(true)}
        onEdit={() => setShowEditJobDialog(true)}
        onDelete={() => setShowDeleteConfirmationDialog(true)}
        onView={
          user?.role === 'proctor' || user?.role === 'sales'
            ? () => setShowEditJobDialog(true)
            : undefined
        }
      >
        <>
          {/* <Button onClick={() => setShowGeneratePDFDialog(true)}>
            Generate Report
          </Button> */}
          {user?.role === 'admin' && (
            <>
              <Button onClick={() => setShowJobCategoriesDialog(true)}>
                Categories <EditIcon />
              </Button>
              <Button onClick={() => setShowTestObjectsDialog(true)}>
                Test Objects <EditIcon />
              </Button>
              <Button
                onClick={() => setShowCopyJobDialog(true)}
                disabled={!selectedId}
              >
                Copy
              </Button>
            </>
          )}
        </>
      </DataToolbar>
      <DataTable
        columns={columns}
        rows={filteredJobs}
        categories={categories}
        categoryFilterCondition={categoryFilterCondition}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Job Record'}
          errorBody={'There was an error submitting the Job record.'}
        />
      )}
    </>
  );
};

export default JobPage;
