import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Tabs,
  Tab,
  FormControl,
  styled
} from '@mui/material';
import { Suspense, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  filterEmptyDescriptions,
  filterEmptyAgeGroups
} from '../../utils/validators';
import { fileToBase64 } from '../../utils/fileHandlers';
import ParticipantsPanel from './ParticipantsPanel/ParticipantsPanel';
import GeneralPanel from './GeneralPanel';
import TestListPanel from './TestListPanel';
import SamplePicturePanel from './SamplePicturePanel';
import MethodOfUse from '../../models/MethodOfUse';
import Procedure from '../../models/Procedures';
import AgeGroup from '../../models/AgeGroup';
import EaseOfUseRating from '../../models/EaseOfUseRating';
import TestSettingsPanel from './TestSettingsPanel';
import JobDialogProps from './JobDialogProps';
import GeneratePDFDialog from '../GeneratePDFDialog';
import Region from '../../models/Region';
import { MissingJobFields } from './RequiredFieldsDialogProps';
import RequiredFieldsDialog from './RequiredFieldsDialog';
import { useAuthentication } from '../../api/authentication';
import AlertDialog from '../AlertDialog';
import { useGetProctorsQuery } from '../../api/proctors/GetProctorsQuery';
import {
  defaultAdultMaxParticipantsPerProctor,
  defaultAdultMaxParticipantsPerSite
} from '../../models/Job';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    height: calc(100% - 64px);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

const TabBar = styled(Box)`
  border-bottom: 1;
  border-color: rgba(0, 0, 0, 0.23);
`;

const PanelActions = styled('div')`
  flex: 1;
`;

const addId = (row: any, index: number) => {
  const newRow = { id: String(index), ...row };
  return newRow;
};

const removeId = (row: any) => {
  const { id, ...newRow } = row;
  return newRow;
};

const JobDialog = ({
  job,
  jobs,
  categories,
  onCancel,
  onSubmit,
  sites
}: JobDialogProps) => {
  let childKey = '';
  if (job?.children && job?.children.length) {
    childKey = job.children[0].key;
  }
  const { user } = useAuthentication();
  const [currentTab, setCurrentTab] = useState(0);
  const [customerId, setCustomerId] = useState(job?.customer.id || '');
  const [childJobKey, setChildJobKey] = useState<string | null>(childKey);
  const [parentJobKey, setParentJobKey] = useState(job?.parent?.key || null);
  const [jobCategoryId, setJobCategoryId] = useState(job?.jobCategoryId || '');
  const [preparedById, setPreparedById] = useState(job?.preparedBy?.id || '');
  const [status, setStatus] = useState(job?.status || 'Tentative');
  const [testType, setTestType] = useState(job?.testType || 'Adult');
  const [testObjectId, setTestObjectId] = useState(job?.testObjectId || '');
  const [samplePicture, setSamplePicture] = useState(job?.samplePicture);
  const [selectedRegions, setSelectedRegions] = useState<readonly Region[]>(
    job?.regions ? job.regions : []
  );
  const [methodsOfUse, setMethodsOfUse] = useState<readonly MethodOfUse[]>(
    job ? [...job.methodsOfUse.map(addId)] : []
  );
  const [procedures, setProcedures] = useState<readonly Procedure[]>(
    job ? [...job.procedures.map(addId)] : []
  );
  const [easeOfUseRatings, setEaseOfUseRatings] = useState<
    readonly EaseOfUseRating[]
  >(job ? [...job.easeOfUseRatings.map(addId)] : []);
  const [ageGroups, setAgeGroups] = useState<readonly AgeGroup[]>(
    job ? [...job.ageGroups.map(addId)] : []
  );
  const [showJobMissingFields, setShowJobMissingFields] = useState(false);
  const [missingFields, setMissingFields] = useState<MissingJobFields | null>(
    null
  );
  type queryOptions = {
    fetchKey: number;
    fetchPolicy: string;
  };
  const [
    refreshedParticipantQueryOptions,
    setRefreshedParticipantQueryOptions
  ] = useState<queryOptions>({
    fetchKey: 0,
    fetchPolicy: 'network-only'
  });
  const [showGeneratePDFDialog, setShowGeneratePDFDialog] = useState(false);
  const [showJobCategoryChangedDialog, setShowJobCategoryChangedDialog] =
    useState(false);

  const proctors = useGetProctorsQuery(refreshedParticipantQueryOptions ?? {});

  // state is managed here for manually refreshing the participants data within the participants component.
  // see documentation here: https://relay.dev/docs/guided-tour/refetching/refreshing-queries/#when-using-uselazyloadquery
  const refreshParticipants = useCallback(() => {
    setRefreshedParticipantQueryOptions((prev) => ({
      fetchKey: (prev.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only'
    }));
  }, []);

  const defaultValues = {
    key: job?.key || '',
    description: job?.description || '',
    notes: job?.notes || '',
    sampleType: job?.sampleType || '',
    submitter: job?.submitter || '',
    participantType: job?.participantType || '',
    requiredParticipants: job?.requiredParticipants || 0,
    numberOfParticipants: job?.numberOfParticipants || 0,
    maxParticipantsPerSite:
      job?.maxParticipantsPerSite ?? defaultAdultMaxParticipantsPerSite,
    maxParticipantsPerProctor:
      job?.maxParticipantsPerProctor ?? defaultAdultMaxParticipantsPerProctor,
    customerTest: job?.customerTest || '',
    reportTree: job?.reportTree || 0,
    requiredTorque: job?.requiredTorque || 0,
    updatedAt: job?.updatedAt
      ? new Date(job?.updatedAt).toLocaleDateString()
      : '',
    updatedBy: job?.updatedBy?.username || '',
    disclaimer: job?.disclaimer || '',
    otherInfo: job?.otherInfo || '',
    reportHistory: job?.reportHistory || '',
    testDescription: job?.testDescription || '',
    objectives: job?.objectives || '',
    procedureNotes: job?.procedureNotes || '',
    resultsDiscussion: job?.resultsDiscussion || '',
    endDate: job?.endDate
      ? new Date(job.endDate).toISOString().split('T')[0]
      : ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ defaultValues });

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    const file = event.target.files[0];
    const base64String = await fileToBase64(file);
    setSamplePicture(base64String);
  };
  const customerJobs = jobs.filter((evalJob) => {
    return evalJob.customerId === job?.customerId && evalJob.id !== job.id;
  });
  const possibleRelatedJobs = customerJobs.map((jobObj) => {
    return { id: jobObj.id ? jobObj.id : '', key: jobObj.key };
  });

  const checkMissingFields = (data: any) => {
    const missingRequiredFields: MissingJobFields = {
      general: [],
      testSettings: [],
      testLists: [],
      samplePicture: [],
      participants: []
    };
    let isMissingFields = false;
    if (!data.key) {
      missingRequiredFields.general.push('Key');
      isMissingFields = true;
    }
    if (!data.customerId) {
      missingRequiredFields.general.push('Customer');
      isMissingFields = true;
    }
    if (!data.jobCategoryId) {
      missingRequiredFields.general.push('Category');
      isMissingFields = true;
    }
    if (!data.preparedById) {
      missingRequiredFields.general.push('Prepared By');
      isMissingFields = true;
    }
    if (!data.regionIds || data.regionIds.length === 0) {
      missingRequiredFields.general.push('Region');
      isMissingFields = true;
    }
    if (!data.status) {
      missingRequiredFields.general.push('Status');
      isMissingFields = true;
    }
    return {
      isMissingFields: isMissingFields,
      missingFields: missingRequiredFields
    };
  };

  // Our permission architecture currently does not support having permission
  // to view only parts of an object, so we must manually check here.
  const hasViewPermission = user?.role === 'admin' || user?.role === 'sales';

  const jobCategory = categories.find(
    (category) => category.id === jobCategoryId
  );

  return (
    <>
      {job && showGeneratePDFDialog && (
        <GeneratePDFDialog
          jobId={job.id}
          reportType={jobCategory?.reportType}
          onCancel={() => setShowGeneratePDFDialog(false)}
          onSubmit={() => setShowGeneratePDFDialog(false)}
          participantData={job.participants}
          sites={sites}
          proctors={proctors}
          job={job}
        />
      )}
      {showJobCategoryChangedDialog && (
        <AlertDialog
          title='Job Category Changed'
          content="Do you want to update this job to use the new job category's test list settings?"
          cancelLabel='No'
          submitLabel='Yes'
          onCancel={() => setShowJobCategoryChangedDialog(false)}
          onSubmit={() => {
            const jobCategory = categories.find(
              (category) => category.id === jobCategoryId
            );
            if (jobCategory) {
              setMethodsOfUse(jobCategory.methodsOfUse);
              setProcedures(jobCategory.procedures);
              setEaseOfUseRatings(jobCategory.easeOfUseRatings);
              setAgeGroups(jobCategory.ageGroups);
            }
            setShowJobCategoryChangedDialog(false);
          }}
        />
      )}
      <StyledDialog fullScreen open>
        <DialogTitle>{job ? 'Edit Job' : 'Add Job'}</DialogTitle>
        <StyledDialogContent>
          <TabBar>
            <Tabs
              value={currentTab}
              onChange={(_event, value) => setCurrentTab(value)}
              aria-label='tabs'
            >
              {hasViewPermission && <Tab label='General' value={0} />}
              {hasViewPermission && <Tab label='Test Settings' value={1} />}
              {hasViewPermission && <Tab label='Test Lists' value={2} />}
              {hasViewPermission && <Tab label='Sample Picture' value={3} />}
              <Tab label='Participants' value={hasViewPermission ? 4 : 0} />
            </Tabs>
          </TabBar>
          {hasViewPermission && currentTab === 0 && (
            <GeneralPanel
              register={register}
              errors={errors}
              customerId={customerId}
              setCustomerId={setCustomerId}
              categories={categories}
              jobCategoryId={jobCategoryId}
              setJobCategoryId={setJobCategoryId}
              onJobCategoryChange={() => setShowJobCategoryChangedDialog(true)}
              preparedById={preparedById}
              setPreparedById={setPreparedById}
              selectedRegions={selectedRegions}
              setSelectedRegions={setSelectedRegions}
              status={status}
              setStatus={setStatus}
              childJobKey={childJobKey}
              setChildJobKey={setChildJobKey}
              parentJobKey={parentJobKey}
              setParentJobKey={setParentJobKey}
              jobs={possibleRelatedJobs}
            />
          )}
          {hasViewPermission && currentTab === 1 && (
            <TestSettingsPanel
              register={register}
              errors={errors}
              testType={testType}
              setTestType={setTestType}
              testObjectId={testObjectId}
              setTestObjectId={setTestObjectId}
              numberOfParticipants={job?.participants?.length ?? 0}
              setValue={setValue}
            />
          )}
          {hasViewPermission && currentTab === 2 && (
            <TestListPanel
              methodsOfUse={methodsOfUse}
              procedures={procedures}
              easeOfUseRatings={easeOfUseRatings}
              ageGroups={ageGroups}
              setMethodsOfUse={setMethodsOfUse}
              setProcedures={setProcedures}
              setEaseOfUseRatings={setEaseOfUseRatings}
              setAgeGroups={setAgeGroups}
            />
          )}
          {hasViewPermission && currentTab === 3 && (
            <SamplePicturePanel samplePicture={samplePicture} />
          )}
          {currentTab === (hasViewPermission ? 4 : 0) && (
            <Suspense fallback='Loading Participant Records'>
              <ParticipantsPanel
                job={job}
                sites={sites}
                proctors={proctors}
                refreshParticipants={refreshParticipants}
                queryOptions={refreshedParticipantQueryOptions ?? {}}
              />
            </Suspense>
          )}
        </StyledDialogContent>
        <DialogActions>
          <PanelActions>
            <Button onClick={() => setShowGeneratePDFDialog(true)}>
              Generate Report
            </Button>
            {currentTab === 3 && (
              <FormControl margin='none'>
                <input
                  style={{ display: 'none' }}
                  id='contained-button-file'
                  type='file'
                  accept='image/png, image/jpeg'
                  onChange={handleFileSelect}
                />
                <label htmlFor='contained-button-file'>
                  <Button color='primary' component='span'>
                    Upload
                  </Button>
                </label>
              </FormControl>
            )}
          </PanelActions>
          <Button onClick={onCancel}>
            {user?.role === 'admin' ? 'Cancel' : 'Close'}
          </Button>
          {user?.role === 'admin' && (
            <Button
              onClick={handleSubmit((fields) => {
                const relatedJobs = jobs.filter(
                  (eachJob) => eachJob.key === childJobKey
                );
                const childJobIds =
                  relatedJobs.length > 0 ? [relatedJobs[0].id] : [];
                const dataToSubmit = {
                  ...fields,
                  id: job?.id,
                  endDate: new Date(fields.endDate),
                  customerId,
                  jobCategoryId,
                  preparedById,
                  regionIds: selectedRegions.map(
                    (region) => region?.id as string
                  ),
                  testObjectId,
                  samplePicture,
                  status,
                  testType,
                  methodsOfUse: methodsOfUse
                    .filter(filterEmptyDescriptions)
                    .map(removeId),
                  procedures: procedures
                    .filter(filterEmptyDescriptions)
                    .map(removeId),
                  easeOfUseRatings: easeOfUseRatings
                    .filter(filterEmptyDescriptions)
                    .map(removeId),
                  ageGroups: ageGroups
                    .filter(filterEmptyAgeGroups)
                    .map((group) => ({
                      groupNumber: Number(group.groupNumber),
                      beginAge: Number(group.beginAge),
                      endAge: Number(group.endAge),
                      groupPercentage: Number(group.groupPercentage)
                    })),
                  childrenIds: childJobIds
                };
                const validatedFields = checkMissingFields(dataToSubmit);
                if (validatedFields.isMissingFields) {
                  setMissingFields(validatedFields.missingFields);
                  setShowJobMissingFields(true);
                } else {
                  onSubmit(dataToSubmit);
                }
              })}
            >
              Submit
            </Button>
          )}
          {showJobMissingFields && (
            <RequiredFieldsDialog
              hideDialog={() => setShowJobMissingFields(false)}
              missingFields={missingFields}
            />
          )}
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default JobDialog;
