import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useForm } from 'react-hook-form';

import CustomerDialogProps from './CustomerDialogProps';
import CustomerStatus from '../../models/CustomerStatus';

const CustomerDialog = ({
  customer,
  categories,
  onCancel,
  onSubmit
}: CustomerDialogProps) => {
  const [categoryId, setCategoryId] = useState(customer?.categoryId || 'None');
  const [status, setStatus] = useState(customer?.status || 'Active');

  const defaultValues = {
    key: customer?.key || '',
    name: customer?.name || '',
    address: customer?.address || '',
    address2: customer?.address2 || '',
    city: customer?.city || '',
    state: customer?.state || '',
    zip: customer?.zip || ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>{customer ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
      <DialogContent>
        <TextField
          label='ID'
          type='text'
          margin='dense'
          helperText={errors.key ? 'Field is required.' : ''}
          error={!!errors.key}
          fullWidth
          autoFocus
          {...register('key', { required: true })}
        />
        <TextField
          label='Name'
          type='text'
          margin='dense'
          helperText={errors.name ? 'Field is required.' : ''}
          error={!!errors.name}
          fullWidth
          {...register('name', { required: true })}
        />
        <FormControl margin='dense' fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            label='Category'
            value={categoryId}
            onChange={(event) => setCategoryId(event.target.value)}
          >
            <MenuItem value={'None'}>None</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Address'
          type='text'
          margin='dense'
          fullWidth
          {...register('address')}
        />
        <TextField
          label='Address 2'
          type='text'
          margin='dense'
          fullWidth
          {...register('address2')}
        />
        <TextField
          label='City'
          type='text'
          margin='dense'
          fullWidth
          {...register('city')}
        />
        <TextField
          label='State'
          type='text'
          margin='dense'
          fullWidth
          {...register('state')}
        />
        <TextField
          label='Zip'
          type='text'
          margin='dense'
          fullWidth
          {...register('zip')}
        />
        <FormControl margin='dense' fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            label='Status'
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            {Object.keys(CustomerStatus)
              .filter((x) => isNaN(Number(x)))
              .map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({
              id: customer?.id,
              categoryId: categoryId === 'None' ? null : categoryId,
              status,
              ...fields
            });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDialog;
