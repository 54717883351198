interface MethodOfUse {
  id?: string;
  description: string;
}

const defaultMethodsOfUse = [
  { id: '0', description: 'Did Not Open 1st Pkg' },
  { id: '1', description: 'Did Not Open 2nd Pkg' },
  { id: '2', description: 'Normal' }
];

export default MethodOfUse;
export { defaultMethodsOfUse };
