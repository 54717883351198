import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  styled,
  Typography
} from '@mui/material';

import JobCategoriesDialogProps from './JobCategoriesDialogProps';
import { useGetJobCategoriesQuery } from '../../api/jobCategories/GetJobCategoriesQuery';
import { useCreateJobCategoryMutation } from '../../api/jobCategories/CreateJobCategoryMutation';
import { useUpdateJobCategoryMutation } from '../../api/jobCategories/UpdateJobCategoryMutation';
import { useDeleteJobCategoryMutation } from '../../api/jobCategories/DeleteJobCategoryMutation';
import MutateJobCategoryDialog from '../MutateJobCategoryDialog';
import { convertToTree } from '../../models/HierarchicalCategory';
import JobCategory from '../../models/JobCategory';
import HierarchicalList from './HierarchicalList';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../ErrorMessageDialog/ErrorMessageDialog';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 500px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 88px;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

const CategoryActions = styled('div')`
  display: flex;
`;

const JobCategoriesDialog = ({ onSubmit }: JobCategoriesDialogProps) => {
  const [showAddJobCategoryDialog, setShowAddJobCategoryDialog] =
    useState(false);
  const [showEditJobCategoryDialog, setShowEditJobCategoryDialog] =
    useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const categories = useGetJobCategoriesQuery({ fetchKey: queryFetchKey });
  const [createJobCategory, createJobCategoryLoading] =
    useCreateJobCategoryMutation();
  const [updateJobCategory, updateJobCategoryLoading] =
    useUpdateJobCategoryMutation();
  const [deleteJobCategory, deleteJobCategoryLoading] =
    useDeleteJobCategoryMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (
      !createJobCategoryLoading &&
      !updateJobCategoryLoading &&
      !deleteJobCategoryLoading
    ) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [
    createJobCategoryLoading,
    updateJobCategoryLoading,
    deleteJobCategoryLoading
  ]);

  const categoryTree = convertToTree<JobCategory>(categories);

  const selectedCategory = categories.find(
    (category) => category.id === selectedId
  );

  return (
    <StyledDialog maxWidth='xl' open>
      {showAddJobCategoryDialog && (
        <MutateJobCategoryDialog
          parentJobCategories={categories}
          onCancel={() => setShowAddJobCategoryDialog(false)}
          onSubmit={(category) => {
            createJobCategory({
              variables: { ...category },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddJobCategoryDialog(false);
          }}
        />
      )}
      {showEditJobCategoryDialog && selectedCategory && (
        <MutateJobCategoryDialog
          jobCategory={selectedCategory}
          parentJobCategories={categories}
          onCancel={() => setShowEditJobCategoryDialog(false)}
          onSubmit={(category) => {
            if (category.id) {
              updateJobCategory({
                variables: {
                  id: category.id,
                  ...category
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditJobCategoryDialog(false);
            }
          }}
        />
      )}
      <DialogTitle>Job Categories</DialogTitle>
      <StyledDialogContent>
        {categoryTree.length === 0 && <Typography>No rows</Typography>}
        {categoryTree.length > 0 && (
          <HierarchicalList
            categories={categoryTree}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            depth={0}
          />
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <CategoryActions>
          <Button onClick={() => setShowAddJobCategoryDialog(true)}>Add</Button>
          <Button
            onClick={() => {
              if (selectedId !== null) setShowEditJobCategoryDialog(true);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              if (selectedId !== null) {
                deleteJobCategory({
                  variables: { id: selectedId },
                  onCompleted: (response, errors) => {
                    if (
                      errors &&
                      errors[0].message.includes(
                        'Foreign key constraint failed'
                      )
                    ) {
                      setIsDelete(true);
                      setShowErrorDialog(true);
                    }
                  },
                  onError: (error) => {
                    console.log('error', error);
                    Sentry.captureException(error);
                  }
                });
              }
            }}
          >
            Delete
          </Button>
        </CategoryActions>
        <Button onClick={onSubmit}>Ok</Button>
      </StyledDialogActions>
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
            setIsDelete(false);
          }}
          errorTitle={`Error ${
            isDelete ? 'Deleting' : 'Submitting'
          } Job Category`}
          errorBody={
            isDelete
              ? 'Unable to delete Job Category that is in use.'
              : 'There was an error submitting the Job Category record.'
          }
        />
      )}
    </StyledDialog>
  );
};

export default JobCategoriesDialog;
