import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateUserMutation } from './__generated__/UpdateUserMutation.graphql';

const updateUserMutation = graphql`
  mutation UpdateUserMutation(
    $id: ID!
    $email: String!
    $username: String
    $fullName: String
    $title: String
    $password: String
    $role: String
    $isActive: Boolean
    $regionIds: [ID]
    $signatureImage: String
  ) {
    updateUser(
      input: {
        id: $id
        email: $email
        username: $username
        fullName: $fullName
        title: $title
        password: $password
        role: $role
        isActive: $isActive
        regionIds: $regionIds
        signatureImage: $signatureImage
      }
    ) {
      id
      email
      username
      fullName
      title
      role
      isActive
      signatureImage
      regions {
        id
        name
      }
    }
  }
`;

const useUpdateUserMutation = () =>
  useMutation<UpdateUserMutation>(updateUserMutation);

export { useUpdateUserMutation };
