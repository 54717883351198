import { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { useAuthentication } from './api/authentication';
import theme from './theme';
import RelayEnvironment from './api/RelayEnvironment';
import { withPermission } from './components/Permissions';
import CustomerPage from './pages/CustomerPage';
import JobPage from './pages/JobPage';
import LoginPage from './pages/LoginPage';
import UserPage from './pages/UserPage';
import RegionPage from './pages/RegionPage';
import SitePage from './pages/SitePage';
import ProctorPage from './pages/ProctorPage';
import PageContainer from './components/PageContainer';
import Header from './components/Header';

const CustomerPageWithPermission = withPermission(CustomerPage);
const UserPageWithPermission = withPermission(UserPage);
const JobPageWithPermission = withPermission(JobPage);
const RegionPageWithPermission = withPermission(RegionPage);
const SitePageWithPermission = withPermission(SitePage);
const ProctorPageWithPermission = withPermission(ProctorPage);

function App() {
  const { user, login } = useAuthentication();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route
                path='/'
                element={
                  <>
                    {user && <Navigate to='/jobs' />}
                    {!user && <Navigate to='/login' />}
                  </>
                }
              />
              <Route path='/login' element={<LoginPage login={login} />} />
              <Route
                element={
                  <PageContainer maxWidth={false}>
                    <Header user={user} />
                    <Outlet />
                  </PageContainer>
                }
              >
                <Route
                  path='/customers'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <CustomerPageWithPermission
                        role={user?.role}
                        type='customers'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='/users'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <UserPageWithPermission
                        role={user?.role}
                        type='users'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='/jobs'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <JobPageWithPermission
                        role={user?.role}
                        type='jobs'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='/regions'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <RegionPageWithPermission
                        role={user?.role}
                        type='regions'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='/sites'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <SitePageWithPermission
                        role={user?.role}
                        type='sites'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
                <Route
                  path='/proctors'
                  element={
                    <Suspense fallback={<>Loading...</>}>
                      <ProctorPageWithPermission
                        role={user?.role}
                        type='proctors'
                        operation='read'
                      />
                    </Suspense>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </ThemeProvider>
      </RelayEnvironmentProvider>
    </LocalizationProvider>
  );
}

export default App;
