import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  styled,
  Autocomplete
} from '@mui/material';
import permissions from 'config/permissions';

import GeneralPanelProps from './GeneralPanelProps';
import JobStatus from '../../models/JobStatus';
import { useGetRegionsQuery } from '../../api/regions/GetRegionsQuery';
import MultiSelectInput from '../../components/MultiSelectInput';
import { useGetCustomersQuery } from '../../api/customers/GetCustomersQuery';
import { useGetUsersQuery } from '../../api/users/GetUsersQuery';
import { useAuthentication } from '../../api/authentication';

const TextAreaContainer = styled('div')`
  display: flex;
  flex: 1;
  padding-top: 16px;
`;

const FullHeightTextField = styled(TextField)`
  flex: 1;

  .MuiInputBase-root {
    flex: 1;

    textarea {
      align-self: start;
    }
  }
`;

const GeneralPanel = ({
  register,
  errors,
  customerId,
  setCustomerId,
  childJobKey,
  setChildJobKey,
  categories,
  jobCategoryId,
  setJobCategoryId,
  onJobCategoryChange,
  preparedById,
  setPreparedById,
  selectedRegions,
  setSelectedRegions,
  status,
  setStatus,
  jobs,
  parentJobKey
}: GeneralPanelProps) => {
  const { user } = useAuthentication();

  const customers = useGetCustomersQuery({ fetchKey: 0 });
  const regions = useGetRegionsQuery({ fetchKey: 0 });
  const users = useGetUsersQuery({ fetchKey: 0 });

  const permissionsIndex = permissions as { [key: string]: any };
  const hasUpdatePermission =
    user?.role && permissionsIndex[user?.role]['jobs']['update'];

  return (
    <>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={6} md={4}>
          <TextField
            label='ID'
            type='text'
            margin='dense'
            helperText={errors.key ? 'Field is required.' : ''}
            error={!!errors.key}
            disabled={!hasUpdatePermission}
            fullWidth
            autoFocus
            {...register('key', { required: true })}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label='Description'
            type='text'
            margin='dense'
            disabled={!hasUpdatePermission}
            fullWidth
            {...register('description')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Autocomplete
            options={customers.map((customer) => customer.key)}
            value={
              customers.find((customer) => customer.id === customerId)?.key ??
              ''
            }
            onInputChange={(_event, value) =>
              setCustomerId(
                customers.find((customer) => customer.key === value)?.id ?? ''
              )
            }
            disabled={!hasUpdatePermission}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Customer'
                margin='dense'
                disabled={!hasUpdatePermission}
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControl margin='dense' fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label='Category'
              value={jobCategoryId}
              onChange={(event) => {
                onJobCategoryChange();
                setJobCategoryId(event.target.value);
              }}
              disabled={!hasUpdatePermission}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4}>
          <MultiSelectInput
            label='Regions'
            options={regions.map((region) => region.name)}
            selectedOptions={selectedRegions.map((region) => region.name)}
            onSelect={(values) => {
              const newRegions = regions.filter((region) =>
                values.includes(region.name)
              );
              setSelectedRegions(newRegions);
            }}
            disabled={!hasUpdatePermission}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControl margin='dense' fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label='Status'
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              disabled={!hasUpdatePermission}
            >
              {Object.keys(JobStatus)
                .filter((x) => isNaN(Number(x)))
                .map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4}>
          {parentJobKey ? (
            <>
              <TextField
                label='Related Job'
                type='text'
                margin='dense'
                disabled
                fullWidth
                value={parentJobKey}
              />
            </>
          ) : (
            <Autocomplete
              options={jobs.map((job) => job.key)}
              value={childJobKey}
              onInputChange={(_event, value) => setChildJobKey(value)}
              disabled={!hasUpdatePermission}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Related Job'
                  margin='dense'
                  disabled={!hasUpdatePermission}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6} md={4}>
          <Autocomplete
            options={users.map((user) => user.username)}
            value={
              users.find((user) => user.id === preparedById)?.username ?? ''
            }
            onInputChange={(_event, value) =>
              setPreparedById(
                users.find((user) => user.username === value)?.id ?? ''
              )
            }
            disabled={!hasUpdatePermission}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Prepared By'
                margin='dense'
                InputProps={{
                  ...params.InputProps
                }}
                disabled={!hasUpdatePermission}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label='End Date'
            type='date'
            margin='dense'
            InputLabelProps={{ shrink: true }}
            fullWidth
            {...register('endDate')}
          />
        </Grid>
      </Grid>
      <TextAreaContainer>
        <FullHeightTextField
          label='Notes'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          multiline
          {...register('notes')}
        />
      </TextAreaContainer>
    </>
  );
};

export default GeneralPanel;
