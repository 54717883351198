import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
  useGridApiContext,
  gridVisibleSortedRowIdsSelector,
  GridColumns
} from '@mui/x-data-grid-pro';
import { CircularProgress, Tooltip, styled } from '@mui/material';
import { WarningAmberRounded, Close } from '@mui/icons-material';

import DataTableEditableCrudProps from './DataTableEditableCrudProps';
import EditToolbar from './DataTableEditableCrudToolbar';
import { useAuthentication } from '../../api/authentication';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

const DataTableEditableCrud = ({
  errorFlags,
  rows,
  columns,
  recordType,
  onUpdate,
  onAdd,
  onSubmit,
  onDelete,
  onValidate,
  toolbarButtons,
  $onColumnResize,
  ...props
}: DataTableEditableCrudProps) => {
  const { user } = useAuthentication();

  const [combinedColumns, setCombinedColumns] = useState<GridColumns<any>>([]);
  const [processingRowIds, setProcessingRowIds] = useState<string[]>([]);
  const [apiRef, setApiRef] = useState<MutableRefObject<GridApiPro>>();

  const processRowUpdate = useCallback(
    (row: any) => {
      onUpdate(row);
      setProcessingRowIds((prev) => [...prev, row.id]);
      if (onValidate(row)) {
        onSubmit(row);
      }
      setProcessingRowIds((prev) => [...prev.filter((id) => id !== row.id)]);
      return row;
    },
    [onUpdate, onValidate, onSubmit, setProcessingRowIds]
  );

  useEffect(() => {
    // Add validation column to show row validation status
    const validationColumn = {
      field: 'status',
      headerName: '',
      width: 0,
      resizable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (processingRowIds.includes(params.id as string)) {
          return (
            <Tooltip title='Processing'>
              <CircularProgress size={20} />
            </Tooltip>
          );
        } else if (
          params.row.isNew ||
          errorFlags?.some((flag) => flag.rowId === params.id)
        ) {
          return (
            <Tooltip title='Row not saved'>
              <WarningAmberRounded color='warning' />
            </Tooltip>
          );
        }
      }
    };

    const newColumns: GridColumns = [validationColumn, ...columns];

    // Add actions column if user has admin or proctor role
    if (user?.role === 'admin' || user?.role === 'proctor') {
      newColumns.push({
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        resizable: false,
        getActions: ({ id }: GridRowParams<any>) => {
          const ref = useGridApiContext();

          // This is a hack to access the data grid api
          // from outside the data grid component
          useEffect(() => {
            if (!apiRef) {
              setApiRef(ref);
            }
          }, [apiRef, ref]);

          return [
            <Tooltip title='Delete'>
              {/* eslint-disable-next-line */}
              {/* @ts-ignore */}
              <GridActionsCellItem
                icon={<Close />}
                label='Delete'
                onClick={async () => {
                  await onDelete(id);
                }}
                color='default'
              />
            </Tooltip>
          ];
        }
      });
    }
    setCombinedColumns(newColumns);
  }, [columns]);

  return (
    <DataGridPro
      rows={rows}
      columns={combinedColumns}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(e) => console.log(e)}
      components={{ Toolbar: EditToolbar }}
      componentsProps={{ toolbar: { type: recordType, onAdd, toolbarButtons } }}
      experimentalFeatures={{ newEditingApi: true }}
      onColumnResize={(e) => {
        if (e.colDef.width != undefined) {
          $onColumnResize(e.colDef.field, e.colDef.width);
        }
      }}
      onCellKeyDown={(params, event) => {
        // Tab key navigation example taken from
        // https://github.com/mui/mui-x/issues/3016
        if (event.key !== 'Tab' || !apiRef?.current) {
          return;
        }

        const rowIds = gridVisibleSortedRowIdsSelector(apiRef.current.state);
        const visibleColumns = apiRef.current.getVisibleColumns();

        const nextCell = {
          rowIndex: rowIds.findIndex((id) => id === params.id),
          columnIndex: apiRef.current.getColumnIndex(params.field)
        };

        if (
          nextCell.columnIndex === visibleColumns.length - 1 &&
          nextCell.rowIndex === rowIds.length - 1 &&
          !event.shiftKey
        ) {
          // Do nothing if we are at the last cell of the last row
          return;
        }

        if (
          nextCell.columnIndex === 0 &&
          nextCell.rowIndex === 0 &&
          event.shiftKey
        ) {
          // Do nothing if we are at the first cell of the first row
          return;
        }

        event.preventDefault();
        event.defaultMuiPrevented = true;

        if (!event.shiftKey) {
          if (nextCell.columnIndex < visibleColumns.length - 1) {
            nextCell.columnIndex += 1;
          } else {
            nextCell.rowIndex += 1;
            nextCell.columnIndex = 0;
          }
        } else if (nextCell.columnIndex > 0) {
          nextCell.columnIndex -= 1;
        } else {
          nextCell.rowIndex -= 1;
          nextCell.columnIndex = visibleColumns.length - 1;
        }
        apiRef.current.scrollToIndexes(nextCell);

        const field = visibleColumns[nextCell.columnIndex].field;
        const id = rowIds[nextCell.rowIndex];
        apiRef.current.setCellFocus(id, field);
      }}
      disableVirtualization
      {...props}
    />
  );
};

const StyledDataTableEditableCrud = styled(DataTableEditableCrud)`
  .invalid {
    border: 1px solid red;
  }
`;

export default StyledDataTableEditableCrud;
