import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetCustomersQuery } from './__generated__/GetCustomersQuery.graphql';

const getCustomersQuery = graphql`
  query GetCustomersQuery {
    getCustomers {
      id
      key
      name
      categoryId
      address
      address2
      city
      state
      zip
      status
    }
  }
`;

const useGetCustomersQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetCustomersQuery>(
    getCustomersQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getCustomers;
};

export { useGetCustomersQuery };
