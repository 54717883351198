import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetTestObjectsQuery } from './__generated__/GetTestObjectsQuery.graphql';

const getTestObjectsQuery = graphql`
  query GetTestObjectsQuery {
    getTestObjects {
      id
      name
    }
  }
`;

const useGetTestObjectsQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetTestObjectsQuery>(
    getTestObjectsQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getTestObjects;
};

export { useGetTestObjectsQuery };
