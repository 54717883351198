import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled
} from '@mui/material';

import SelectInputProps from './SelectInputProps';

const StyledSelect = styled(Select)`
  border-radius: 0;
`;

const SelectInput = ({
  className,
  label,
  options,
  defaultValue,
  onSelect
}: SelectInputProps) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultValue ?? options[0]
  );

  return (
    <FormControl className={className} size='small'>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        label={label}
        value={selectedOption}
        onChange={(event) => {
          const value = event.target.value as string;
          if (value) {
            setSelectedOption(value);
            if (onSelect) {
              onSelect(value);
            }
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

const StyledSelectInput = styled(SelectInput)`
  width: 206px;
`;

export default StyledSelectInput;
