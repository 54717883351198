interface EaseOfUseRating {
  id?: string;
  description: string;
}

const defaultEaseOfUseRatings = [
  { id: '0', description: 'Average' },
  { id: '1', description: 'Easy' },
  { id: '2', description: 'Hard' },
  { id: '3', description: 'No Opinion' }
];

export default EaseOfUseRating;
export { defaultEaseOfUseRatings };
