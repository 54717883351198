/**
 * @generated SignedSource<<0db71d3a27cd628683df9755d4d102ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUserMutation$variables = {
  email: string;
  fullName?: string | null;
  password: string;
  regionIds?: ReadonlyArray<string | null> | null;
  role: string;
  signatureImage?: string | null;
  title?: string | null;
  username?: string | null;
};
export type CreateUserMutation$data = {
  readonly createUser: {
    readonly email: string;
    readonly fullName: string | null;
    readonly id: string;
    readonly regions: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly role: string;
    readonly signatureImage: string | null;
    readonly title: string | null;
    readonly username: string | null;
  };
};
export type CreateUserMutation = {
  response: CreateUserMutation$data;
  variables: CreateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fullName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signatureImage"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "fullName",
            "variableName": "fullName"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          },
          {
            "kind": "Variable",
            "name": "regionIds",
            "variableName": "regionIds"
          },
          {
            "kind": "Variable",
            "name": "role",
            "variableName": "role"
          },
          {
            "kind": "Variable",
            "name": "signatureImage",
            "variableName": "signatureImage"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          },
          {
            "kind": "Variable",
            "name": "username",
            "variableName": "username"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "createUser",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signatureImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateUserMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "da265bd46870d255dda313ad181cee64",
    "id": null,
    "metadata": {},
    "name": "CreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUserMutation(\n  $email: String!\n  $username: String\n  $fullName: String\n  $title: String\n  $password: String!\n  $role: String!\n  $regionIds: [ID]\n  $signatureImage: String\n) {\n  createUser(input: {email: $email, username: $username, fullName: $fullName, title: $title, password: $password, role: $role, regionIds: $regionIds, signatureImage: $signatureImage}) {\n    id\n    email\n    username\n    fullName\n    title\n    role\n    signatureImage\n    regions {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f67b471d6ea30fcf3f7c86304c70db7";

export default node;
