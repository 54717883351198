import AlertDialog from './AlertDialog';
import DeleteConfirmationDialogProps from './DeleteConfirmationDialogProps';

const DeleteConfirmationDialog = ({
  objectName,
  onCancel,
  onDelete
}: DeleteConfirmationDialogProps) => {
  return (
    <AlertDialog
      title='Delete Confirmation'
      content={`Are you sure you want to permanently delete ${objectName}? This action cannot be undone.`}
      cancelLabel='Cancel'
      submitLabel='Delete'
      onCancel={onCancel}
      onSubmit={onDelete}
    />
  );
};

export default DeleteConfirmationDialog;
