import { useReducer } from 'react';
import _ from 'lodash';

import ErrorFlag from './ErrorFlag';

const useErrorFlags = () => {
  const [errorFlags, dispatchErrorFlag] = useReducer(
    (flags: ErrorFlag[], action: { type: string; flag: ErrorFlag }) => {
      if (action.type === 'hide') {
        return flags.map((flag) => {
          if (
            flag.rowId === action.flag.rowId &&
            flag.field === action.flag.field
          ) {
            return { ...flag, hidden: true };
          }
          return flag;
        });
      } else if (action.type === 'remove') {
        return flags.filter(
          (flag) =>
            !(
              flag.rowId === action.flag.rowId &&
              flag.field === action.flag.field &&
              flag.message === action.flag.message
            )
        );
      } else {
        // insert flag if it doesn't exist, otherwise update it
        const index = flags.findIndex(
          (flag) =>
            flag.rowId === action.flag.rowId &&
            flag.field === action.flag.field &&
            flag.message === action.flag.message
        );
        if (index > -1) {
          flags[index] = { ...action.flag, hidden: false };
        } else {
          flags.push({ ...action.flag, hidden: false });
        }

        if (
          action.flag.field !== 'groupNum' &&
          action.flag.field !== 'testDateTime' &&
          action.flag.field !== 'birthDate'
        ) {
          const errorAudio = new Audio('lib/pixabay/error.mp3');
          errorAudio.play();
        }

        return flags;
      }
    },
    []
  );

  return { errorFlags, dispatchErrorFlag };
};

export default useErrorFlags;
