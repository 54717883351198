import { useState, useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { useGetCustomersQuery } from '../../api/customers/GetCustomersQuery';
import { useGetCustomerCategoriesQuery } from '../../api/customerCategories/GetCustomerCategoriesQuery';
import { useCreateCustomerMutation } from '../../api/customers/CreateCustomerMutation';
import { useUpdateCustomerMutation } from '../../api/customers/UpdateCustomerMutation';
import CustomerDialog from '../../dialogs/CustomerDialog';
import CustomerCategoriesDialog from '../../dialogs/CustomerCategoriesDialog';
import DataToolbar from '../../components/DataToolbar';
import DataTable from '../../components/DataTable';
import Button from '../../components/Button';
import CustomerStatus from '../../models/CustomerStatus';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';

const statuses = [
  'All',
  ...Object.keys(CustomerStatus).filter((x) => isNaN(Number(x)))
];

const CustomerPage = () => {
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const [showEditCustomerDialog, setShowEditCustomerDialog] = useState(false);
  const [showCustomerCategoriesDialog, setShowCustomerCategoriesDialog] =
    useState(false);
  const [filterCondition, setFilterCondition] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedCustomerCategoryId, setSelectedCustomerCategoryId] = useState<
    string | null
  >(null);
  const [customerKeys, setCustomerKeys] = useState<string[]>([]);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const customers = useGetCustomersQuery({ fetchKey: queryFetchKey });
  const customerCategories = useGetCustomerCategoriesQuery({
    fetchKey: queryFetchKey
  });
  const [createCustomer, createCustomerLoading] = useCreateCustomerMutation();
  const [updateCustomer, updateCustomerLoading] = useUpdateCustomerMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (!createCustomerLoading && !updateCustomerLoading) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createCustomerLoading, updateCustomerLoading]);

  useEffect(() => {
    if (customers && customers.length > 0) {
      const customerKeys: string[] = [];
      customers.forEach((customer) => customerKeys.push(customer.key));
      setCustomerKeys(customerKeys);
    }
  }, [customers, setCustomerKeys]);

  const onSearchChange = (value: string) => {
    const selectedCustomer = customers.find(
      (cust) => cust.key.toLowerCase() === value.toLowerCase()
    );
    if (selectedCustomer) {
      setSelectedId(selectedCustomer.id || '');
      if (selectedCustomer?.id) {
        setShowEditCustomerDialog(true);
      }
    }
    setFilterCondition(value.toLowerCase());
  };

  /**
   * Filters customers so that
   * - customer is not null
   * - the key contains the searched for value
   * - the customer status matches the selected value (if one is selected)
   * - the customer category matches the selected value (if one is selected)
   * @returns Customer[]
   */
  const filterCustomers = () => {
    return customers.filter((customer) => {
      const statusPasses =
        customer.status?.toLocaleLowerCase() === selectedStatus ||
        selectedStatus === 'all';
      const categoryPasses =
        !selectedCustomerCategoryId ||
        customer.categoryId === selectedCustomerCategoryId;
      return (
        customer !== null &&
        customer.key.toLowerCase().includes(filterCondition) &&
        statusPasses &&
        categoryPasses
      );
    });
  };

  const cellClassName = (params: GridCellParams) =>
    params.row.status?.toLowerCase() === 'inactive' ? 'disabled' : 'enabled';

  const columns = [
    {
      field: 'key',
      headerName: 'ID',
      flex: 1,
      cellClassName
    },
    { field: 'name', headerName: 'Customer Name', flex: 2, cellClassName },
    {
      field: 'category',
      headerName: 'Category',
      flex: 2,
      valueGetter: ({ row }: { row: { categoryId: string } }) => {
        return (
          customerCategories.find((category) => category.id === row.categoryId)
            ?.name ?? 'None'
        );
      },
      cellClassName
    },
    { field: 'address', headerName: 'Address', flex: 2, cellClassName },
    { field: 'city', headerName: 'City', flex: 2, cellClassName },
    { field: 'state', headerName: 'State', flex: 2, cellClassName },
    { field: 'status', headerName: 'Status', flex: 2, cellClassName }
  ];

  return (
    <>
      {showAddCustomerDialog && (
        <CustomerDialog
          categories={customerCategories}
          onCancel={() => setShowAddCustomerDialog(false)}
          onSubmit={(customer) => {
            createCustomer({
              variables: { ...customer },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddCustomerDialog(false);
          }}
        />
      )}
      {showEditCustomerDialog && (
        <CustomerDialog
          categories={customerCategories}
          customer={customers?.find((user) => user.id === selectedId)}
          onCancel={() => setShowEditCustomerDialog(false)}
          onSubmit={(customer) => {
            if (customer.id) {
              updateCustomer({
                variables: {
                  id: customer.id,
                  ...customer
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditCustomerDialog(false);
            }
          }}
        />
      )}
      {showCustomerCategoriesDialog && (
        <CustomerCategoriesDialog
          onSubmit={() => setShowCustomerCategoriesDialog(false)}
        />
      )}
      <DataToolbar
        dataType='customers'
        selectedId={selectedId}
        categories={customerCategories}
        onCategoryChange={(customerCategory) => {
          if (customerCategory && customerCategory.id) {
            setSelectedCustomerCategoryId(customerCategory.id);
          } else setSelectedCustomerCategoryId(null);
        }}
        searchOptions={customerKeys}
        onSearchChange={onSearchChange}
        statuses={statuses}
        onStatusChange={(value) => setSelectedStatus(value.toLocaleLowerCase())}
        onAdd={() => setShowAddCustomerDialog(true)}
        onEdit={() => setShowEditCustomerDialog(true)}
        onDelete={() => {
          if (selectedId) {
            const customer = customers?.find(
              (customer) => customer.id === selectedId
            );
            if (customer?.id) {
              updateCustomer({
                variables: {
                  ...customer,
                  id: customer.id,
                  status: 'Inactive'
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
            }
          }
        }}
        isDisable
      >
        <Button onClick={() => setShowCustomerCategoriesDialog(true)}>
          Edit Categories
        </Button>
      </DataToolbar>
      <DataTable
        columns={columns}
        rows={filterCustomers()}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Customer Record'}
          errorBody={'There was an error submitting the Customer record.'}
        />
      )}
    </>
  );
};

export default CustomerPage;
