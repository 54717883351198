interface AgeGroup {
  id?: string;
  groupNumber: number;
  beginAge: number;
  endAge: number;
  groupPercentage: number;
}

const defaultAgeGroups = [
  {
    id: '0',
    groupNumber: 1,
    beginAge: 50,
    endAge: 54,
    groupPercentage: 25
  },
  {
    id: '1',
    groupNumber: 2,
    beginAge: 55,
    endAge: 59,
    groupPercentage: 25
  },
  {
    id: '2',
    groupNumber: 3,
    beginAge: 60,
    endAge: 70,
    groupPercentage: 25
  }
];

export default AgeGroup;
export { defaultAgeGroups };
