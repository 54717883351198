import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateSiteMutation } from './__generated__/UpdateSiteMutation.graphql';

const updateSiteMutation = graphql`
  mutation UpdateSiteMutation(
    $id: ID!
    $key: String!
    $name: String!
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $regionId: String
  ) {
    updateSite(
      regionId: $regionId
      input: {
        id: $id
        key: $key
        name: $name
        address: $address
        address2: $address2
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      id
      key
      name
      address
      address2
      city
      state
      zip
    }
  }
`;

const useUpdateSiteMutation = () =>
  useMutation<UpdateSiteMutation>(updateSiteMutation);

export { useUpdateSiteMutation };
