/**
 * @generated SignedSource<<f9cf78f6cb906ae229c490b13ea64395>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportType = "ADULTRESECURING" | "ADULTUNITPACKAGE" | "ADULTVIAL" | "CHILDCT" | "CHILDUP" | "CHILDVIAL" | "LINKEDRESECURING" | "%future added value";
export type SuppressedReportsInput = {
  ageDistribution?: boolean | null;
  detail?: boolean | null;
  header?: boolean | null;
  history?: boolean | null;
  objectivesProcedures?: boolean | null;
  packageId?: boolean | null;
  picture?: boolean | null;
  proctor?: boolean | null;
  resultsDiscussion?: boolean | null;
  siteParticipation?: boolean | null;
  summary?: boolean | null;
};
export type generatePDFMutation$variables = {
  jobId: string;
  reportType: ReportType;
  suppressedReports?: SuppressedReportsInput | null;
  upload?: boolean | null;
};
export type generatePDFMutation$data = {
  readonly generatePDF: {
    readonly path: string;
  } | null;
};
export type generatePDFMutation = {
  response: generatePDFMutation$data;
  variables: generatePDFMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suppressedReports"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "upload"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "jobId",
        "variableName": "jobId"
      },
      {
        "kind": "Variable",
        "name": "reportType",
        "variableName": "reportType"
      },
      {
        "kind": "Variable",
        "name": "suppressedReports",
        "variableName": "suppressedReports"
      },
      {
        "kind": "Variable",
        "name": "upload",
        "variableName": "upload"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "generatePDFMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PDF",
        "kind": "LinkedField",
        "name": "generatePDF",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "generatePDFMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PDF",
        "kind": "LinkedField",
        "name": "generatePDF",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c3920d13c2db22520c74509d152d7d8",
    "id": null,
    "metadata": {},
    "name": "generatePDFMutation",
    "operationKind": "mutation",
    "text": "mutation generatePDFMutation(\n  $jobId: ID!\n  $suppressedReports: SuppressedReportsInput\n  $reportType: ReportType!\n  $upload: Boolean\n) {\n  generatePDF(input: {jobId: $jobId, suppressedReports: $suppressedReports, reportType: $reportType, upload: $upload}) {\n    path\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "722e2cf57a04fae951dc7a0da02c08dd";

export default node;
