import { useEffect, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { useGetUsersQuery } from '../../api/users/GetUsersQuery';
import { useCreateUserMutation } from '../../api/users/CreateUserMutation';
import { useUpdateUserMutation } from '../../api/users/UpdateUserMutation';

import DataToolbar from '../../components/DataToolbar';
import DataTable from '../../components/DataTable';
import UserDialog from '../../dialogs/UserDialog';
import { useGetRegionsQuery } from '../../api/regions/GetRegionsQuery';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';
const cellClassName = (params: GridCellParams) =>
  params.row.isActive ? 'enabled' : 'disabled';

const columns = [
  { field: 'email', headerName: 'Email', flex: 1, cellClassName },
  { field: 'username', headerName: 'Username', flex: 1, cellClassName },
  { field: 'isActive', headerName: 'Is Active', flex: 1, cellClassName }
];

const UserPage = () => {
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [filterCondition, setFilterCondition] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const users = useGetUsersQuery({ fetchKey: queryFetchKey });
  const regions = useGetRegionsQuery({ fetchKey: queryFetchKey });
  const [createUser, createUserLoading] = useCreateUserMutation();
  const [updateUser, updateUserLoading] = useUpdateUserMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (!createUserLoading && !updateUserLoading) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createUserLoading, updateUserLoading]);

  const onSearchChange = (value: string) => setFilterCondition(value);

  return (
    <>
      {showAddUserDialog && (
        <UserDialog
          regions={regions}
          onCancel={() => setShowAddUserDialog(false)}
          onSubmit={(user) => {
            if (user.password) {
              createUser({
                variables: { password: user.password, ...user },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  } else {
                    setShowAddUserDialog(false);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
            }
          }}
        />
      )}
      {showEditUserDialog && (
        <UserDialog
          user={users?.find((user) => user.id === selectedId)}
          regions={regions}
          onCancel={() => setShowEditUserDialog(false)}
          onSubmit={(user) => {
            if (user.id) {
              updateUser({
                variables: {
                  id: user.id,
                  password: user?.password,
                  ...user
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  } else {
                    setShowAddUserDialog(false);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditUserDialog(false);
            }
          }}
        />
      )}
      <DataToolbar
        dataType='users'
        selectedId={selectedId}
        isDisable={true}
        onSearchChange={onSearchChange}
        onAdd={() => setShowAddUserDialog(true)}
        onEdit={() => setShowEditUserDialog(true)}
        onDelete={() => {
          if (selectedId) {
            const user = users?.find((user) => user.id === selectedId);
            if (user?.id) {
              updateUser({
                variables: {
                  ...user,
                  id: user.id,
                  isActive: false
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
            }
          }
        }}
      />
      <DataTable
        columns={columns}
        rows={users}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting User Record'}
          errorBody={'There was an error submitting the User record.'}
        />
      )}
    </>
  );
};

export default UserPage;
