import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateParticipantMutation } from './__generated__/UpdateParticipantMutation.graphql';

const updateParticipantMutation = graphql`
  mutation UpdateParticipantMutation(
    $id: ID!
    $jobId: ID
    $createdById: ID
    $siteId: ID
    $sampleId: String
    $custSampleNum: String
    $sex: String
    $groupNum: Int
    $testDateTime: String
    $birthDate: String
    $age: Int
    $zip: String
    $participantData: String
    $proctorId: ID!
  ) {
    updateParticipant(
      input: {
        id: $id
        jobId: $jobId
        createdById: $createdById
        siteId: $siteId
        sampleId: $sampleId
        custSampleNum: $custSampleNum
        sex: $sex
        groupNum: $groupNum
        testDateTime: $testDateTime
        birthDate: $birthDate
        age: $age
        zip: $zip
        participantData: $participantData
        proctorId: $proctorId
      }
    ) {
      id
      job {
        id
        key
      }
      createdById
      site {
        id
        name
      }
      jobId
      siteId
      sampleId
      custSampleNum
      sex
      groupNum
      testDateTime
      birthDate
      age
      zip
      participantData
      proctorId
    }
  }
`;

const useUpdateParticipantMutation = () =>
  useMutation<UpdateParticipantMutation>(updateParticipantMutation);

export { useUpdateParticipantMutation };
