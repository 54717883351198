import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateJobCategoryMutation } from './__generated__/UpdateJobCategoryMutation.graphql';

const updateJobCategoryMutation = graphql`
  mutation UpdateJobCategoryMutation(
    $id: ID!
    $name: String!
    $description: String
    $reportType: String
    $parentId: ID
    $methodsOfUse: [UpdateMethodOfUseInput]
    $procedures: [UpdateProcedureInput]
    $easeOfUseRatings: [UpdateEaseOfUseRatingInput]
    $ageGroups: [UpdateAgeGroupInput]
  ) {
    updateJobCategory(
      input: {
        id: $id
        name: $name
        description: $description
        reportType: $reportType
        parentId: $parentId
        methodsOfUse: $methodsOfUse
        procedures: $procedures
        easeOfUseRatings: $easeOfUseRatings
        ageGroups: $ageGroups
      }
    ) {
      id
      name
      description
      reportType
      parentId
      methodsOfUse {
        description
      }
      procedures {
        description
      }
      easeOfUseRatings {
        description
      }
      ageGroups {
        groupNumber
        beginAge
        endAge
        groupPercentage
      }
    }
  }
`;

const useUpdateJobCategoryMutation = () =>
  useMutation<UpdateJobCategoryMutation>(updateJobCategoryMutation);

export { useUpdateJobCategoryMutation };
