import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button
} from '@mui/material';
import { useForm } from 'react-hook-form';
import MultiSelectInput from '../../components/MultiSelectInput';
import Region from '../../models/Region';

import ProctorDialogProps from './ProctorDialogProps';

const ProctorDialog = ({
  proctor,
  regions,
  onCancel,
  onSubmit
}: ProctorDialogProps) => {
  const [selectedRegions, setSelectedRegions] = useState<readonly Region[]>(
    proctor?.regions ? proctor.regions : []
  );

  const defaultValues = {
    key: proctor?.key || '',
    firstName: proctor?.firstName || '',
    lastName: proctor?.lastName || '',
    state: proctor?.state || ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>{proctor ? 'Edit Proctor' : 'Add Proctor'}</DialogTitle>
      <DialogContent>
        <TextField
          label='ID'
          type='text'
          margin='dense'
          helperText={errors.key ? 'Field is required.' : ''}
          error={!!errors.key}
          fullWidth
          autoFocus
          {...register('key', { required: true })}
        />
        <TextField
          label='First Name'
          type='text'
          margin='dense'
          fullWidth
          autoFocus
          {...register('firstName')}
        />
        <TextField
          label='Last Name'
          type='text'
          margin='dense'
          fullWidth
          autoFocus
          {...register('lastName')}
        />
        <TextField
          label='State'
          type='text'
          margin='dense'
          fullWidth
          autoFocus
          {...register('state')}
        />
        <MultiSelectInput
          label='Regions'
          options={regions.map((region) => region.name)}
          selectedOptions={selectedRegions.map((region) => region.name)}
          onSelect={(values) => {
            const newRegions = regions.filter((region) =>
              values.includes(region.name)
            );
            setSelectedRegions(newRegions);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({
              ...fields,
              id: proctor?.id,
              regionIds: selectedRegions.map((region) => region?.id as string)
            });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProctorDialog;
