import { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Button from '../../components/Button';

type ErrorMessageDialogProps = {
  hideDialog: () => void;
  errorBody: string | ReactNode;
  errorTitle: string;
};
const ErrorMessageDialog = ({
  hideDialog,
  errorBody,
  errorTitle
}: ErrorMessageDialogProps) => {
  return (
    <Dialog open>
      <DialogTitle>{errorTitle}</DialogTitle>
      <DialogContent>{errorBody}</DialogContent>
      <DialogActions>
        <Button onClick={hideDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ErrorMessageDialog;
