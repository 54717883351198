import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetRegionsQuery } from './__generated__/GetRegionsQuery.graphql';

const getRegionsQuery = graphql`
  query GetRegionsQuery {
    getRegions {
      id
      name
      enabled
    }
  }
`;

const useGetRegionsQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetRegionsQuery>(
    getRegionsQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getRegions;
};

export { useGetRegionsQuery };
