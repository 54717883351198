import React from 'react';
import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled
} from '@mui/material';

import HierarchicalListProps from './HierarchicalListProps';

interface CategoryListProps {
  depth: number;
}

const CategoryList = styled(List)<CategoryListProps>`
  max-height: 352px;
  overflow-y: auto;
  padding: 0;
  padding-left: ${({ depth }) => (depth > 0 ? '16px' : '0')};
  width: calc(100% - 16px * ${({ depth }) => depth});
`;

const HierarchicalList = ({
  categories,
  selectedId,
  setSelectedId,
  depth
}: HierarchicalListProps) => {
  return (
    <CategoryList depth={depth}>
      {categories.map((category) => (
        <React.Fragment key={category.name}>
          <ListItemButton
            onClick={() => {
              if (category.id) {
                if (category.id === selectedId) setSelectedId(null);
                else setSelectedId(category.id);
              }
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={category.id === selectedId}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': category.name }}
              />
            </ListItemIcon>
            <ListItemText
              id={category.name}
              primary={category.name}
              secondary={category.description}
            />
          </ListItemButton>
          {category.children && category.children.length > 0 && (
            <HierarchicalList
              categories={category.children ?? []}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              depth={depth + 1}
            />
          )}
        </React.Fragment>
      ))}
    </CategoryList>
  );
};

export default HierarchicalList;
