import { useState, useEffect } from 'react';
import User from '../models/User';
import config from '../config';

const useAuthentication = () => {
  const [user, setUser] = useState<User | null>();
  const [token, setToken] = useState<string | null>();

  // Load user and token from storage into react state.
  useEffect(() => {
    const userJson = localStorage.getItem('bos_user');
    setUser(userJson ? JSON.parse(userJson) : null);
    setToken(localStorage.getItem('bos_token'));
  }, []);

  const login = (email: string, password: string): Promise<User> => {
    return fetch(config.REACT_APP_AUTH_URL, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((result) => result.json())
      .then((data) => {
        localStorage.setItem('bos_token', data.token);
        localStorage.setItem('bos_user', JSON.stringify(data.user));
        setUser(data.user);
        setToken(data.token);
        return data.user;
      });
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
  };

  return { user, token, login, logout };
};

export { useAuthentication };
