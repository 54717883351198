import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      offWhite: string;
      darkBlue: string;
      lightGray: string;
      darkGray: string;
    };
  }

  interface ThemeOptions {
    colors?: {
      offWhite?: string;
      darkBlue?: string;
      lightGray?: string;
      darkGray?: string;
    };
  }
}

const theme = createTheme({
  typography: { fontFamily: `"Open Sans"` },
  palette: { primary: { main: '#5f83bf' }, secondary: { main: '#cd5c3e' } },
  colors: {
    offWhite: '#f7f7f7',
    darkBlue: '#1e3163',
    lightGray: '#e8e8e8',
    darkGray: '#b4b4b4'
  }
});

export default theme;
