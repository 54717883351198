/**
 * @generated SignedSource<<58e3c7fec98c86afac198eb8c6d1a001>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateProctorMutation$variables = {
  firstName?: string | null;
  id: string;
  key: string;
  lastName?: string | null;
  regionIds?: ReadonlyArray<string | null> | null;
  state?: string | null;
};
export type UpdateProctorMutation$data = {
  readonly updateProctor: {
    readonly firstName: string | null;
    readonly id: string;
    readonly key: string;
    readonly lastName: string | null;
    readonly regions: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly state: string | null;
  };
};
export type UpdateProctorMutation = {
  response: UpdateProctorMutation$data;
  variables: UpdateProctorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "firstName",
            "variableName": "firstName"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "key",
            "variableName": "key"
          },
          {
            "kind": "Variable",
            "name": "lastName",
            "variableName": "lastName"
          },
          {
            "kind": "Variable",
            "name": "regionIds",
            "variableName": "regionIds"
          },
          {
            "kind": "Variable",
            "name": "state",
            "variableName": "state"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "Proctor",
    "kind": "LinkedField",
    "name": "updateProctor",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProctorMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateProctorMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "c18fc2c941ab24292dcf09bec83c165d",
    "id": null,
    "metadata": {},
    "name": "UpdateProctorMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProctorMutation(\n  $id: ID!\n  $key: String!\n  $firstName: String\n  $lastName: String\n  $state: String\n  $regionIds: [ID]\n) {\n  updateProctor(input: {id: $id, key: $key, firstName: $firstName, lastName: $lastName, state: $state, regionIds: $regionIds}) {\n    id\n    key\n    firstName\n    lastName\n    state\n    regions {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "869ed7da85ca61c904120619e5e21d74";

export default node;
