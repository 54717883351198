import _ from 'lodash';

import Job from '../../../models/Job';
import Participant from '../../../models/Participant';

const propertyDefined = (row: Participant, prop: string) => {
  return row[prop] != null && row[prop] !== '';
};

const sampleIdsAreUnique = (
  participants: Participant[],
  row: Participant,
  sampleId: string
) => {
  return !participants.some((participant) => {
    return participant.id !== row.id && participant.sampleId === sampleId;
  });
};

const ageGroupIsValid = (job: Job, age: number, groupNumber: number) => {
  if (job) {
    const ageGroup = _.find(job.ageGroups, (group) => {
      return group.groupNumber === groupNumber;
    });
    if (ageGroup) {
      return ageGroup.beginAge <= age && ageGroup.endAge >= age;
    }
  }
  return false;
};

const birthDateBeforeTestDate = (birthDate: string, testDateTime: string) => {
  if (birthDate && testDateTime) {
    return Date.parse(birthDate) <= Date.parse(testDateTime);
  }
  return false;
};

export {
  propertyDefined,
  sampleIdsAreUnique,
  ageGroupIsValid,
  birthDateBeforeTestDate
};
