import { Autocomplete, TextField, styled } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import SearchInputProps from './SearchInputProps';

const SearchInput = ({
  className,
  searchOptions = [],
  onChange
}: SearchInputProps) => {
  return (
    <Autocomplete
      freeSolo
      options={searchOptions}
      onInputChange={(_event, value) => onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Search...'
          InputProps={{
            ...params.InputProps,
            className: className,
            type: 'search',
            endAdornment: <SearchIcon />
          }}
        />
      )}
    />
  );
};

const StyledSearchInput = styled(SearchInput)`
  width: 206px;
  height: 34px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

export default StyledSearchInput;
