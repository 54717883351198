/**
 * @generated SignedSource<<d56e06de208afa7131922de85489db3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCustomerCategoryMutation$variables = {
  description?: string | null;
  name: string;
};
export type CreateCustomerCategoryMutation$data = {
  readonly createCustomerCategory: {
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  } | null;
};
export type CreateCustomerCategoryMutation = {
  response: CreateCustomerCategoryMutation$data;
  variables: CreateCustomerCategoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "description",
            "variableName": "description"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CustomerCategory",
    "kind": "LinkedField",
    "name": "createCustomerCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomerCategoryMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateCustomerCategoryMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e3d48991c3219a99c77c02ddfe3f317f",
    "id": null,
    "metadata": {},
    "name": "CreateCustomerCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomerCategoryMutation(\n  $name: String!\n  $description: String\n) {\n  createCustomerCategory(input: {name: $name, description: $description}) {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc0808a5ddec4763993ab9e758fe8033";

export default node;
