import AgeGroup from '../models/AgeGroup';

const filterEmptyDescriptions = (row: any) => !!row.description;

const filterEmptyAgeGroups = (row: AgeGroup) => {
  return (
    row.groupNumber !== undefined &&
    row.beginAge !== undefined &&
    row.endAge !== undefined &&
    row.groupPercentage !== undefined
  );
};

export { filterEmptyDescriptions, filterEmptyAgeGroups };
