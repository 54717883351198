import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { GetJobParticipantsQuery as GetJobParticipantsQueryType } from './__generated__/GetJobParticipantsQuery.graphql';

const GetJobParticipantsQuery = graphql`
  query GetJobParticipantsQuery($id: ID!) {
    getJob(id: $id) {
      participants {
        id
        jobId
        createdById
        siteId
        sampleId
        custSampleNum
        sex
        groupNum
        testDateTime
        birthDate
        age
        zip
        participantData
        proctorId
      }
    }
  }
`;

const useGetJobParticipantsQuery = (id: string, options: any) => {
  return useLazyLoadQuery<GetJobParticipantsQueryType>(
    GetJobParticipantsQuery,
    {
      id
    },
    options
  ).getJob;
};

export { useGetJobParticipantsQuery };
