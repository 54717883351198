import { useEffect, useState } from 'react';
import { DataGridPro, GridSelectionModel } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import _ from 'lodash';

import DataTableProps from './DataTableProps';
import CategoryPicker from '../CategoryPicker';
import HierarchicalCategory from '../../models/HierarchicalCategory';

const DataTable = ({
  className,
  columns,
  rows = [],
  categories,
  categoryFilterCondition,
  selectedId,
  onSelectionChange,
  isEditable = false,
  processRow,
  checkboxSelection = true,
  scaleHeight = false,
  hideFooter = false,
  compact = false
}: DataTableProps) => {
  const [selectedCategories, setSelectedCategories] = useState<
    HierarchicalCategory[]
  >(categories && categories.length > 0 ? [categories[0]] : []);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => setSelectionModel([`${selectedId}`]), [selectedId]);

  const onCategoryClick = (selectedCategory: HierarchicalCategory) => {
    // Deselect self if already selected
    if (
      selectedCategories.some((category) => category.id === selectedCategory.id)
    ) {
      const updatedCategoryIds = _.pull(selectedCategories, selectedCategory);
      setSelectedCategories([...updatedCategoryIds]);
    } else {
      setSelectedCategories([selectedCategory]);
    }
  };

  if (
    categories &&
    categoryFilterCondition &&
    !selectedCategories.some((category) => category.id === '0') // 'All' is selected
  ) {
    rows = rows.filter((row) =>
      categoryFilterCondition(row, selectedCategories)
    );
  }

  return (
    <div className={className}>
      {categories && (
        <CategoryPicker
          categories={categories}
          selectedCategories={selectedCategories}
          onCategoryClick={onCategoryClick}
        />
      )}
      <DataGridPro
        columns={columns}
        rows={rows}
        selectionModel={selectionModel}
        onCellClick={(event) => {
          if (onSelectionChange) onSelectionChange(event.row.id);
        }}
        checkboxSelection={checkboxSelection}
        disableColumnMenu
        experimentalFeatures={{ newEditingApi: isEditable }}
        processRowUpdate={processRow || undefined}
        autoHeight={scaleHeight}
        hideFooter={hideFooter}
        density={compact ? 'compact' : 'standard'}
      />
    </div>
  );
};

const StyleDataTable = styled(DataTable)`
  display: flex;
  height: calc(100% - 250px);
  margin: 0 84px;

  .disabled {
    font-style: italic;
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
    font-size: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .MuiDataGrid-columnHeaderTitle {
      font-weight: normal;
    }

    .MuiDataGrid-columnHeadersInner > * > div:first-of-type {
      visibility: hidden;
    }

    .MuiDataGrid-columnHeadersInner > * > div:last-of-type {
      padding-right: 50px;
    }
  }

  && .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    font-size: 16px;
  }
`;

export { StyleDataTable, DataTable };
