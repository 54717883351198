import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateCustomerCategoryMutation } from './__generated__/UpdateCustomerCategoryMutation.graphql';

const updateCustomerCategoryMutation = graphql`
  mutation UpdateCustomerCategoryMutation(
    $id: ID!
    $name: String!
    $description: String
  ) {
    updateCustomerCategory(
      input: { id: $id, name: $name, description: $description }
    ) {
      id
      name
      description
    }
  }
`;

const useUpdateCustomerCategoryMutation = () =>
  useMutation<UpdateCustomerCategoryMutation>(updateCustomerCategoryMutation);

export { useUpdateCustomerCategoryMutation };
