import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { useForm } from 'react-hook-form';

import SiteDialogProps from './SiteDialogProps';
import styled from '@emotion/styled';

const StyledFormControl = styled(FormControl)`
  margin: 10px 0;
`;

const SiteDialog = ({ site, regions, onCancel, onSubmit }: SiteDialogProps) => {
  const [region, setRegion] = useState(site?.region?.id ?? '');

  const defaultValues = {
    key: site?.key || '',
    name: site?.name || '',
    address: site?.address || '',
    address2: site?.address2 || '',
    city: site?.city || '',
    state: site?.state || '',
    zip: site?.zip || ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm({ defaultValues });

  const generateRegionOptions = () => {
    return regions.map((region) => {
      return (
        <MenuItem key={region.id} value={region.id}>
          {region.name}
        </MenuItem>
      );
    });
  };

  /**
   * Helper function to check if required fields are filled in (needed because the region field is not part of the form generator)
   * @returns Boolean
   */
  const checkSubmitValid = () => {
    const fields = getValues();
    return fields.key && fields.name && region !== '';
  };

  return (
    <Dialog open>
      <DialogTitle>{site ? 'Edit Site' : 'Add Site'}</DialogTitle>
      <DialogContent>
        <TextField
          label='ID *'
          type='text'
          margin='dense'
          helperText={errors.key ? 'Field is required.' : ''}
          error={!!errors.key}
          fullWidth
          autoFocus
          {...register('key', { required: true })}
        />
        <TextField
          label='Name *'
          type='text'
          margin='dense'
          helperText={errors.name ? 'Field is required.' : ''}
          error={!!errors.name}
          fullWidth
          {...register('name', { required: true })}
        />
        <TextField
          label='Address'
          type='text'
          margin='dense'
          fullWidth
          {...register('address')}
        />
        <TextField
          label='Address 2'
          type='text'
          margin='dense'
          fullWidth
          {...register('address2')}
        />
        <TextField
          label='City'
          type='text'
          margin='dense'
          fullWidth
          {...register('city')}
        />
        <TextField
          label='State'
          type='text'
          margin='dense'
          fullWidth
          {...register('state')}
        />
        <TextField
          label='Zip'
          type='text'
          margin='dense'
          fullWidth
          {...register('zip')}
        />
        <StyledFormControl fullWidth>
          <InputLabel id='regionLabel'>Region *</InputLabel>
          <Select
            labelId='regionLabel'
            label='Region *'
            value={region}
            required
            onChange={(event) => setRegion(event.target.value)}
          >
            {generateRegionOptions()}
          </Select>
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            if (checkSubmitValid()) {
              onSubmit({ id: site?.id, regionId: region, ...fields });
            } else
              alert(
                'The following fields are required. \n - Key \n - Name \n - Region'
              );
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SiteDialog;
