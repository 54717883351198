import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateRegionMutation } from './__generated__/CreateRegionMutation.graphql';

const createRegionMutation = graphql`
  mutation CreateRegionMutation($name: String!, $enabled: Boolean!) {
    createRegion(input: { name: $name, enabled: $enabled }) {
      id
      name
      enabled
    }
  }
`;

const useCreateRegionMutation = () =>
  useMutation<CreateRegionMutation>(createRegionMutation);

export { useCreateRegionMutation };
