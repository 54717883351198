import { useEffect, useState } from 'react';
import { Container, Card, Typography, TextField, Button } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import LoginPageProps from './LoginPageProps';
import User from '../../models/User';

const StyledContainer = styled(Container)`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 368px;
  height: 268px;
  padding: 32px;
  justify-content: center;
  align-items: center;
`;

const LoginPage = ({ login }: LoginPageProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      Sentry.setUser({ email });
    } catch (err) {
      // No Op
      console.error('Unable to set Sentry user');
    }
  }, [Sentry, email]);

  return (
    <StyledContainer>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          login(email, password)
            .then(() => navigate('/jobs', {}))
            .catch((e) => {
              console.error(e);
              setError(true);
            });
        }}
      >
        <StyledCard>
          <Typography variant='h5'>Log in to continue</Typography>

          <TextField
            label='Email'
            type='email'
            margin='dense'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            autoFocus
            fullWidth
          />
          <TextField
            label='Password'
            type='password'
            margin='dense'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
          />
          <Button variant='contained' type='submit' fullWidth>
            Log In
          </Button>
          {isError && (
            <Typography color='red'>Invalid email or password.</Typography>
          )}
        </StyledCard>
      </form>
    </StyledContainer>
  );
};

export default LoginPage;
