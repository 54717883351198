/**
 * @generated SignedSource<<2726b52df2d215752cfb3b6a9595fc0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCustomerCategoryMutation$variables = {
  description?: string | null;
  id: string;
  name: string;
};
export type UpdateCustomerCategoryMutation$data = {
  readonly updateCustomerCategory: {
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  } | null;
};
export type UpdateCustomerCategoryMutation = {
  response: UpdateCustomerCategoryMutation$data;
  variables: UpdateCustomerCategoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "description",
            "variableName": "description"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CustomerCategory",
    "kind": "LinkedField",
    "name": "updateCustomerCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomerCategoryMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateCustomerCategoryMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "15842c4d2c080d06ccbfe8a95035796e",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomerCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomerCategoryMutation(\n  $id: ID!\n  $name: String!\n  $description: String\n) {\n  updateCustomerCategory(input: {id: $id, name: $name, description: $description}) {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ee15e9d05139afd472610419f8e8d54";

export default node;
