import { useState, useEffect } from 'react';

import { useGetSitesQuery } from '../../api/sites/GetSitesQuery';
import { useGetRegionsQuery } from '../../api/regions/GetRegionsQuery';
import { useCreateSiteMutation } from '../../api/sites/CreateSiteMutation';
import { useUpdateSiteMutation } from '../../api/sites/UpdateSiteMutation';
import { useDeleteSiteMutation } from '../../api/sites/DeleteSiteMutation';
import SiteDialog from '../../dialogs/SiteDialog';
import DataToolbar from '../../components/DataToolbar';
import DataTable from '../../components/DataTable';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';
const columns = [
  { field: 'key', headerName: 'ID', flex: 1 },
  { field: 'name', headerName: 'Site Name', flex: 2 },
  { field: 'address', headerName: 'Address', flex: 2 },
  { field: 'city', headerName: 'City', flex: 2 },
  { field: 'state', headerName: 'State', flex: 2 },
  { field: 'region', headerName: 'Region', flex: 2 }
];

const SitePage = () => {
  const [showAddSiteDialog, setShowAddSiteDialog] = useState(false);
  const [showEditSiteDialog, setShowEditSiteDialog] = useState(false);
  const [filterCondition, setFilterCondition] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [siteKeys, setSiteKeys] = useState<string[]>([]);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const sites = useGetSitesQuery({ fetchKey: queryFetchKey });
  const regions = useGetRegionsQuery({ fetchKey: queryFetchKey });
  const [createSite, createSiteLoading] = useCreateSiteMutation();
  const [updateSite, updateSiteLoading] = useUpdateSiteMutation();
  const [deleteSite, deleteSiteLoading] = useDeleteSiteMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (!createSiteLoading && !updateSiteLoading && !deleteSiteLoading) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createSiteLoading, updateSiteLoading, deleteSiteLoading]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const custKeys = sites.map((cust) => cust.key);
      setSiteKeys(custKeys);
    }
  }, [sites, setSiteKeys]);

  const onSearchChange = (value: string) => {
    const selectedSite = sites.find(
      (cust) => cust.key.toLowerCase() === value.toLowerCase()
    );
    if (selectedSite) {
      setSelectedId(selectedSite.id || '');
      if (selectedSite?.id) {
        setShowEditSiteDialog(true);
      }
    }
    setFilterCondition(value.toLowerCase());
  };

  /**
   * Filters sites so that the site is not null and the key contains the searched for value
   * @returns Site[]
   */
  const filterSites = () => {
    return sites.filter((site) => {
      return site !== null && site.key.toLowerCase().includes(filterCondition);
    });
  };

  const formatSites = () => {
    const filtered = filterSites();
    return filtered.map((site) => {
      return {
        ...site,
        region: site.region?.name
      };
    });
  };
  return (
    <>
      {showAddSiteDialog && (
        <SiteDialog
          onCancel={() => setShowAddSiteDialog(false)}
          onSubmit={(site) => {
            createSite({
              variables: { ...site, regionId: site.regionId || '-1' },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddSiteDialog(false);
          }}
          regions={regions}
        />
      )}
      {showEditSiteDialog && (
        <SiteDialog
          site={sites?.find((user) => user.id === selectedId)}
          onCancel={() => setShowEditSiteDialog(false)}
          onSubmit={(site) => {
            if (site.id) {
              updateSite({
                variables: {
                  ...site,
                  id: site.id
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditSiteDialog(false);
            }
          }}
          regions={regions}
        />
      )}
      <DataToolbar
        dataType='sites'
        selectedId={selectedId}
        searchOptions={siteKeys}
        onSearchChange={onSearchChange}
        onAdd={() => setShowAddSiteDialog(true)}
        onEdit={() => setShowEditSiteDialog(true)}
        onDelete={() => {
          if (selectedId)
            deleteSite({
              variables: { id: selectedId },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
        }}
        isDisable
      />
      <DataTable
        columns={columns}
        rows={formatSites()}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Site Record'}
          errorBody={'There was an error submitting the Site record.'}
        />
      )}
    </>
  );
};

export default SitePage;
