import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  styled,
  List,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemButton,
  Typography
} from '@mui/material';

import CustomerCategoriesDialogProps from './CustomerCategoriesDialogProps';
import { useGetCustomerCategoriesQuery } from '../../api/customerCategories/GetCustomerCategoriesQuery';
import { useCreateCustomerCategoryMutation } from '../../api/customerCategories/CreateCustomerCategoryMutation';
import { useUpdateCustomerCategoryMutation } from '../../api/customerCategories/UpdateCustomerCategoryMutation';
import { useDeleteCustomerCategoryMutation } from '../../api/customerCategories/DeleteCustomerCategoryMutation';
import MutateCustomerCategoryDialog from '../MutateCustomerCategoryDialog';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../ErrorMessageDialog/ErrorMessageDialog';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 500px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 88px;
`;

const CategoryList = styled(List)`
  width: 100%;
  max-height: 352px;
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

const CategoryActions = styled('div')`
  display: flex;
`;

const CustomerCategoriesDialog = ({
  onSubmit
}: CustomerCategoriesDialogProps) => {
  const [showAddCustomerCategoryDialog, setShowAddCustomerCategoryDialog] =
    useState(false);
  const [showEditCustomerCategoryDialog, setShowEditCustomerCategoryDialog] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const categories = useGetCustomerCategoriesQuery({ fetchKey: queryFetchKey });
  const [createCustomerCategory, createCustomerCategoryLoading] =
    useCreateCustomerCategoryMutation();
  const [updateCustomerCategory, updateCustomerCategoryLoading] =
    useUpdateCustomerCategoryMutation();
  const [deleteCustomerCategory, deleteCustomerCategoryLoading] =
    useDeleteCustomerCategoryMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (
      !createCustomerCategoryLoading &&
      !updateCustomerCategoryLoading &&
      !deleteCustomerCategoryLoading
    ) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [
    createCustomerCategoryLoading,
    updateCustomerCategoryLoading,
    deleteCustomerCategoryLoading
  ]);

  return (
    <StyledDialog maxWidth='xl' open>
      {showAddCustomerCategoryDialog && (
        <MutateCustomerCategoryDialog
          onCancel={() => setShowAddCustomerCategoryDialog(false)}
          onSubmit={(category) => {
            createCustomerCategory({
              variables: { ...category },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddCustomerCategoryDialog(false);
          }}
        />
      )}
      {showEditCustomerCategoryDialog && selectedIndex != null && (
        <MutateCustomerCategoryDialog
          customerCategory={categories[selectedIndex]}
          onCancel={() => setShowEditCustomerCategoryDialog(false)}
          onSubmit={(category) => {
            if (category.id) {
              updateCustomerCategory({
                variables: {
                  id: category.id,
                  ...category
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditCustomerCategoryDialog(false);
            }
          }}
        />
      )}
      <DialogTitle>Customer Categories</DialogTitle>
      <StyledDialogContent>
        {categories.length === 0 && <Typography>No rows</Typography>}
        {categories.length > 0 && (
          <CategoryList>
            {categories.map((category, index) => (
              <ListItemButton
                key={category.name}
                onClick={() => {
                  if (index === selectedIndex) setSelectedIndex(null);
                  else setSelectedIndex(index);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={index === selectedIndex}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': category.name }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={category.name}
                  primary={category.name}
                  secondary={category.description}
                />
              </ListItemButton>
            ))}
          </CategoryList>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <CategoryActions>
          <Button onClick={() => setShowAddCustomerCategoryDialog(true)}>
            Add
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex !== null)
                setShowEditCustomerCategoryDialog(true);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex !== null) {
                const id = categories[selectedIndex].id;
                if (id) {
                  deleteCustomerCategory({
                    variables: { id },
                    onError: (error) => {
                      Sentry.captureException(error);
                      setShowErrorDialog(true);
                    }
                  });
                }
              }
            }}
          >
            Delete
          </Button>
        </CategoryActions>
        <Button onClick={onSubmit}>Ok</Button>
      </StyledDialogActions>
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Customer Category'}
          errorBody={
            'There was an error submitting the Customer Category record.'
          }
        />
      )}
    </StyledDialog>
  );
};

export default CustomerCategoriesDialog;
