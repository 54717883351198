import { Popper, TextField, styled } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useState, useRef } from 'react';

const StyledPopper = styled(Popper)(() => ({
  width: 'auto !important'
}));

const StyledAutocomplete = styled(Autocomplete<string, false, true>)(() => ({
  width: '100%',

  [`&&& .${autocompleteClasses.inputRoot}`]: {
    padding: 0
  },

  [`&&& .${autocompleteClasses.input}`]: {
    padding: '13px 24px 13px 10px'
  },

  [`&&& .${autocompleteClasses.endAdornment}`]: {
    right: 0
  }
}));

const DataTableEditableAutocompleteCell = ({
  id,
  value,
  field,
  options
}: {
  id: string;
  value: { label: string; value: string };
  field: string;
  options: { label: string; value: string }[];
}) => {
  const apiRef = useGridApiContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState(
    value.label ? value.label : value.value
  );

  // Highlight the text in the input when the cell enters edit mode.
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleChange = async (event: any, value: any) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: value.value
    });
    apiRef.current.stopCellEditMode({ id, field, cellToFocusAfter: 'right' });
  };

  const handleKeyDown = (event: any) => {
    if (event.code === 'Enter' && isOpen) {
      event.stopPropagation();
    }

    if (event.code === 'Tab' && isOpen) {
      event.stopPropagation();
      event.preventDefault();
      if (inputRef.current) {
        const currentInput = inputRef.current.value.toLocaleLowerCase();
        const option = options.find((option) =>
          option.label.toLocaleLowerCase().startsWith(currentInput)
        );
        if (option) {
          handleChange({}, option);
        }
      }
    }
  };

  return (
    // This component's props cause a typescript error, which we believe is a bug in MUI.
    // Autocomplete works with tuples, but the typescript definition only allows for strings.
    <StyledAutocomplete
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size='small'
      sx={{ height: 1 }}
      autoHighlight
      disableClearable
      openOnFocus
      inputValue={inputValue}
      onInputChange={(_event, value, reason) => {
        if (reason !== 'reset') setInputValue(value);
      }}
      renderInput={(params) => <TextField {...params} inputRef={inputRef} />}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options={options}
      PopperComponent={StyledPopper}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
    />
  );
};

export default DataTableEditableAutocompleteCell;
