import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button
} from '@mui/material';
import { useForm } from 'react-hook-form';

import MutateTestObjectDialogProps from './MutateTestObjectDialogProps';

const MutateTestObjectDialog = ({
  testObject,
  onCancel,
  onSubmit
}: MutateTestObjectDialogProps) => {
  const defaultValues = {
    name: testObject?.name || ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>
        {testObject ? 'Edit Test Object' : 'Add Test Object'}
      </DialogTitle>
      <DialogContent>
        <TextField
          label='Name'
          type='text'
          margin='dense'
          helperText={errors.name ? 'Field is required.' : ''}
          error={!!errors.name}
          fullWidth
          autoFocus
          {...register('name', { required: true })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({ id: testObject?.id, ...fields });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MutateTestObjectDialog;
