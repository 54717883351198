import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  Typography,
  Grid,
  styled,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel
} from '@mui/material';
import { useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';

import { useAuthentication } from '../../api/authentication';

import useGeneratePDF from '../../api/generatePDF';
import GeneratePDFDialogProps from './GeneratePDFDialogProps';
import Participant from '../../models/Participant';
import { reportTypes } from '../../models/Report';

const CheckboxLabel = styled('label')`
  display: flex;
  align-items: center;
  user-select: none;
`;

interface ReportSuppressionCheckboxProps {
  children: React.ReactNode;
  name: string;
  control: Control<any>;
}

const ReportSuppressionCheckbox = ({
  children,
  name,
  control
}: ReportSuppressionCheckboxProps) => {
  return (
    <Grid sx={{ width: '100%' }} item>
      <CheckboxLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
        <Typography>{children}</Typography>
      </CheckboxLabel>
    </Grid>
  );
};

const GeneratePDFDialog = ({
  jobId,
  reportType: defaultReportType,
  onCancel,
  onSubmit,
  participantData,
  sites,
  proctors,
  job
}: GeneratePDFDialogProps) => {
  const { user } = useAuthentication();
  const { generate, isLoading, error } = useGeneratePDF();
  const [reportType, setReportType] = useState(
    defaultReportType ?? 'ADULTRESECURING'
  );
  const [linkReport, setLinkReport] = useState(false);

  const defaultValues = {
    objectivesProcedures: false,
    picture: false,
    resultsDiscussion: false,
    summary: false,
    proctor: false,
    siteParticipation: false,
    ageDistribution: false,
    detail: false,
    packageId: false,
    header: false,
    history: false
  };

  const { handleSubmit, control } = useForm({ defaultValues });

  const generateCsvLink = () => {
    let customFields = [];
    customFields = job.procedures.map((proc) => {
      return {
        label: proc.description ? proc.description : '',
        key: proc.description ? proc.description : ''
      };
    });

    const headers = [
      { label: 'sample id', key: 'sampleId' },
      { label: 'customer sample number', key: 'custSampleNum' },
      { label: 'sex', key: 'sex' },
      { label: 'group number', key: 'groupNum' },
      { label: 'test date', key: 'testDateTime' },
      { label: 'birth date', key: 'birthDate' },
      { label: 'age', key: 'age' },
      { label: 'method of use', key: 'mou' },
      { label: 'ease of use', key: 'eou' },
      { label: 'site', key: 'siteId' },
      { label: 'proctor', key: 'proctorId' },
      ...customFields
    ];

    const data = participantData.map((partRecord: Participant) => {
      let procedures = {};
      if (partRecord.participantData) {
        procedures = JSON.parse(partRecord?.participantData)?.procedures;
      }

      let mou = '';
      let eou = '';

      if (partRecord.participantData) {
        mou = JSON.parse(partRecord?.participantData)?.methodsOfUse;
      }

      if (partRecord.participantData) {
        eou = JSON.parse(partRecord?.participantData)?.easeOfUse;
      }

      const siteKey =
        sites.find((site) => partRecord.siteId === site.id)?.key ?? '';
      const proctorKey =
        proctors.find((proctor) => partRecord.proctorId === proctor.id)?.key ??
        '';

      return {
        sampleId: `=""${partRecord.sampleId}""`,
        custSampleNum: `=""${partRecord.custSampleNum ?? ''}""`,
        sex: partRecord.sex,
        groupNum: partRecord.groupNum,
        testDateTime: partRecord.testDateTime
          ? new Date(Number(partRecord.testDateTime)).toLocaleDateString(
              'en-US'
            )
          : '',
        birthDate: partRecord.birthDate
          ? new Date(Number(partRecord.birthDate)).toLocaleDateString('en-US')
          : '',
        age: partRecord.age,
        mou: mou,
        eou: eou,
        siteId: siteKey,
        proctorId: proctorKey,
        ...procedures
      };
    });

    return (
      <CSVLink
        style={{ textDecoration: 'none', color: 'inherit' }}
        data={data}
        headers={headers}
        filename={`${job.key}-participants.csv`}
      >
        Participant CSV
      </CSVLink>
    );
  };

  const isLinkableReport =
    linkReport ||
    ((reportType === 'ADULTRESECURING' || reportType === 'CHILDCT') &&
      (job.parent != null ||
        (job.children?.length != null && job.children?.length > 0)));

  return (
    <Dialog maxWidth='sm' fullWidth open>
      <DialogTitle>Generate Report</DialogTitle>
      <DialogContent>
        {error !== '' && <Alert severity='error'>{error}</Alert>}
        <FormControl margin='dense' disabled={!!defaultReportType} fullWidth>
          <InputLabel>Report Type</InputLabel>
          <Select
            label='Report Type'
            value={reportType}
            onChange={(event) => setReportType(event.target.value)}
          >
            {reportTypes.map((reportType) => (
              <MenuItem key={reportType.key} value={reportType.key}>
                {reportType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLinkableReport && (
          <FormControlLabel
            control={
              <Checkbox
                checked={linkReport}
                onChange={(e) => {
                  setLinkReport(e.target.checked);
                  if (e.target.checked) {
                    setReportType('LINKEDRESECURING');
                  } else if (defaultReportType) {
                    setReportType(defaultReportType);
                  }
                }}
              />
            }
            label='Link Adult & Child Report'
          />
        )}
        <Typography
          variant='h3'
          fontSize='1.125rem'
          fontWeight={500}
          paddingY='16px'
        >
          Report Suppression
        </Typography>
        <Divider />
        <Grid container>
          {user?.role === 'admin' && (
            <ReportSuppressionCheckbox
              name='objectivesProcedures'
              control={control}
            >
              Objectives & Procedures Report
            </ReportSuppressionCheckbox>
          )}
          <ReportSuppressionCheckbox name='picture' control={control}>
            Picture Report
          </ReportSuppressionCheckbox>
          {user?.role === 'admin' && (
            <ReportSuppressionCheckbox
              name='resultsDiscussion'
              control={control}
            >
              Results & Discussion Report
            </ReportSuppressionCheckbox>
          )}
          <ReportSuppressionCheckbox name='summary' control={control}>
            Summary Report
          </ReportSuppressionCheckbox>
          <ReportSuppressionCheckbox name='proctor' control={control}>
            Proctor Report
          </ReportSuppressionCheckbox>
          <ReportSuppressionCheckbox name='siteParticipation' control={control}>
            Site Report
          </ReportSuppressionCheckbox>
          <ReportSuppressionCheckbox name='ageDistribution' control={control}>
            Age Distribution Report
          </ReportSuppressionCheckbox>
          <ReportSuppressionCheckbox name='detail' control={control}>
            Detail Report
          </ReportSuppressionCheckbox>
          {user?.role === 'admin' && (
            <ReportSuppressionCheckbox name='packageId' control={control}>
              Package ID Report
            </ReportSuppressionCheckbox>
          )}
          {user?.role === 'admin' && (
            <ReportSuppressionCheckbox name='header' control={control}>
              Header Report
            </ReportSuppressionCheckbox>
          )}
          {user?.role === 'admin' && (
            <ReportSuppressionCheckbox name='history' control={control}>
              Report History Report
            </ReportSuppressionCheckbox>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        {user?.role === 'admin' && (
          <Button disabled={isLoading}>{generateCsvLink()}</Button>
        )}
        <Button
          disabled={isLoading}
          onClick={handleSubmit((values) => {
            if (!(user?.role === 'admin')) {
              values.objectivesProcedures = true;
              values.resultsDiscussion = true;
              values.header = true;
              values.history = true;
              values.packageId = true;
            }
            console.log('job.reportHistory', job.reportHistory);
            if (!job.reportHistory) {
              values.history = true;
            }
            console.log('generate report values', values);
            if (jobId) generate(jobId, values, reportType, false);
            onSubmit();
          })}
        >
          Generate
        </Button>
        {user?.role === 'admin' && (
          <Button
            disabled={isLoading}
            onClick={handleSubmit((values) => {
              if (jobId) generate(jobId, values, reportType, true);
              onSubmit();
            })}
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GeneratePDFDialog;
