/**
 * @generated SignedSource<<3107526f6de251453b6508c29723d1c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRegionMutation$variables = {
  enabled: boolean;
  name: string;
};
export type CreateRegionMutation$data = {
  readonly createRegion: {
    readonly enabled: boolean;
    readonly id: string;
    readonly name: string;
  };
};
export type CreateRegionMutation = {
  response: CreateRegionMutation$data;
  variables: CreateRegionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "enabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "enabled",
            "variableName": "enabled"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "Region",
    "kind": "LinkedField",
    "name": "createRegion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRegionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateRegionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ff404c51699639d34c9690586a8d963c",
    "id": null,
    "metadata": {},
    "name": "CreateRegionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRegionMutation(\n  $name: String!\n  $enabled: Boolean!\n) {\n  createRegion(input: {name: $name, enabled: $enabled}) {\n    id\n    name\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6e1ffeaa92d8bd49c48407dee7f1f31";

export default node;
