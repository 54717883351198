import AgeGroup from './AgeGroup';
import EaseOfUseRating from './EaseOfUseRating';
import JobCategory from './JobCategory';
import MethodOfUse from './MethodOfUse';
import Procedure from './Procedures';
import User from './User';
import Customer from './Customer';
import Region from './Region';

const defaultAdultMaxParticipantsPerSite = 24;
const defaultAdultMaxParticipantsPerProctor = 35;
const defaultChildMaxParticipantsPerSite = 20;
const defaultChildMaxParticipantsPerProctor = 30;

interface relatedJob {
  readonly id: string;
  readonly key: string;
}

interface Job {
  id?: string;
  key: string;
  customerId: string;
  customer: Customer;
  description?: string | null;
  notes?: string | null;
  jobCategoryId: string;
  jobCategory?: JobCategory | null;
  preparedById: string | null;
  preparedBy: User | null;
  regionIds?: string[];
  status: string;
  regions: readonly Region[] | null;
  readonly participants?: ReadonlyArray<{
    readonly age: number | null;
    readonly birthDate: string | null;
    readonly custSampleNum: string | null;
    readonly groupNum: number | null;
    readonly id: string;
    readonly jobId: string | null;
    readonly participantData: string | null;
    readonly createdById: string;
    readonly sampleId: string | null;
    readonly sex: string | null;
    readonly siteId: string;
    readonly testDateTime: string | null;
    readonly zip: string | null;
    readonly proctor?: string | null;
  } | null> | null;
  samplePicture?: string | null;
  sampleType: string | null;
  submitter: string | null;
  testType: string | null;
  participantType: string | null;
  requiredParticipants: number | null;
  numberOfParticipants: number | null;
  maxParticipantsPerSite: number | null;
  maxParticipantsPerProctor: number | null;
  customerTest: string | null;
  reportTree: number | null;
  testObjectId: string | null;
  requiredTorque: number | null;
  disclaimer: string | null;
  otherInfo: string | null;
  reportHistory: string | null;
  testDescription: string | null;
  objectives: string | null;
  procedureNotes: string | null;
  resultsDiscussion: string | null;
  methodsOfUse: readonly MethodOfUse[];
  procedures: readonly Procedure[];
  easeOfUseRatings: readonly EaseOfUseRating[];
  ageGroups: readonly AgeGroup[];
  updatedAt?: Date;
  updatedBy?: User | null;
  parentId?: string | null;
  parent?: relatedJob | null;
  children?: readonly { readonly id: string; readonly key: string }[] | null;
  endDate?: Date | null;
}

export default Job;
export {
  defaultAdultMaxParticipantsPerProctor,
  defaultAdultMaxParticipantsPerSite,
  defaultChildMaxParticipantsPerProctor,
  defaultChildMaxParticipantsPerSite
};
