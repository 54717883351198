/**
 * @generated SignedSource<<c150e9283ebaf27b410dc543a11cd59b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetRegionsQuery$variables = {};
export type GetRegionsQuery$data = {
  readonly getRegions: ReadonlyArray<{
    readonly enabled: boolean;
    readonly id: string;
    readonly name: string;
  }>;
};
export type GetRegionsQuery = {
  response: GetRegionsQuery$data;
  variables: GetRegionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Region",
    "kind": "LinkedField",
    "name": "getRegions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetRegionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetRegionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7da8f7453d128266a7d62a7818a49e80",
    "id": null,
    "metadata": {},
    "name": "GetRegionsQuery",
    "operationKind": "query",
    "text": "query GetRegionsQuery {\n  getRegions {\n    id\n    name\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "66104b0cbf755f3f76cfbf7cb6721b64";

export default node;
