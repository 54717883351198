import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetSitesQuery } from './__generated__/GetSitesQuery.graphql';

const getSitesQuery = graphql`
  query GetSitesQuery {
    getSites {
      id
      key
      name
      address
      address2
      city
      state
      zip
      region {
        id
        name
      }
    }
  }
`;

const useGetSitesQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetSitesQuery>(
    getSitesQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getSites;
};

export { useGetSitesQuery };
