import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';

import { useCreateProctorMutation } from '../../api/proctors/CreateProctorMutation';
import { useDeleteProctorMutation } from '../../api/proctors/DeleteProctorMutation';
import { useGetProctorsQuery } from '../../api/proctors/GetProctorsQuery';
import { useUpdateProctorMutation } from '../../api/proctors/UpdateProctorMutation';
import { useGetRegionsQuery } from '../../api/regions/GetRegionsQuery';
import DataTable from '../../components/DataTable';
import DataToolbar from '../../components/DataToolbar';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';
import ProctorDialog from '../../dialogs/ProctorDialog';
import Region from '../../models/Region';

const ProctorPage = () => {
  const [selectedId, setSelectedId] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showAddProctorDialog, setShowAddProctorDialog] = useState(false);
  const [showEditProctorDialog, setShowEditProctorDialog] = useState(false);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const regions = useGetRegionsQuery({ fetchKey: queryFetchKey });
  const proctors = useGetProctorsQuery({ fetchKey: queryFetchKey });
  const [createProctor, createProctorLoading] = useCreateProctorMutation();
  const [updateProctor, updateProctorLoading] = useUpdateProctorMutation();
  const [deleteProctor, deleteProctorLoading] = useDeleteProctorMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (
      !createProctorLoading &&
      !updateProctorLoading &&
      !deleteProctorLoading
    ) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createProctorLoading, updateProctorLoading, deleteProctorLoading]);

  const columns = [
    { field: 'key', headerName: 'ID', flex: 1 },
    { field: 'firstName', headerName: 'First Name', flex: 2 },
    { field: 'lastName', headerName: 'Last Name', flex: 2 },
    {
      field: 'regionIds',
      headerName: 'Regions',
      flex: 2,
      valueGetter: ({ row }: { row: { regions: readonly Region[] } }) => {
        return row.regions.map((region) => region.name).join(', ');
      }
    }
  ];

  return (
    <>
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Proctor Record'}
          errorBody={'There was an error submitting the Proctor record.'}
        />
      )}
      {showAddProctorDialog && (
        <ProctorDialog
          regions={regions}
          onCancel={() => setShowAddProctorDialog(false)}
          onSubmit={(proctor) =>
            createProctor({
              variables: proctor,
              onCompleted: (_response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                } else {
                  setShowAddProctorDialog(false);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            })
          }
        />
      )}
      {showEditProctorDialog && (
        <ProctorDialog
          proctor={proctors?.find((proctor) => proctor.id === selectedId)}
          regions={regions}
          onCancel={() => setShowEditProctorDialog(false)}
          onSubmit={(proctor) => {
            if (proctor.id) {
              updateProctor({
                variables: { ...proctor, id: proctor.id },
                onCompleted: (_response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  } else {
                    setShowEditProctorDialog(false);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
            }
          }}
        />
      )}
      <DataToolbar
        dataType='proctors'
        selectedId={selectedId}
        searchOptions={[]}
        onSearchChange={() => console.log('Search changed.')}
        onAdd={() => setShowAddProctorDialog(true)}
        onEdit={() => setShowEditProctorDialog(true)}
        onDelete={() => {
          if (selectedId) {
            const proctor = proctors?.find(
              (proctor) => proctor.id === selectedId
            );

            if (proctor?.id) {
              deleteProctor({
                variables: {
                  id: proctor.id
                },
                onCompleted: (_response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
            }
          }
        }}
      />
      <DataTable
        columns={columns}
        rows={proctors}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
    </>
  );
};

export default ProctorPage;
