import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  filterEmptyAgeGroups,
  filterEmptyDescriptions
} from '../../utils/validators';
import JobCategoryDialogProps from './MutateJobCategoryDialogProps';
import MethodOfUse from '../../models/MethodOfUse';
import Procedure from '../../models/Procedures';
import EaseOfUseRating from '../../models/EaseOfUseRating';
import AgeGroup from '../../models/AgeGroup';
import EditableTestListDataTable from '../../components/EditableTestListDataTable/EditableTestListDataTable';
import { defaultMethodsOfUse } from '../../models/MethodOfUse';
import { defaultProcedures } from '../../models/Procedures';
import { defaultEaseOfUseRatings } from '../../models/EaseOfUseRating';
import { defaultAgeGroups } from '../../models/AgeGroup';
import { formatPercentage } from '../../utils/formatters';
import { reportTypes } from '../../models/Report';

const addId = (row: any, index: number) => {
  const newRow = { id: String(index), ...row };
  return newRow;
};

const removeId = (row: any) => {
  const { id, ...newRow } = row;
  return newRow;
};

const FieldContainer = styled('div')`
  display: flex;
  gap: 16px;
`;

const StyledFormControl = styled(FormControl)`
  width: 225px;
`;

const StyledGridItem = styled(Grid)`
  height: 350px;
`;

const methodsOfUseColumns = [
  {
    field: 'description',
    headerName: 'Description',
    flex: 4,
    editable: true
  }
];

const proceduresColumns = [
  {
    field: 'description',
    headerName: 'Description',
    flex: 4,
    editable: true
  }
];

const easeOfUseRatingColumns = [
  {
    field: 'description',
    headerName: 'Description',
    flex: 4,
    editable: true
  }
];

const ageGroupColumns = [
  {
    field: 'groupNumber',
    headerName: 'Age Groups',
    flex: 1,
    editable: true,
    type: 'number'
  },
  {
    field: 'beginAge',
    headerName: 'Begin Age',
    flex: 1,
    editable: true,
    type: 'number'
  },
  {
    field: 'endAge',
    headerName: 'End Age',
    flex: 1,
    editable: true,
    type: 'number'
  },
  {
    field: 'groupPercentage',
    headerName: 'Group Percentage',
    flex: 1,
    editable: true,
    type: 'number',
    valueFormatter: formatPercentage
  }
];

const MutateJobCategoryDialog = ({
  jobCategory,
  parentJobCategories,
  onCancel,
  onSubmit
}: JobCategoryDialogProps) => {
  const [reportType, setReportType] = useState(jobCategory?.reportType || '');
  const [parentId, setParentId] = useState(jobCategory?.parentId || 'None');
  const [methodsOfUse, setMethodsOfUse] = useState<readonly MethodOfUse[]>(
    jobCategory ? [...jobCategory.methodsOfUse.map(addId)] : defaultMethodsOfUse
  );
  const [procedures, setProcedures] = useState<readonly Procedure[]>(
    jobCategory ? [...jobCategory.procedures.map(addId)] : defaultProcedures
  );
  const [easeOfUseRatings, setEaseOfUseRatings] = useState<
    readonly EaseOfUseRating[]
  >(
    jobCategory
      ? [...jobCategory.easeOfUseRatings.map(addId)]
      : defaultEaseOfUseRatings
  );
  const [ageGroups, setAgeGroups] = useState<readonly AgeGroup[]>(
    jobCategory ? [...jobCategory.ageGroups.map(addId)] : defaultAgeGroups
  );

  const defaultValues = {
    name: jobCategory?.name ?? '',
    description: jobCategory?.description ?? ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog maxWidth='xl' open fullWidth>
      <DialogTitle>
        {jobCategory ? 'Edit Job Category' : 'Add Job Category'}
      </DialogTitle>
      <DialogContent>
        <FieldContainer>
          <TextField
            label='Name'
            margin='dense'
            helperText={errors.name ? 'Field is required.' : ''}
            error={!!errors.name}
            autoFocus
            {...register('name', { required: true })}
          />
          <TextField
            label='Description'
            margin='dense'
            {...register('description')}
          />
          <StyledFormControl margin='dense'>
            <InputLabel>Report Type</InputLabel>
            <Select
              label='Report Type'
              value={reportType}
              onChange={(event) => setReportType(event.target.value)}
            >
              {reportTypes.map((reportType) => (
                <MenuItem key={reportType.key} value={reportType.key}>
                  {reportType.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl margin='dense'>
            <InputLabel>Parent Category</InputLabel>
            <Select
              label='Parent Category'
              value={parentId}
              onChange={(event) => setParentId(event.target.value)}
            >
              <MenuItem value={'None'}>None</MenuItem>
              {parentJobCategories
                .filter((category) => category.id !== jobCategory?.id)
                .map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
          </StyledFormControl>
        </FieldContainer>
        <Grid container spacing={2}>
          <StyledGridItem item xs={6}>
            <EditableTestListDataTable
              label='Methods of Use'
              columns={methodsOfUseColumns}
              rows={methodsOfUse}
              addButtonText='Add Method of Use'
              onChange={setMethodsOfUse}
            />
          </StyledGridItem>
          <StyledGridItem item xs={6}>
            <EditableTestListDataTable
              label='Procedures'
              columns={proceduresColumns}
              rows={procedures}
              addButtonText='Add Procedure'
              onChange={setProcedures}
            />
          </StyledGridItem>
          <StyledGridItem item xs={6}>
            <EditableTestListDataTable
              label='Ease of Use'
              columns={easeOfUseRatingColumns}
              rows={easeOfUseRatings}
              addButtonText='Add Ease of Use'
              onChange={setEaseOfUseRatings}
            />
          </StyledGridItem>
          <StyledGridItem item xs={6}>
            <EditableTestListDataTable
              label='Age Groups'
              columns={ageGroupColumns}
              rows={ageGroups}
              addButtonText='Add Age Group'
              onChange={setAgeGroups}
            />
          </StyledGridItem>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({
              id: jobCategory?.id,
              reportType: reportType === '' ? null : reportType,
              parentId: parentId === 'None' ? null : parentId,
              methodsOfUse: methodsOfUse
                .filter(filterEmptyDescriptions)
                .map(removeId),
              procedures: procedures
                .filter(filterEmptyDescriptions)
                .map(removeId),
              easeOfUseRatings: easeOfUseRatings
                .filter(filterEmptyDescriptions)
                .map(removeId),
              ageGroups: ageGroups
                .filter(filterEmptyAgeGroups)
                .map((group) => ({
                  groupNumber: Number(group.groupNumber),
                  beginAge: Number(group.beginAge),
                  endAge: Number(group.endAge),
                  groupPercentage: Number(group.groupPercentage)
                })),
              ...fields
            });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MutateJobCategoryDialog;
