import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateCustomerMutation } from './__generated__/UpdateCustomerMutation.graphql';

const updateCustomerMutation = graphql`
  mutation UpdateCustomerMutation(
    $id: ID!
    $key: String!
    $name: String!
    $categoryId: ID
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $status: String
  ) {
    updateCustomer(
      input: {
        id: $id
        key: $key
        name: $name
        categoryId: $categoryId
        address: $address
        address2: $address2
        city: $city
        state: $state
        zip: $zip
        status: $status
      }
    ) {
      id
      key
      name
      categoryId
      address
      address2
      city
      state
      zip
      status
    }
  }
`;

const useUpdateCustomerMutation = () =>
  useMutation<UpdateCustomerMutation>(updateCustomerMutation);

export { useUpdateCustomerMutation };
