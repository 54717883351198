/**
 * @generated SignedSource<<7636f864f38e209973ec31c08a236741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetCustomersQuery$variables = {};
export type GetCustomersQuery$data = {
  readonly getCustomers: ReadonlyArray<{
    readonly address: string | null;
    readonly address2: string | null;
    readonly categoryId: string | null;
    readonly city: string | null;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly state: string | null;
    readonly status: string | null;
    readonly zip: string | null;
  }>;
};
export type GetCustomersQuery = {
  response: GetCustomersQuery$data;
  variables: GetCustomersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Customer",
    "kind": "LinkedField",
    "name": "getCustomers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categoryId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCustomersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetCustomersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6c75a312e999be98d2f1e63d03b1f174",
    "id": null,
    "metadata": {},
    "name": "GetCustomersQuery",
    "operationKind": "query",
    "text": "query GetCustomersQuery {\n  getCustomers {\n    id\n    key\n    name\n    categoryId\n    address\n    address2\n    city\n    state\n    zip\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "d62d417388891f0137dd43a2e189160e";

export default node;
