import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteJobMutation } from './__generated__/DeleteJobMutation.graphql';

const deleteJobMutation = graphql`
  mutation DeleteJobMutation($id: ID!) {
    deleteJob(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteJobMutation = () =>
  useMutation<DeleteJobMutation>(deleteJobMutation);

export { useDeleteJobMutation };
