import { styled, Typography } from '@mui/material';
import { Image } from '@mui/icons-material';

import SamplePicturePanelProps from './SamplePicturePanelProps';

const NoImage = styled('div')`
  width: 100%;
  height: calc(100% - 97.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SamplePicturePanel = ({ samplePicture }: SamplePicturePanelProps) => {
  return (
    <>
      {samplePicture && <img src={samplePicture} />}
      {!samplePicture && (
        <NoImage>
          <Image fontSize='large' />
          <Typography>No Sample Picture</Typography>
        </NoImage>
      )}
    </>
  );
};

export default SamplePicturePanel;
