/**
 * @generated SignedSource<<bf81e5d3f807d3aa754b81c3a408f041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetUsersQuery$variables = {};
export type GetUsersQuery$data = {
  readonly getUsers: ReadonlyArray<{
    readonly email: string;
    readonly fullName: string | null;
    readonly id: string;
    readonly isActive: boolean | null;
    readonly regions: ReadonlyArray<{
      readonly enabled: boolean;
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly role: string;
    readonly signatureImage: string | null;
    readonly title: string | null;
    readonly username: string | null;
  }>;
};
export type GetUsersQuery = {
  response: GetUsersQuery$data;
  variables: GetUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "getUsers",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signatureImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUsersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetUsersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e409ee5fb74fa0a36691aefb80e68bc",
    "id": null,
    "metadata": {},
    "name": "GetUsersQuery",
    "operationKind": "query",
    "text": "query GetUsersQuery {\n  getUsers {\n    id\n    email\n    username\n    fullName\n    title\n    role\n    isActive\n    signatureImage\n    regions {\n      id\n      name\n      enabled\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d58c3d11151fd5cbc8a1bf4890f99c7";

export default node;
