import {
  RequestParameters,
  Variables,
  Environment,
  Network,
  RecordSource,
  Store
} from 'relay-runtime';

import config from '../config';

const fetchRelay = async (params: RequestParameters, variables: Variables) => {
  const response = await fetch(config.REACT_APP_GRAPHQL_URL, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      query: params.text,
      variables
    }),
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('bos_token')}`
    }
  });
  return await response.json();
};

const RelayEnvironment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource())
});

export default RelayEnvironment;
