import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import permissions from 'config/permissions';

import UserDialogProps from './UserDialogProps';
import MultiSelectInput from '../../components/MultiSelectInput';
import Region from '../../models/Region';
import SignaturePicker from './SignaturePicker';

const UserDialog = ({ user, regions, onCancel, onSubmit }: UserDialogProps) => {
  const [role, setRole] = useState(user?.role ?? Object.keys(permissions)[0]);
  const [selectedRegions, setSelectedRegions] = useState<readonly Region[]>(
    user?.regions ? user.regions : []
  );
  const [signatureImage, setSignatureImage] = useState(user?.signatureImage);

  const defaultValues = {
    email: user?.email ?? '',
    username: user?.username ?? '',
    fullName: user?.fullName ?? '',
    title: user?.title ?? '',
    password: '',
    confirmPassword: '',
    isActive: user?.isActive ?? true
  };

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>{user ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        <TextField
          label='Email'
          type='email'
          margin='dense'
          autoComplete='off'
          helperText={errors.email ? 'Field is required.' : ''}
          error={!!errors.email}
          fullWidth
          autoFocus
          {...register('email', { required: true })}
        />
        <TextField
          label='Username'
          type='text'
          margin='dense'
          autoComplete='off'
          helperText={errors.username ? 'Field is required.' : ''}
          error={!!errors.username}
          fullWidth
          {...register('username', { required: true })}
        />
        <TextField
          label='Full Name'
          type='text'
          margin='dense'
          autoComplete='off'
          fullWidth
          {...register('fullName')}
        />
        <TextField
          label='Title'
          type='text'
          margin='dense'
          autoComplete='off'
          fullWidth
          {...register('title')}
        />
        <TextField
          label='Password'
          type='password'
          margin='dense'
          autoComplete='off'
          helperText={errors.password ? 'Field is required.' : ''}
          error={!!errors.password}
          fullWidth
          {...register('password', { required: !user })}
        />
        <TextField
          label='Confirm Password'
          type='password'
          margin='dense'
          autoComplete='off'
          helperText={errors.confirmPassword ? 'Passwords do no match.' : ''}
          error={!!errors.confirmPassword}
          fullWidth
          {...register('confirmPassword', {
            required: !user,
            validate: (value) => value === getValues().password
          })}
        />
        <FormControl margin='dense' fullWidth>
          <InputLabel>Role</InputLabel>
          <Select
            label='Role'
            value={role}
            onChange={(event) => setRole(event.target.value)}
          >
            {Object.entries(permissions).map(([key]) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MultiSelectInput
          label='Regions'
          options={regions.map((region) => region.name)}
          selectedOptions={selectedRegions.map((region) => region.name)}
          onSelect={(values) => {
            const newRegions = regions.filter((region) =>
              values.includes(region.name)
            );
            setSelectedRegions(newRegions);
          }}
        />
        <label>Signature</label>
        <SignaturePicker
          signatureImage={signatureImage}
          onUpload={(base64Image) => setSignatureImage(base64Image)}
          onClear={() => setSignatureImage(null)}
        />
        <label>Active</label>
        <Controller
          name='isActive'
          control={control}
          render={({ field }) => (
            <Checkbox
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({
              id: user?.id,
              role,
              signatureImage,
              regionIds: selectedRegions.map((region) => region?.id as string),
              ...fields
            });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
