/**
 * @generated SignedSource<<5ee89f07495670798064aff5378bb581>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetProctorsQuery$variables = {};
export type GetProctorsQuery$data = {
  readonly getProctors: ReadonlyArray<{
    readonly firstName: string | null;
    readonly id: string;
    readonly key: string;
    readonly lastName: string | null;
    readonly regions: ReadonlyArray<{
      readonly enabled: boolean;
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly state: string | null;
  }>;
};
export type GetProctorsQuery = {
  response: GetProctorsQuery$data;
  variables: GetProctorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Proctor",
    "kind": "LinkedField",
    "name": "getProctors",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetProctorsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetProctorsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ffefbadf9c59a5cd7f5878de05e8b8a9",
    "id": null,
    "metadata": {},
    "name": "GetProctorsQuery",
    "operationKind": "query",
    "text": "query GetProctorsQuery {\n  getProctors {\n    id\n    key\n    firstName\n    lastName\n    state\n    regions {\n      id\n      name\n      enabled\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a279715d84b5de3a58bd5729c40e2c51";

export default node;
