/**
 * @generated SignedSource<<540cb9250218dac366aa44e3642825ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetJobsQuery$variables = {};
export type GetJobsQuery$data = {
  readonly getJobs: ReadonlyArray<{
    readonly ageGroups: ReadonlyArray<{
      readonly beginAge: number;
      readonly endAge: number;
      readonly groupNumber: number;
      readonly groupPercentage: number;
    }>;
    readonly children: ReadonlyArray<{
      readonly id: string;
      readonly key: string;
    }> | null;
    readonly customer: {
      readonly categoryId: string | null;
      readonly id: string;
      readonly key: string;
      readonly name: string;
    };
    readonly customerId: string;
    readonly customerTest: string | null;
    readonly description: string | null;
    readonly disclaimer: string | null;
    readonly easeOfUseRatings: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly endDate: any | null;
    readonly id: string;
    readonly jobCategory: {
      readonly ageGroups: ReadonlyArray<{
        readonly beginAge: number;
        readonly endAge: number;
        readonly groupNumber: number;
        readonly groupPercentage: number;
      }>;
      readonly description: string | null;
      readonly easeOfUseRatings: ReadonlyArray<{
        readonly description: string;
      }>;
      readonly id: string;
      readonly methodsOfUse: ReadonlyArray<{
        readonly description: string;
      }>;
      readonly name: string;
      readonly parentId: string | null;
      readonly procedures: ReadonlyArray<{
        readonly description: string;
      }>;
      readonly reportType: string | null;
    } | null;
    readonly jobCategoryId: string;
    readonly key: string;
    readonly maxParticipantsPerProctor: number | null;
    readonly maxParticipantsPerSite: number | null;
    readonly methodsOfUse: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly notes: string | null;
    readonly numberOfParticipants: number | null;
    readonly objectives: string | null;
    readonly otherInfo: string | null;
    readonly parent: {
      readonly id: string;
      readonly key: string;
    } | null;
    readonly participantType: string | null;
    readonly participants: ReadonlyArray<{
      readonly age: number | null;
      readonly birthDate: string | null;
      readonly createdById: string;
      readonly custSampleNum: string | null;
      readonly groupNum: number | null;
      readonly id: string;
      readonly jobId: string | null;
      readonly participantData: string | null;
      readonly proctorId: string | null;
      readonly sampleId: string | null;
      readonly sex: string | null;
      readonly siteId: string;
      readonly testDateTime: string | null;
      readonly zip: string | null;
    } | null> | null;
    readonly preparedBy: {
      readonly email: string;
      readonly id: string;
      readonly isActive: boolean | null;
      readonly role: string;
      readonly username: string | null;
    } | null;
    readonly preparedById: string | null;
    readonly procedureNotes: string | null;
    readonly procedures: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly regions: ReadonlyArray<{
      readonly enabled: boolean;
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly reportHistory: string | null;
    readonly reportTree: number | null;
    readonly requiredParticipants: number | null;
    readonly requiredTorque: number | null;
    readonly resultsDiscussion: string | null;
    readonly samplePicture: string | null;
    readonly sampleType: string | null;
    readonly status: string;
    readonly submitter: string | null;
    readonly testDescription: string | null;
    readonly testObjectId: string | null;
    readonly testType: string | null;
    readonly updatedAt: any | null;
    readonly updatedBy: {
      readonly email: string;
      readonly isActive: boolean | null;
      readonly role: string;
      readonly username: string | null;
    } | null;
  }>;
};
export type GetJobsQuery = {
  response: GetJobsQuery$data;
  variables: GetJobsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobCategoryId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v10 = [
  (v5/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "MethodOfUse",
  "kind": "LinkedField",
  "name": "methodsOfUse",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Procedure",
  "kind": "LinkedField",
  "name": "procedures",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "EaseOfUseRating",
  "kind": "LinkedField",
  "name": "easeOfUseRatings",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "beginAge",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAge",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupPercentage",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "AgeGroup",
  "kind": "LinkedField",
  "name": "ageGroups",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preparedById",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "preparedBy",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/),
    (v22/*: any*/),
    (v23/*: any*/)
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "regions",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Participant",
  "kind": "LinkedField",
  "name": "participants",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdById",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sampleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custSampleNum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupNum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testDateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "participantData",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proctorId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "samplePicture",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sampleType",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submitter",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testType",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "participantType",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredParticipants",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfParticipants",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxParticipantsPerSite",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxParticipantsPerProctor",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerTest",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportTree",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testObjectId",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredTorque",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disclaimer",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherInfo",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportHistory",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testDescription",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectives",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procedureNotes",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resultsDiscussion",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v50 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v51 = {
  "alias": null,
  "args": null,
  "concreteType": "Job",
  "kind": "LinkedField",
  "name": "parent",
  "plural": false,
  "selections": (v50/*: any*/),
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "concreteType": "Job",
  "kind": "LinkedField",
  "name": "children",
  "plural": true,
  "selections": (v50/*: any*/),
  "storageKey": null
},
v53 = [
  (v5/*: any*/),
  (v0/*: any*/)
],
v54 = {
  "alias": null,
  "args": null,
  "concreteType": "MethodOfUse",
  "kind": "LinkedField",
  "name": "methodsOfUse",
  "plural": true,
  "selections": (v53/*: any*/),
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "concreteType": "Procedure",
  "kind": "LinkedField",
  "name": "procedures",
  "plural": true,
  "selections": (v53/*: any*/),
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "concreteType": "EaseOfUseRating",
  "kind": "LinkedField",
  "name": "easeOfUseRatings",
  "plural": true,
  "selections": (v53/*: any*/),
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "concreteType": "AgeGroup",
  "kind": "LinkedField",
  "name": "ageGroups",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetJobsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "getJobs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobCategory",
            "kind": "LinkedField",
            "name": "jobCategory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v18/*: any*/),
          (v49/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "updatedBy",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v51/*: any*/),
          (v52/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetJobsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "getJobs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JobCategory",
            "kind": "LinkedField",
            "name": "jobCategory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v54/*: any*/),
              (v55/*: any*/),
              (v56/*: any*/),
              (v57/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v49/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "updatedBy",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v21/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v51/*: any*/),
          (v52/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd8f72749c5b4e452637b2b838327d4c",
    "id": null,
    "metadata": {},
    "name": "GetJobsQuery",
    "operationKind": "query",
    "text": "query GetJobsQuery {\n  getJobs {\n    id\n    key\n    customerId\n    customer {\n      id\n      key\n      name\n      categoryId\n    }\n    description\n    notes\n    jobCategoryId\n    jobCategory {\n      id\n      name\n      description\n      reportType\n      parentId\n      methodsOfUse {\n        description\n        id\n      }\n      procedures {\n        description\n        id\n      }\n      easeOfUseRatings {\n        description\n        id\n      }\n      ageGroups {\n        groupNumber\n        beginAge\n        endAge\n        groupPercentage\n        id\n      }\n    }\n    preparedById\n    preparedBy {\n      id\n      email\n      username\n      role\n      isActive\n    }\n    status\n    regions {\n      id\n      name\n      enabled\n    }\n    participants {\n      id\n      jobId\n      createdById\n      siteId\n      sampleId\n      custSampleNum\n      sex\n      groupNum\n      testDateTime\n      birthDate\n      age\n      zip\n      participantData\n      proctorId\n    }\n    samplePicture\n    sampleType\n    submitter\n    testType\n    participantType\n    requiredParticipants\n    numberOfParticipants\n    maxParticipantsPerSite\n    maxParticipantsPerProctor\n    customerTest\n    reportTree\n    testObjectId\n    requiredTorque\n    disclaimer\n    otherInfo\n    reportHistory\n    testDescription\n    objectives\n    procedureNotes\n    resultsDiscussion\n    endDate\n    methodsOfUse {\n      description\n      id\n    }\n    procedures {\n      description\n      id\n    }\n    easeOfUseRatings {\n      description\n      id\n    }\n    ageGroups {\n      groupNumber\n      beginAge\n      endAge\n      groupPercentage\n      id\n    }\n    updatedAt\n    updatedBy {\n      email\n      role\n      isActive\n      username\n      id\n    }\n    parent {\n      id\n      key\n    }\n    children {\n      id\n      key\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6de4bee86462a89a93f818eb01a61d9";

export default node;
