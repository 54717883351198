/**
 * @generated SignedSource<<be008ea7d31b18b7999108a877e10243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetCustomerCategoriesQuery$variables = {};
export type GetCustomerCategoriesQuery$data = {
  readonly getCustomerCategories: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
};
export type GetCustomerCategoriesQuery = {
  response: GetCustomerCategoriesQuery$data;
  variables: GetCustomerCategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerCategory",
    "kind": "LinkedField",
    "name": "getCustomerCategories",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCustomerCategoriesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetCustomerCategoriesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d1e681217318f5c03f0f1782d13e990c",
    "id": null,
    "metadata": {},
    "name": "GetCustomerCategoriesQuery",
    "operationKind": "query",
    "text": "query GetCustomerCategoriesQuery {\n  getCustomerCategories {\n    id\n    name\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "a10abbd28b9cfb5f8adaf48256969dba";

export default node;
