import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetCustomerCategoriesQuery } from './__generated__/GetCustomerCategoriesQuery.graphql';

const getCustomerCategoriesQuery = graphql`
  query GetCustomerCategoriesQuery {
    getCustomerCategories {
      id
      name
      description
    }
  }
`;

const useGetCustomerCategoriesQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetCustomerCategoriesQuery>(
    getCustomerCategoriesQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getCustomerCategories;
};

export { useGetCustomerCategoriesQuery };
