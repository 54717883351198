import { Button, ButtonProps, styled } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { Add } from '@mui/icons-material';

import { useAuthentication } from '../../api/authentication';
import { withPermission } from '../Permissions';

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  gap: 8px;
`;

const ButtonWithPermission = withPermission<ButtonProps>(Button);

const EditToolbar = ({
  type,
  onAdd,
  toolbarButtons
}: {
  type: string;
  onAdd: () => void;
  toolbarButtons?: () => JSX.Element;
}) => {
  const { user } = useAuthentication();

  return (
    <StyledGridToolbarContainer>
      <>
        <ButtonWithPermission
          role={user?.role}
          type={type}
          operation='create'
          componentProps={{
            onClick: onAdd,
            color: 'primary',
            variant: 'outlined',
            startIcon: <Add />
          }}
        >
          Add Record
        </ButtonWithPermission>
        {toolbarButtons}
      </>
    </StyledGridToolbarContainer>
  );
};

export default EditToolbar;
