const reportTypes = [
  { key: 'ADULTRESECURING', label: 'Adult Resecuring Test (CRT)' },
  { key: 'ADULTUNITPACKAGE', label: 'Adult Unit Package Test' },
  { key: 'ADULTVIAL', label: 'Adult Vial Test' },
  { key: 'CHILDCT', label: 'Child CT Test' },
  { key: 'CHILDUP', label: 'Child UP Test' },
  { key: 'CHILDVIAL', label: 'Child Vial Test' },
  { key: 'LINKEDRESECURING', label: '[Linked] Adult Resecuring - Child CT' }
];

export { reportTypes };
