/**
 * @generated SignedSource<<55aea34b0d2dd497466ddb39ab4273f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetJobParticipantsQuery$variables = {
  id: string;
};
export type GetJobParticipantsQuery$data = {
  readonly getJob: {
    readonly participants: ReadonlyArray<{
      readonly age: number | null;
      readonly birthDate: string | null;
      readonly createdById: string;
      readonly custSampleNum: string | null;
      readonly groupNum: number | null;
      readonly id: string;
      readonly jobId: string | null;
      readonly participantData: string | null;
      readonly proctorId: string | null;
      readonly sampleId: string | null;
      readonly sex: string | null;
      readonly siteId: string;
      readonly testDateTime: string | null;
      readonly zip: string | null;
    } | null> | null;
  };
};
export type GetJobParticipantsQuery = {
  response: GetJobParticipantsQuery$data;
  variables: GetJobParticipantsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Participant",
  "kind": "LinkedField",
  "name": "participants",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdById",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sampleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "custSampleNum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupNum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testDateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "participantData",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proctorId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetJobParticipantsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "getJob",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetJobParticipantsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "getJob",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d50d1439dee1e0652b355959cf87a9bd",
    "id": null,
    "metadata": {},
    "name": "GetJobParticipantsQuery",
    "operationKind": "query",
    "text": "query GetJobParticipantsQuery(\n  $id: ID!\n) {\n  getJob(id: $id) {\n    participants {\n      id\n      jobId\n      createdById\n      siteId\n      sampleId\n      custSampleNum\n      sex\n      groupNum\n      testDateTime\n      birthDate\n      age\n      zip\n      participantData\n      proctorId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7adbf5557dfaf33515cafbe732d4473";

export default node;
