import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteProctorMutation } from './__generated__/DeleteProctorMutation.graphql';

const deleteProctorMutation = graphql`
  mutation DeleteProctorMutation($id: ID!) {
    deleteProctor(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteProctorMutation = () =>
  useMutation<DeleteProctorMutation>(deleteProctorMutation);

export { useDeleteProctorMutation };
