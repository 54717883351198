import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateSiteMutation } from './__generated__/CreateSiteMutation.graphql';

const createSiteMutation = graphql`
  mutation CreateSiteMutation(
    $key: String!
    $name: String!
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $regionId: String
  ) {
    createSite(
      regionId: $regionId
      input: {
        key: $key
        name: $name
        address: $address
        address2: $address2
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      id
      key
      name
      address
      address2
      city
      state
      zip
    }
  }
`;

const useCreateSiteMutation = () =>
  useMutation<CreateSiteMutation>(createSiteMutation);

export { useCreateSiteMutation };
