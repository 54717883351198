import { useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import axios from 'axios';
import graphql from 'babel-plugin-relay/macro';

import { useAuthentication } from '../authentication';
import { generatePDFMutation } from './__generated__/generatePDFMutation.graphql';

const openPDF = async (url: string, token: string) => {
  try {
    axios
      .get(url, {
        responseType: 'blob',
        headers: { Authorization: 'Bearer ' + token }
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = fileURL;
        }
      })
      .catch((error) => {
        return error;
      });
  } catch (err) {
    return err;
  }
  return true;
};

function useGeneratePDF(upload = false) {
  const { token } = useAuthentication();
  const [generatePDF, isMutationInFlight] =
    useMutation<generatePDFMutation>(graphql`
      mutation generatePDFMutation(
        $jobId: ID!
        $suppressedReports: SuppressedReportsInput
        $reportType: ReportType!
        $upload: Boolean
      ) {
        generatePDF(
          input: {
            jobId: $jobId
            suppressedReports: $suppressedReports
            reportType: $reportType
            upload: $upload
          }
        ) {
          path
        }
      }
    `);
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState();
  const [error, setError] = useState<string>('');
  function resetError() {
    setError('');
  }

  function generate(
    jobId: string,
    suppressedReports: any,
    reportType: any,
    upload: boolean
  ) {
    setIsLoading(true);
    setError('');
    if (token) {
      generatePDF({
        variables: {
          jobId,
          suppressedReports,
          reportType,
          upload
        },
        onCompleted: (response) => {
          if (response?.generatePDF?.path) {
            if (upload) {
              alert('Report Uploaded To Client Portal');
            } else {
              const result = openPDF(response?.generatePDF?.path, token);
              result.then((res) => {
                if (res !== true) {
                  setError(`Error ${res}`);
                }
              });
            }
          } else {
            setError('Error Generating Report');
          }
        },
        onError: (err) => {
          setError(`Error communicating with API: ${err.message}`);
        }
      });
    }
    return error;
  }

  useEffect(() => {
    setIsLoading(isMutationInFlight);
  }, [isMutationInFlight]);

  return { generate, isLoading, error, resetError };
}

export default useGeneratePDF;
