import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateJobMutation } from './__generated__/UpdateJobMutation.graphql';

const updateJobMutation = graphql`
  mutation UpdateJobMutation(
    $id: ID!
    $key: String!
    $customerId: ID!
    $description: String
    $notes: String
    $jobCategoryId: ID!
    $preparedById: ID!
    $regionIds: [ID!]!
    $status: String!
    $samplePicture: String
    $sampleType: String
    $submitter: String
    $testType: String
    $participantType: String
    $requiredParticipants: Int
    $numberOfParticipants: Int
    $maxParticipantsPerSite: Int
    $maxParticipantsPerProctor: Int
    $customerTest: String
    $reportTree: Int
    $testObjectId: ID
    $requiredTorque: Int
    $disclaimer: String
    $otherInfo: String
    $reportHistory: String
    $testDescription: String
    $objectives: String
    $procedureNotes: String
    $resultsDiscussion: String
    $endDate: DateTime
    $methodsOfUse: [UpdateMethodOfUseInput]
    $procedures: [UpdateProcedureInput]
    $easeOfUseRatings: [UpdateEaseOfUseRatingInput]
    $ageGroups: [UpdateAgeGroupInput]
    $parentId: ID
    $childrenIds: [ID]
  ) {
    updateJob(
      input: {
        id: $id
        key: $key
        customerId: $customerId
        description: $description
        notes: $notes
        jobCategoryId: $jobCategoryId
        preparedById: $preparedById
        regionIds: $regionIds
        status: $status
        samplePicture: $samplePicture
        sampleType: $sampleType
        submitter: $submitter
        testType: $testType
        participantType: $participantType
        requiredParticipants: $requiredParticipants
        numberOfParticipants: $numberOfParticipants
        maxParticipantsPerSite: $maxParticipantsPerSite
        maxParticipantsPerProctor: $maxParticipantsPerProctor
        customerTest: $customerTest
        reportTree: $reportTree
        testObjectId: $testObjectId
        requiredTorque: $requiredTorque
        disclaimer: $disclaimer
        otherInfo: $otherInfo
        reportHistory: $reportHistory
        testDescription: $testDescription
        objectives: $objectives
        procedureNotes: $procedureNotes
        resultsDiscussion: $resultsDiscussion
        methodsOfUse: $methodsOfUse
        procedures: $procedures
        easeOfUseRatings: $easeOfUseRatings
        ageGroups: $ageGroups
        parentId: $parentId
        childrenIds: $childrenIds
        endDate: $endDate
      }
    ) {
      id
    }
  }
`;

const useUpdateJobMutation = () =>
  useMutation<UpdateJobMutation>(updateJobMutation);

export { useUpdateJobMutation };
