/**
 * @generated SignedSource<<55fad29af4c0dec4a5bea27f8f2da449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMethodOfUseInput = {
  description: string;
};
export type CreateProcedureInput = {
  description: string;
};
export type CreateEaseOfUseRatingInput = {
  description: string;
};
export type CreateAgeGroupInput = {
  beginAge: number;
  endAge: number;
  groupNumber: number;
  groupPercentage: number;
};
export type CreateJobCategoryMutation$variables = {
  ageGroups?: ReadonlyArray<CreateAgeGroupInput | null> | null;
  description?: string | null;
  easeOfUseRatings?: ReadonlyArray<CreateEaseOfUseRatingInput | null> | null;
  methodsOfUse?: ReadonlyArray<CreateMethodOfUseInput | null> | null;
  name: string;
  parentId?: string | null;
  procedures?: ReadonlyArray<CreateProcedureInput | null> | null;
  reportType?: string | null;
};
export type CreateJobCategoryMutation$data = {
  readonly createJobCategory: {
    readonly ageGroups: ReadonlyArray<{
      readonly beginAge: number;
      readonly endAge: number;
      readonly groupNumber: number;
      readonly groupPercentage: number;
    }>;
    readonly description: string | null;
    readonly easeOfUseRatings: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly id: string;
    readonly methodsOfUse: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly name: string;
    readonly parentId: string | null;
    readonly procedures: ReadonlyArray<{
      readonly description: string;
    }>;
    readonly reportType: string | null;
  } | null;
};
export type CreateJobCategoryMutation = {
  response: CreateJobCategoryMutation$data;
  variables: CreateJobCategoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ageGroups"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "easeOfUseRatings"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "methodsOfUse"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "procedures"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportType"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "ageGroups",
        "variableName": "ageGroups"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "easeOfUseRatings",
        "variableName": "easeOfUseRatings"
      },
      {
        "kind": "Variable",
        "name": "methodsOfUse",
        "variableName": "methodsOfUse"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "parentId",
        "variableName": "parentId"
      },
      {
        "kind": "Variable",
        "name": "procedures",
        "variableName": "procedures"
      },
      {
        "kind": "Variable",
        "name": "reportType",
        "variableName": "reportType"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v14 = [
  (v11/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupNumber",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "beginAge",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAge",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupPercentage",
  "storageKey": null
},
v19 = [
  (v11/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateJobCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "JobCategory",
        "kind": "LinkedField",
        "name": "createJobCategory",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MethodOfUse",
            "kind": "LinkedField",
            "name": "methodsOfUse",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Procedure",
            "kind": "LinkedField",
            "name": "procedures",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EaseOfUseRating",
            "kind": "LinkedField",
            "name": "easeOfUseRatings",
            "plural": true,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AgeGroup",
            "kind": "LinkedField",
            "name": "ageGroups",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateJobCategoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "JobCategory",
        "kind": "LinkedField",
        "name": "createJobCategory",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MethodOfUse",
            "kind": "LinkedField",
            "name": "methodsOfUse",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Procedure",
            "kind": "LinkedField",
            "name": "procedures",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EaseOfUseRating",
            "kind": "LinkedField",
            "name": "easeOfUseRatings",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AgeGroup",
            "kind": "LinkedField",
            "name": "ageGroups",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba4e9c327a2884e5fffb21cdf74f5a52",
    "id": null,
    "metadata": {},
    "name": "CreateJobCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation CreateJobCategoryMutation(\n  $name: String!\n  $description: String\n  $reportType: String\n  $parentId: ID\n  $methodsOfUse: [CreateMethodOfUseInput]\n  $procedures: [CreateProcedureInput]\n  $easeOfUseRatings: [CreateEaseOfUseRatingInput]\n  $ageGroups: [CreateAgeGroupInput]\n) {\n  createJobCategory(input: {name: $name, description: $description, reportType: $reportType, parentId: $parentId, methodsOfUse: $methodsOfUse, procedures: $procedures, easeOfUseRatings: $easeOfUseRatings, ageGroups: $ageGroups}) {\n    id\n    name\n    description\n    reportType\n    parentId\n    methodsOfUse {\n      description\n      id\n    }\n    procedures {\n      description\n      id\n    }\n    easeOfUseRatings {\n      description\n      id\n    }\n    ageGroups {\n      groupNumber\n      beginAge\n      endAge\n      groupPercentage\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7c48551e2bd61b57d94f85a1918294b";

export default node;
