import { useState, useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { useGetRegionsQuery } from '../../api/regions/GetRegionsQuery';
import { useCreateRegionMutation } from '../../api/regions/CreateRegionMutation';
import { useUpdateRegionMutation } from '../../api/regions/UpdateRegionMutation';
import { useDeleteRegionMutation } from '../../api/regions/DeleteRegionMutation';
import DataToolbar from '../../components/DataToolbar';
import DataTable from '../../components/DataTable';
import RegionDialog from '../../dialogs/RegionDialog';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../../dialogs/ErrorMessageDialog/ErrorMessageDialog';
const cellClassName = (params: GridCellParams) =>
  params.row.enabled ? 'enabled' : 'disabled';

const columns = [
  { field: 'name', headerName: 'Region Name', flex: 2, cellClassName },
  { field: 'enabled', headerName: 'Enabled', flex: 2, cellClassName }
];

const RegionPage = () => {
  const [showAddRegionDialog, setShowAddRegionDialog] = useState(false);
  const [showEditRegionDialog, setShowEditRegionDialog] = useState(false);
  const [filterCondition, setFilterCondition] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [regionKeys, setRegionKeys] = useState<string[]>([]);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const regions = useGetRegionsQuery({ fetchKey: queryFetchKey });
  const [createRegion, createRegionLoading] = useCreateRegionMutation();
  const [updateRegion, updateRegionLoading] = useUpdateRegionMutation();
  const [deleteRegion, deleteRegionLoading] = useDeleteRegionMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (!createRegionLoading && !updateRegionLoading && !deleteRegionLoading) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [createRegionLoading, updateRegionLoading, deleteRegionLoading]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      setRegionKeys(regions.map((region) => region.name));
    }
  }, [regions, setRegionKeys]);

  const onSearchChange = (value: string) => {
    const selectedRegion = regions.find(
      (region) => region?.name?.toLowerCase() === value.toLowerCase()
    );
    if (selectedRegion) {
      setSelectedId(selectedRegion.id || '');
      if (selectedRegion?.id) {
        setShowEditRegionDialog(true);
      }
    }
    setFilterCondition(value.toLowerCase());
  };

  /**
   * Filters regions so that
   * - region is not null
   * - the key contains the searched for value
   * - the region status matches the selected value (if one is selected)
   * @returns Region[]
   */
  const filterRegions = () => {
    return regions.filter((region) => {
      // default statusPasses to true in case no status is selected
      return (
        region !== null && region.name.toLowerCase().includes(filterCondition)
      );
    });
  };

  return (
    <>
      {showAddRegionDialog && (
        <RegionDialog
          onCancel={() => setShowAddRegionDialog(false)}
          onSubmit={(region) => {
            createRegion({
              variables: { ...region },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddRegionDialog(false);
          }}
        />
      )}
      {showEditRegionDialog && (
        <RegionDialog
          region={regions?.find((user) => user.id === selectedId)}
          onCancel={() => setShowEditRegionDialog(false)}
          onSubmit={(region) => {
            if (region.id) {
              updateRegion({
                variables: {
                  id: region.id,
                  ...region
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditRegionDialog(false);
            }
          }}
        />
      )}
      <DataToolbar
        dataType='regions'
        selectedId={selectedId}
        searchOptions={regionKeys}
        onSearchChange={onSearchChange}
        onAdd={() => {
          setShowAddRegionDialog(true);
        }}
        onEdit={() => setShowEditRegionDialog(true)}
        onDelete={() => {
          if (selectedId)
            deleteRegion({
              variables: { id: selectedId },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
        }}
        isDisable
      />
      <DataTable
        columns={columns}
        rows={filterRegions()}
        selectedId={selectedId}
        onSelectionChange={(id) => setSelectedId(id)}
      />
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Region Record'}
          errorBody={'There was an error submitting the Region record.'}
        />
      )}
    </>
  );
};

export default RegionPage;
