import { Select, styled } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';

const StyledSelect = styled(Select<string>)`
  &&& .MuiNativeSelect-select {
    padding: 0 24px 0 10px;
  }

  & .MuiNativeSelect-icon {
    right: 0;
  }
`;

const DataTableEditableSelectCell = ({
  id,
  value,
  field,
  options
}: {
  id: string;
  value: string;
  field: string;
  options: { label: string; value: string }[];
}) => {
  const apiRef = useGridApiContext();

  // If value is a single character, set to the option whose label starts with that character.
  useEffect(() => {
    if (value?.length === 1) {
      const option = options.find((option) =>
        option.label.toLocaleLowerCase().startsWith(value)
      );
      if (option) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: option.value
        });
      }
    }
  }, [value]);

  const handleChange = async (event: { target: { value: string } }) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <StyledSelect
      autoFocus
      value={value}
      onChange={handleChange}
      size='small'
      sx={{ height: 1 }}
      native
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

export default DataTableEditableSelectCell;
