import { Container, styled } from '@mui/material';

const PageContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.offWhite};
  height: 100vh;
  & {
    padding: 0;
  }
`;

export default PageContainer;
