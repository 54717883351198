import { Button, styled, Typography } from '@mui/material';

import { DataTable } from '../DataTable/DataTable';
import DataTableEditableProps from './DataTableEditableProps';

const StyledButton = styled(Button)`
  margin: 16px 0;
  align-self: flex-start;
`;

const TableHeading = styled(Typography)`
  padding: 16px 0;
`;

const StyleDataTable = styled(DataTable)`
  flex: 1;
`;

const DataTableEditable = ({
  className,
  columns,
  rows = [],
  categories,
  selectedId,
  label,
  disabled,
  onSelectionChange,
  processRow,
  addRow,
  addButtonText
}: DataTableEditableProps) => {
  return (
    <div className={className}>
      {label && <TableHeading variant='h6'>{label}</TableHeading>}
      <StyleDataTable
        columns={columns}
        rows={rows}
        isEditable={true}
        processRow={processRow}
        categories={categories}
        selectedId={selectedId}
        onSelectionChange={onSelectionChange}
        checkboxSelection={false}
        hideFooter
        compact
      />
      {!disabled && (
        <StyledButton variant={'contained'} onClick={addRow}>
          {addButtonText}
        </StyledButton>
      )}
    </div>
  );
};
const StyleDataTableEditable = styled(DataTableEditable)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
    font-size: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .MuiDataGrid-columnHeaderTitle {
      font-weight: normal;
    }
  }

  && .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    font-size: 16px;
  }
`;
export default StyleDataTableEditable;
