import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateTestObjectMutation } from './__generated__/CreateTestObjectMutation.graphql';

const createTestObjectMutation = graphql`
  mutation CreateTestObjectMutation($name: String!) {
    createTestObject(input: { name: $name }) {
      id
      name
    }
  }
`;

const useCreateTestObjectMutation = () =>
  useMutation<CreateTestObjectMutation>(createTestObjectMutation);

export { useCreateTestObjectMutation };
