import { Grid, styled } from '@mui/material';
import permissions from 'config/permissions';

import { useAuthentication } from '../../api/authentication';
import EditableTestListDataTable from '../../components/EditableTestListDataTable';
import { formatPercentage } from '../../utils/formatters';
import TestListPanelProps from './TestListPanelProps';

const StyledGridContainer = styled(Grid)`
  flex: 1;
`;

const StyledGridItem = styled(Grid)`
  height: 350px;
`;

const TestListPanel = ({
  methodsOfUse,
  procedures,
  easeOfUseRatings,
  ageGroups,
  setMethodsOfUse,
  setProcedures,
  setEaseOfUseRatings,
  setAgeGroups
}: TestListPanelProps) => {
  const { user } = useAuthentication();

  const permissionsIndex = permissions as { [key: string]: any };
  const hasUpdatePermission =
    user?.role && permissionsIndex[user?.role]['jobs']['update'];

  const methodsOfUseColumns = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 4,
      editable: hasUpdatePermission
    }
  ];

  const proceduresColumns = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 4,
      editable: hasUpdatePermission
    }
  ];

  const easeOfUseRatingColumns = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 4,
      editable: hasUpdatePermission
    }
  ];

  const ageGroupColumns = [
    {
      field: 'groupNumber',
      headerName: 'Age Groups',
      flex: 1,
      editable: hasUpdatePermission,
      type: 'number'
    },
    {
      field: 'beginAge',
      headerName: 'Begin Age',
      flex: 1,
      editable: hasUpdatePermission,
      type: 'number'
    },
    {
      field: 'endAge',
      headerName: 'End Age',
      flex: 1,
      editable: hasUpdatePermission,
      type: 'number'
    },
    {
      field: 'groupPercentage',
      headerName: 'Group Percentage',
      flex: 1,
      editable: hasUpdatePermission,
      type: 'number',
      valueFormatter: formatPercentage
    }
  ];

  return (
    <StyledGridContainer container spacing={2}>
      <StyledGridItem item xs={6}>
        <EditableTestListDataTable
          label='Methods of Use'
          columns={methodsOfUseColumns}
          rows={methodsOfUse}
          addButtonText='Add Method of Use'
          onChange={setMethodsOfUse}
          disabled={!hasUpdatePermission}
        />
      </StyledGridItem>
      <StyledGridItem item xs={6}>
        <EditableTestListDataTable
          label='Procedures'
          columns={proceduresColumns}
          rows={procedures}
          addButtonText='Add Procedure'
          onChange={setProcedures}
          disabled={!hasUpdatePermission}
        />
      </StyledGridItem>
      <StyledGridItem item xs={6}>
        <EditableTestListDataTable
          label='Ease of Use'
          columns={easeOfUseRatingColumns}
          rows={easeOfUseRatings}
          addButtonText='Add Ease of Use'
          onChange={setEaseOfUseRatings}
          disabled={!hasUpdatePermission}
        />
      </StyledGridItem>
      <StyledGridItem item xs={6}>
        <EditableTestListDataTable
          label='Age Groups'
          columns={ageGroupColumns}
          rows={ageGroups}
          addButtonText='Add Age Group'
          onChange={setAgeGroups}
          disabled={!hasUpdatePermission}
        />
      </StyledGridItem>
    </StyledGridContainer>
  );
};

export default TestListPanel;
