import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';

import {
  calculateAdultAge,
  calculateChildAge
} from '../../utils/ageCalculations';
import AgeCalculatorDialogProps from './AgeCalculatorDialogProps';

const AgeCalculatorDialog = ({ onClose }: AgeCalculatorDialogProps) => {
  const [ageType, setAgeType] = useState<string>('adult');
  const [birthDate, setBirthDate] = useState<string>();
  const [testDate, setTestDate] = useState<string>();
  const [age, setAge] = useState<string>();

  useEffect(() => {
    if (birthDate && testDate) {
      if (ageType === 'child') {
        setAge(
          `${calculateChildAge(new Date(testDate), new Date(birthDate))} months`
        );
      } else {
        setAge(
          `${calculateAdultAge(new Date(testDate), new Date(birthDate))} years`
        );
      }
    }
  }, [ageType, birthDate, testDate]);

  return (
    <Dialog maxWidth='xs' open>
      <DialogTitle>Age Calculator</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormLabel id='age-type-label'>Age Type</FormLabel>
          <RadioGroup
            aria-labelledby='age-type-label'
            value={ageType}
            onChange={(event) => setAgeType(event.target.value)}
            row
          >
            <FormControlLabel value='adult' control={<Radio />} label='Adult' />
            <FormControlLabel value='child' control={<Radio />} label='Child' />
          </RadioGroup>
        </FormControl>
        <TextField
          id='date'
          label='Birth Date'
          type='date'
          margin='dense'
          value={birthDate}
          onChange={(event) => setBirthDate(event.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
        />
        <TextField
          id='date'
          label='Test Date'
          type='date'
          margin='dense'
          value={testDate}
          onChange={(event) => setTestDate(event.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
        />
        <TextField
          id='date'
          label='Age'
          type='text'
          margin='dense'
          value={age}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          disabled
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgeCalculatorDialog;
