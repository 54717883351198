import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteCustomerCategoryMutation } from './__generated__/DeleteCustomerCategoryMutation.graphql';

const deleteCustomerCategoryMutation = graphql`
  mutation DeleteCustomerCategoryMutation($id: ID!) {
    deleteCustomerCategory(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteCustomerCategoryMutation = () =>
  useMutation<DeleteCustomerCategoryMutation>(deleteCustomerCategoryMutation);

export { useDeleteCustomerCategoryMutation };
