import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteTestObjectMutation } from './__generated__/DeleteTestObjectMutation.graphql';

const deleteTestObjectMutation = graphql`
  mutation DeleteTestObjectMutation($id: ID!) {
    deleteTestObject(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteTestObjectMutation = () =>
  useMutation<DeleteTestObjectMutation>(deleteTestObjectMutation);

export { useDeleteTestObjectMutation };
