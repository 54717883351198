/**
 * @generated SignedSource<<117435ac292e611b4b76588f254bbe12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSiteMutation$variables = {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  key: string;
  name: string;
  regionId?: string | null;
  state?: string | null;
  zip?: string | null;
};
export type CreateSiteMutation$data = {
  readonly createSite: {
    readonly address: string | null;
    readonly address2: string | null;
    readonly city: string | null;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly state: string | null;
    readonly zip: string | null;
  };
};
export type CreateSiteMutation = {
  response: CreateSiteMutation$data;
  variables: CreateSiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zip"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "address2",
            "variableName": "address2"
          },
          {
            "kind": "Variable",
            "name": "city",
            "variableName": "city"
          },
          {
            "kind": "Variable",
            "name": "key",
            "variableName": "key"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "state",
            "variableName": "state"
          },
          {
            "kind": "Variable",
            "name": "zip",
            "variableName": "zip"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      },
      {
        "kind": "Variable",
        "name": "regionId",
        "variableName": "regionId"
      }
    ],
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "createSite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zip",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSiteMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateSiteMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "8ddc573c6b34e3d8b6316807d2e44223",
    "id": null,
    "metadata": {},
    "name": "CreateSiteMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSiteMutation(\n  $key: String!\n  $name: String!\n  $address: String\n  $address2: String\n  $city: String\n  $state: String\n  $zip: String\n  $regionId: String\n) {\n  createSite(regionId: $regionId, input: {key: $key, name: $name, address: $address, address2: $address2, city: $city, state: $state, zip: $zip}) {\n    id\n    key\n    name\n    address\n    address2\n    city\n    state\n    zip\n  }\n}\n"
  }
};
})();

(node as any).hash = "93f8a09550beaee7b90ef188abb2a3a3";

export default node;
