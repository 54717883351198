import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import permissions from 'config/permissions';

import { useAuthentication } from '../../api/authentication';
import { useGetTestObjectsQuery } from '../../api/testObjects/GetTestObjectsQuery';
import AgeType from '../../models/AgeType';
import TestObject from '../../models/TestObject';
import TestSettingsPanelProps from './TestSettingsPanelProps';
import {
  defaultAdultMaxParticipantsPerProctor,
  defaultAdultMaxParticipantsPerSite,
  defaultChildMaxParticipantsPerProctor,
  defaultChildMaxParticipantsPerSite
} from '../../models/Job';

const TestSettingsPanel = ({
  register,
  testType,
  setTestType,
  testObjectId,
  setTestObjectId,
  numberOfParticipants,
  setValue
}: TestSettingsPanelProps) => {
  const { user } = useAuthentication();

  const testObjects = useGetTestObjectsQuery({ fetchKey: 0 });

  const permissionsIndex = permissions as { [key: string]: any };
  const hasUpdatePermission =
    user?.role && permissionsIndex[user?.role]['jobs']['update'];

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={3}>
        <TextField
          label='Sample Type'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          autoFocus
          {...register('sampleType')}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Submitter'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('submitter')}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <FormControl margin='dense' fullWidth>
          <InputLabel>Test Type</InputLabel>
          <Select
            label='Test Type'
            value={testType}
            onChange={(event) => {
              setTestType(event.target.value);
              if (event.target.value.toLocaleLowerCase() === 'adult') {
                setValue(
                  'maxParticipantsPerSite',
                  defaultAdultMaxParticipantsPerSite
                );
                setValue(
                  'maxParticipantsPerProctor',
                  defaultAdultMaxParticipantsPerProctor
                );
              }
              if (event.target.value.toLocaleLowerCase() === 'child') {
                setValue(
                  'maxParticipantsPerSite',
                  defaultChildMaxParticipantsPerSite
                );
                setValue(
                  'maxParticipantsPerProctor',
                  defaultChildMaxParticipantsPerProctor
                );
              }
            }}
            disabled={!hasUpdatePermission}
          >
            {Object.keys(AgeType)
              .filter((x) => isNaN(Number(x)))
              .map((ageType) => (
                <MenuItem key={ageType} value={ageType}>
                  {ageType}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Participant Type'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('participantType')}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Required Participants'
          type='number'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('requiredParticipants', { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Number of Participants'
          type='number'
          margin='dense'
          value={numberOfParticipants}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Max Participants Per Site'
          type='number'
          margin='dense'
          disabled={!hasUpdatePermission}
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>
          }}
          fullWidth
          {...register('maxParticipantsPerSite', { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Max Participants Per Proctor'
          type='number'
          margin='dense'
          disabled={!hasUpdatePermission}
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>
          }}
          fullWidth
          {...register('maxParticipantsPerProctor', { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Customer Test'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('customerTest')}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Report Tree'
          type='number'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('reportTree', { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <FormControl margin='dense' fullWidth>
          <InputLabel>Test Object</InputLabel>
          <Select
            label='Test Object'
            value={testObjectId}
            onChange={(event) => setTestObjectId(event.target.value)}
            disabled={!hasUpdatePermission}
          >
            {testObjects.map((testObject: TestObject) => (
              <MenuItem key={testObject.id} value={testObject.id}>
                {testObject.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Required Torque'
          type='number'
          margin='dense'
          disabled={!hasUpdatePermission}
          fullWidth
          {...register('requiredTorque', { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Date Changed'
          type='text'
          margin='dense'
          fullWidth
          disabled
          {...register('updatedAt')}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          label='Changed By'
          type='text'
          margin='dense'
          fullWidth
          disabled
          {...register('updatedBy')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Disclaimer'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('disclaimer')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Other Info'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('otherInfo')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Report History'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('reportHistory')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Test Description'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('testDescription')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Objectives'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('objectives')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Procedures'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('procedureNotes')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Results and Discussions'
          type='text'
          margin='dense'
          disabled={!hasUpdatePermission}
          minRows={2}
          fullWidth
          multiline
          {...register('resultsDiscussion')}
        />
      </Grid>
    </Grid>
  );
};

export default TestSettingsPanel;
