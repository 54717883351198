/**
 * @generated SignedSource<<3bc8cce31cd348c8964dfb7535e29274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCustomerMutation$variables = {
  address?: string | null;
  address2?: string | null;
  categoryId?: string | null;
  city?: string | null;
  key: string;
  name: string;
  state?: string | null;
  status?: string | null;
  zip?: string | null;
};
export type CreateCustomerMutation$data = {
  readonly createCustomer: {
    readonly address: string | null;
    readonly address2: string | null;
    readonly categoryId: string | null;
    readonly city: string | null;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly state: string | null;
    readonly status: string | null;
    readonly zip: string | null;
  };
};
export type CreateCustomerMutation = {
  response: CreateCustomerMutation$data;
  variables: CreateCustomerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zip"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "address2",
            "variableName": "address2"
          },
          {
            "kind": "Variable",
            "name": "categoryId",
            "variableName": "categoryId"
          },
          {
            "kind": "Variable",
            "name": "city",
            "variableName": "city"
          },
          {
            "kind": "Variable",
            "name": "key",
            "variableName": "key"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "state",
            "variableName": "state"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          },
          {
            "kind": "Variable",
            "name": "zip",
            "variableName": "zip"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "Customer",
    "kind": "LinkedField",
    "name": "createCustomer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categoryId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "city",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomerMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateCustomerMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "ffae158d8d2206bb81865d54bde9dfc2",
    "id": null,
    "metadata": {},
    "name": "CreateCustomerMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomerMutation(\n  $key: String!\n  $name: String!\n  $categoryId: ID\n  $address: String\n  $address2: String\n  $city: String\n  $state: String\n  $zip: String\n  $status: String\n) {\n  createCustomer(input: {key: $key, name: $name, categoryId: $categoryId, address: $address, address2: $address2, city: $city, state: $state, zip: $zip, status: $status}) {\n    id\n    key\n    name\n    categoryId\n    address\n    address2\n    city\n    state\n    zip\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1c952b83c01e34206da7bd80fa53d58";

export default node;
