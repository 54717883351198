import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  styled,
  List,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemButton,
  Typography
} from '@mui/material';

import { useCreateTestObjectMutation } from '../../api/testObjects/CreateTestObjectMutation';
import { useUpdateTestObjectMutation } from '../../api/testObjects/UpdateTestObjectMutation';
import { useDeleteTestObjectMutation } from '../../api/testObjects/DeleteTestObjectMutation';
import { useGetTestObjectsQuery } from '../../api/testObjects/GetTestObjectsQuery';
import TestObjectsDialogProps from './TestObjectsDialogProps';
import MutateTestObjectDialog from '../MutateTestObjectDialog/MutateTestObjectDialog';
import * as Sentry from '@sentry/browser';
import ErrorMessageDialog from '../ErrorMessageDialog/ErrorMessageDialog';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 500px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 88px;
`;

const CategoryList = styled(List)`
  width: 100%;
  max-height: 352px;
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

const CategoryActions = styled('div')`
  display: flex;
`;

const TestObjectsDialog = ({ onSubmit }: TestObjectsDialogProps) => {
  const [showAddCustomerCategoryDialog, setShowAddCustomerCategoryDialog] =
    useState(false);
  const [showEditCustomerCategoryDialog, setShowEditCustomerCategoryDialog] =
    useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [queryFetchKey, setQueryFetchKey] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const testObjects = useGetTestObjectsQuery({ fetchKey: queryFetchKey });
  const [createTestObject, createTestObjectLoading] =
    useCreateTestObjectMutation();
  const [updateTestObject, updateTestObjectLoading] =
    useUpdateTestObjectMutation();
  const [deleteTestObject, deleteTestObjectLoading] =
    useDeleteTestObjectMutation();

  // Invalidate fetch keys after mutation loads.
  useEffect(() => {
    if (
      !createTestObjectLoading &&
      !updateTestObjectLoading &&
      !deleteTestObjectLoading
    ) {
      setQueryFetchKey(queryFetchKey + 1);
    }
  }, [
    createTestObjectLoading,
    updateTestObjectLoading,
    deleteTestObjectLoading
  ]);

  return (
    <StyledDialog maxWidth='xl' open>
      {showAddCustomerCategoryDialog && (
        <MutateTestObjectDialog
          onCancel={() => setShowAddCustomerCategoryDialog(false)}
          onSubmit={(testObject) => {
            createTestObject({
              variables: { ...testObject },
              onCompleted: (response, error) => {
                if (error && error.length > 0) {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              },
              onError: (error) => {
                Sentry.captureException(error);
                setShowErrorDialog(true);
              }
            });
            setShowAddCustomerCategoryDialog(false);
          }}
        />
      )}
      {showEditCustomerCategoryDialog && selectedIndex != null && (
        <MutateTestObjectDialog
          testObject={testObjects[selectedIndex]}
          onCancel={() => setShowEditCustomerCategoryDialog(false)}
          onSubmit={(testObject) => {
            if (testObject.id) {
              updateTestObject({
                variables: {
                  ...testObject,
                  id: testObject.id
                },
                onCompleted: (response, error) => {
                  if (error && error.length > 0) {
                    Sentry.captureException(error);
                    setShowErrorDialog(true);
                  }
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setShowErrorDialog(true);
                }
              });
              setShowEditCustomerCategoryDialog(false);
            }
          }}
        />
      )}
      <DialogTitle>Test Objects</DialogTitle>
      <StyledDialogContent>
        {testObjects.length === 0 && <Typography>No rows</Typography>}
        {testObjects.length > 0 && (
          <CategoryList>
            {testObjects.map((testObject, index) => (
              <ListItemButton
                key={testObject.name}
                onClick={() => {
                  if (index === selectedIndex) setSelectedIndex(null);
                  else setSelectedIndex(index);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={index === selectedIndex}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': testObject.name }}
                  />
                </ListItemIcon>
                <ListItemText id={testObject.name} primary={testObject.name} />
              </ListItemButton>
            ))}
          </CategoryList>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <CategoryActions>
          <Button onClick={() => setShowAddCustomerCategoryDialog(true)}>
            Add
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex !== null)
                setShowEditCustomerCategoryDialog(true);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              if (selectedIndex !== null) {
                const id = testObjects[selectedIndex].id;
                if (id) {
                  deleteTestObject({
                    variables: { id },
                    onCompleted: (response, error) => {
                      if (error && error.length > 0) {
                        Sentry.captureException(error);
                      }
                    }
                  });
                }
              }
            }}
          >
            Delete
          </Button>
        </CategoryActions>
        <Button onClick={onSubmit}>Ok</Button>
      </StyledDialogActions>
      {showErrorDialog && (
        <ErrorMessageDialog
          hideDialog={() => {
            setShowErrorDialog(false);
          }}
          errorTitle={'Error Submitting Test Object'}
          errorBody={'There was an error submitting the Test Object record.'}
        />
      )}
    </StyledDialog>
  );
};

export default TestObjectsDialog;
