import {
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro';
import { styled, Button, ButtonProps } from '@mui/material';
import { format } from 'date-fns';
import { startTransition, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { v4 } from 'uuid';
import permissions from 'config/permissions';

import { useAuthentication } from '../../../api/authentication';
import { useGetJobParticipantsQuery } from '../../../api/jobs/GetJobParticipantsQuery';
import { useCreateParticipantMutation } from '../../../api/participants/CreateParticipantMutation';
import { useUpdateParticipantMutation } from '../../../api/participants/UpdateParticipantMutation';
import { useDeleteParticipantMutation } from '../../../api/participants/DeleteParticipantMutation';

import DataTable from '../../../components/DataTable';
import DataTableEditableSelectCell from '../../../components/DataTableEditableSelectCell';
import { withPermission } from '../../../components/Permissions';
import Job from '../../../models/Job';
import Participant from '../../../models/Participant';
import {
  calculateAdultAge,
  calculateChildAge
} from '../../../utils/ageCalculations';
import Site from '../../../models/Site';
import ValidatorsDialog from '../ValidatorsDialog';
import * as Sentry from '@sentry/browser';
import LinkedGridEditDateCell from '../../../components/LinkedGridEditDateCell';
import AgeCalculatorDialog from '../../AgeCalcuatorDialog';
import DataTableEditableCrud from '../../../components/DataTableEditableCrud';
import Toaster, { Toast } from '../../../components/Toaster';
import useErrorFlags from '../../../hooks/useErrorFlags';
import DataTableEditableAutocompleteCell from '../../../components/DataTableEditableAutocompleteCell';
import {
  ageGroupIsValid,
  sampleIdsAreUnique,
  birthDateBeforeTestDate,
  propertyDefined
} from './validators';
import { DeleteConfirmationDialog } from '../../AlertDialog';
import Proctor from '../../../models/Proctor';

const ButtonWithPermission = withPermission<ButtonProps>(Button);

const StyledDataTable = styled(DataTable)`
  margin: 0;
  height: 100%;
`;

const calculateAge = (
  testType: string,
  birth: string | number | Date,
  test: string | number | Date
) => {
  const testDate = new Date(test);
  const birthDate = new Date(birth);
  return testType.toLowerCase() === 'adult'
    ? calculateAdultAge(testDate, birthDate)
    : calculateChildAge(testDate, birthDate);
};

const ParticipantsPanel = ({
  job,
  sites,
  proctors,
  refreshParticipants,
  queryOptions
}: {
  job?: Job;
  sites: readonly Site[];
  proctors: readonly Proctor[];
  refreshParticipants: () => void;
  queryOptions: any;
}) => {
  let participants: Participant | null[] | null = [];
  const [showAgeCalculatorDialog, setShowAgeCalculatorDialog] = useState(false);
  const [showValidators, setShowValidators] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const [participantIdToDelete, setParticipantIdToDelete] = useState<
    string | null
  >(null);
  const { errorFlags, dispatchErrorFlag } = useErrorFlags();

  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>({
    sampleId: 100,
    custSampleNum: 100,
    sex: 50,
    groupNum: 100,
    testDateTime: 100,
    birthDate: 100,
    age: 50,
    mou: 100,
    eou: 100,
    siteId: 100,
    proctorId: 100
  });

  // Populate column widths of dynamic procedure columns
  useEffect(() => {
    if (job?.procedures) {
      job.procedures.forEach(
        (procedure) => (columnWidths[procedure.description] = 100)
      );
      setColumnWidths({ ...columnWidths });
    }
  }, [job]);

  const onColumnResize = (field: string, width: number) => {
    columnWidths[field] = width;
    setColumnWidths({ ...columnWidths });
  };

  //TODO: is startTransition needed
  startTransition(() => {
    //TODO: check if condition is needed or will throw errors
    if (job && job.id) {
      participants = useGetJobParticipantsQuery(
        job?.id,
        queryOptions
      ).participants;
    }
  });

  let filteredSites = [...sites];
  const navigate = useNavigate();
  // groundwork for inline site addition.
  // const [showAddSiteDialog, setShowAddSiteDialog] = useState(false);
  // const [createSite, createSiteLoading] = useCreateSiteMutation();
  const jobRegions = job?.regions?.map((region) => region?.id);
  if (job && job.regions && job.regions.length > 0) {
    filteredSites = sites.filter((site) => {
      return jobRegions?.includes(site.region?.id);
    });
  }
  // implement participant manipulation here (ie splitting json into objects for rendering in columns, date parsing etc...)
  const prepareParticipantFunc = (part: any) => {
    const editablePart: Participant = { ...part };

    if (editablePart.testDateTime) {
      const testDateObj = new Date(parseInt(editablePart.testDateTime));
      editablePart.testDateTime = format(testDateObj, 'MM/dd/yyyy HH:mm:ss');
    }
    if (editablePart.birthDate) {
      const birthDateObj = new Date(parseInt(editablePart.birthDate));
      editablePart.birthDate = format(birthDateObj, 'MM/dd/yyyy');
    }
    if (editablePart.participantData) {
      const participantData = JSON.parse(editablePart.participantData);
      editablePart.mou = participantData?.methodsOfUse;
      editablePart.eou = participantData?.easeOfUse;
      if (participantData.procedures) {
        editablePart.procedures = participantData.procedures;
        Object.entries(participantData.procedures).forEach((proc) => {
          editablePart[proc[0]] = proc[1];
        });
      }
    }
    return editablePart;
  };

  const { user } = useAuthentication();
  const [participantData, setParticipantData] = useState<any>([]);
  const [newParticipantData, setNewParticipantData] = useState<any>([]);

  useEffect(() => {
    const preppedParticipants = job?.participants?.map((part) => {
      return prepareParticipantFunc({
        ...part,
        id: v4(),
        databaseId: part?.id
      });
    });
    setParticipantData(preppedParticipants);
  }, []);

  const onAddNewRow = () => {
    setNewParticipantData([
      ...newParticipantData,
      { ...stubParticipant, id: v4(), isNew: true }
    ]);
  };

  const updateRecord = (record: any) => {
    let setCallback;
    let data = [];
    if (record.isNew) {
      setCallback = setNewParticipantData;
      data = newParticipantData;
    } else {
      setCallback = setParticipantData;
      data = participantData;
    }

    setCallback(
      data.map((participantDatum: any) => {
        if (participantDatum.id === record.id) {
          return {
            ...record,
            age:
              job?.testType && record.birthDate && record.testDateTime
                ? calculateAge(
                    job.testType,
                    record.birthDate,
                    record.testDateTime
                  )
                : ''
          };
        }
        return participantDatum;
      })
    );
  };

  // Always have a 'new' row at the bottom of the grid
  useEffect(() => {
    // Don't automatically add new row if user does not have create permissions
    const permissionsIndex = permissions as { [key: string]: any };
    const hasCreatePermission =
      user?.role && permissionsIndex[user.role]['participants']['create'];
    if (
      job?.status === 'In Process' &&
      newParticipantData.length === 0 &&
      hasCreatePermission
    ) {
      onAddNewRow();
    }
  }, [job, user, newParticipantData, onAddNewRow]);

  const [createParticipant] = useCreateParticipantMutation();
  const [updateParticipant] = useUpdateParticipantMutation();
  const [deleteParticipant] = useDeleteParticipantMutation();

  const siteOptions = filteredSites
    .map((site, _) => {
      return {
        label: site.key,
        value: site.id
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const proctorOptions = proctors
    .map((proctor, _) => {
      return {
        label: proctor.key,
        value: proctor.id
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const methodOfUseOptions = [
    { label: '', value: '' },
    ...(job?.methodsOfUse.map((mou) => ({
      label: mou.description,
      value: mou.description
    })) ?? [])
  ];

  const easeOfUseOptions = [
    { label: '', value: '' },
    ...(job?.easeOfUseRatings.map((eou) => ({
      label: eou.description,
      value: eou.description
    })) ?? [])
  ];

  const sexOptions = [
    { label: '', value: '' },
    { label: 'M', value: 'M' },
    { label: 'F', value: 'F' }
  ];

  const ageGroupOptions = job?.ageGroups.map((group) => ({
    label: String(group.groupNumber),
    value: String(group.groupNumber)
  }));

  const renderSiteCell = (params: any) => {
    params.value = {
      label: getSiteNameById(params.value),
      value: params.value
    };
    return (
      <DataTableEditableAutocompleteCell options={siteOptions} {...params} />
    );
  };
  const renderProctorCell = (params: any) => {
    params.value = {
      label: getProctorKeyById(params.value),
      value: params.value
    };
    return (
      <DataTableEditableAutocompleteCell options={proctorOptions} {...params} />
    );
  };
  const renderMouCell = (params: any) => {
    return (
      <DataTableEditableSelectCell options={methodOfUseOptions} {...params} />
    );
  };
  const renderEouCell = (params: any) => {
    return (
      <DataTableEditableSelectCell options={easeOfUseOptions} {...params} />
    );
  };
  const renderSexCell = (params: any) => {
    return <DataTableEditableSelectCell options={sexOptions} {...params} />;
  };
  const renderAgeGroupCell = (params: any) => {
    return (
      <DataTableEditableSelectCell options={ageGroupOptions} {...params} />
    );
  };

  type procColumn = {
    field: string;
    headerName: string;
    editable: boolean;
  };

  let procedureColumns: procColumn[] = [];
  if (job?.procedures && job?.procedures?.length > 0) {
    procedureColumns = job.procedures.map((proc) => {
      return {
        field: `${proc.description}`,
        headerName: `${proc.description}`,
        editable: true
      };
    });
  }

  const cellClassName = (params: GridCellParams) => {
    return errorFlags.some((error) => {
      return (
        error.rowId === (params.id as string) && error.field === params.field
      );
    })
      ? 'invalid'
      : 'valid';
  };

  const participantsColumns: GridColumns = [
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      width: columnWidths['sampleId'],
      editable: true,
      cellClassName,
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'sampleId',
            message: 'Sample ID is required.'
          }
        });

        // Check if value is unique
        dispatchErrorFlag({
          type: !sampleIdsAreUnique(
            participantData,
            params.row,
            params.props.value
          )
            ? 'add'
            : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'sampleId',
            message: 'Sample ID must be unique.'
          }
        });
        return { ...params.props };
      }
    },
    {
      field: 'custSampleNum',
      headerName: 'Cust Sample #',
      width: columnWidths['custSampleNum'],
      editable: true
    },
    {
      field: 'sex',
      headerName: 'Sex',
      width: columnWidths['sex'],
      editable: true,
      cellClassName,
      renderEditCell: renderSexCell,
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'sex',
            message: 'Sex is required.'
          }
        });
        return { ...params.props };
      }
    },
    {
      field: 'groupNum',
      headerName: 'Group #',
      width: columnWidths['groupNum'],
      editable: true,
      cellClassName,
      renderEditCell: renderAgeGroupCell,
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'groupNum',
            message: 'Age group is required.'
          }
        });

        const groupNumber = parseInt(params.props.value);
        if (job && !isNaN(groupNumber)) {
          // Check if participant age is outside of age group range
          dispatchErrorFlag({
            type: !ageGroupIsValid(job, params.row.age, groupNumber)
              ? 'add'
              : 'remove',
            flag: {
              rowId: params.id as string,
              field: 'groupNum',
              message: `Age must be between Beginning and End age of group.`
            }
          });
        }
        return { ...params.props };
      }
    },
    {
      field: 'testDateTime',
      headerName: 'Test Date',
      width: columnWidths['testDateTime'],
      editable: true,
      cellClassName,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (Date.parse(params?.value)) {
          const date = new Date(params?.value);
          return format(date, 'MM/dd/yyyy');
        } else return params.value;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <LinkedGridEditDateCell
            linkedField='age'
            linkedFieldSetter={(row: any, newFieldValue: any) =>
              job?.testType && newFieldValue && row.birthDate
                ? calculateAge(job?.testType, row.birthDate, newFieldValue)
                : ''
            }
            centuryPrefix={'20'}
            {...params}
          />
        </>
      ),
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'testDateTime',
            message: 'Test date is required.'
          }
        });
        // Check if test date is before birth date
        dispatchErrorFlag({
          type: !birthDateBeforeTestDate(
            params.row.birthDate,
            params.props.value
          )
            ? 'add'
            : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'testDateTime',
            message: 'Test date must be after birth date.'
          }
        });
        // Re-check age group validity
        const groupNumber = parseInt(params.row.groupNum);
        if (job && job.testType && !isNaN(groupNumber)) {
          const age = calculateAge(
            job.testType,
            params.row.birthDate,
            params.props.value
          );
          dispatchErrorFlag({
            type: !ageGroupIsValid(job, age, groupNumber) ? 'add' : 'remove',
            flag: {
              rowId: params.id as string,
              field: 'groupNum',
              message: `Age must be between Beginning and End age of group.`
            }
          });
        }
        return { ...params.props };
      }
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      width: columnWidths['birthDate'],
      editable: true,
      cellClassName,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (Date.parse(params?.value)) {
          const date = new Date(params?.value);
          return format(date, 'MM/dd/yyyy');
        } else return params.value;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          <LinkedGridEditDateCell
            linkedField='age'
            linkedFieldSetter={(row: any, newFieldValue: any) =>
              job?.testType && newFieldValue && row.testDateTime
                ? calculateAge(job?.testType, newFieldValue, row.testDateTime)
                : ''
            }
            centuryPrefix={
              job?.testType?.toLowerCase() === 'child' ? '20' : '19'
            }
            {...params}
          />
        </>
      ),
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'birthDate',
            message: 'Birth date is required.'
          }
        });
        // Check if birth date is after test date
        dispatchErrorFlag({
          type: !birthDateBeforeTestDate(
            params.props.value,
            params.row.testDateTime
          )
            ? 'add'
            : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'testDateTime',
            message: 'Test date must be after birth date.'
          }
        });
        // Re-check age group validity
        const groupNumber = parseInt(params.row.groupNum);
        if (job && job.testType && !isNaN(groupNumber)) {
          const age = calculateAge(
            job.testType,
            params.props.value,
            params.row.testDateTime
          );
          dispatchErrorFlag({
            type: !ageGroupIsValid(job, age, groupNumber) ? 'add' : 'remove',
            flag: {
              rowId: params.id as string,
              field: 'groupNum',
              message: `Age must be between Beginning and End age of group.`
            }
          });
        }
        return { ...params.props };
      }
    },
    {
      field: 'age',
      headerName: 'Age',
      width: columnWidths['age'],
      editable: false
    }
  ];

  // Hide methods of use column if there are not methods of use options defined.
  if (job?.methodsOfUse?.length) {
    participantsColumns.push({
      field: 'mou',
      headerName: 'Method Of Use',
      width: columnWidths['mou'],
      editable: true,
      renderEditCell: renderMouCell
    });
  }

  // Hide ease of use column if there are not methods of use options defined.
  if (job?.easeOfUseRatings?.length) {
    participantsColumns.push({
      field: 'eou',
      headerName: 'Ease Of Use',
      width: columnWidths['eou'],
      editable: true,
      renderEditCell: renderEouCell
    });
  }

  participantsColumns.push(
    {
      field: 'siteId',
      headerName: 'Site',
      width: columnWidths['siteId'],
      editable: true,
      renderEditCell: renderSiteCell,
      renderCell: (params: GridRenderCellParams) => {
        return <strong>{getSiteNameById(params.value)}</strong>;
      },
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'siteId',
            message: 'Site is required.'
          }
        });
        return { ...params.props };
      }
    },
    {
      field: 'proctorId',
      headerName: 'Proctor',
      width: columnWidths['proctorId'],
      editable: true,
      renderEditCell: renderProctorCell,
      renderCell: (params: GridRenderCellParams) => {
        return <strong>{getProctorKeyById(params.value)}</strong>;
      },
      preProcessEditCellProps: (params) => {
        // Check if value is defined
        dispatchErrorFlag({
          type: !params.props.value ? 'add' : 'remove',
          flag: {
            rowId: params.id as string,
            field: 'proctorId',
            message: 'Proctor is required.'
          }
        });
        return { ...params.props };
      }
    }
  );

  participantsColumns.push(...procedureColumns);

  // example datatableeditable with select field: https://codesandbox.io/s/prumpy?file=/demo.js
  const processRow =
    <T extends { id?: string }>(
      currentState: readonly T[],
      setter: (newState: T[]) => void
    ) =>
    (newRow: any) => {
      const editableNewRow = { ...newRow };
      editableNewRow.isDirty = true;
      const newState = [...currentState];
      const index = newState.map((row) => row.id).indexOf(newRow.id);
      newState[index] = editableNewRow as T;
      setter(newState);
      return newRow;
    };

  const getSiteNameById = (id: string) => {
    const selectedSite = sites.filter((site) => {
      return site.id === id;
    });
    return selectedSite.length > 0 ? selectedSite[0].key : '';
  };

  const getProctorKeyById = (id: string) => {
    const selectedProctor = proctors.find((proctor) => proctor.id === id);
    return selectedProctor?.key ?? '';
  };

  const prepareParticipant = (participant: any) => {
    const proceduresObj: { [key: string]: string } = {};
    procedureColumns.forEach((proc: procColumn) => {
      proceduresObj[proc.field] = participant[proc.field];
    });

    const participantData = {
      procedures: proceduresObj,
      methodsOfUse: participant.mou,
      easeOfUse: participant.eou
    };

    const testDate = new Date(participant.testDateTime);
    const birthDate = new Date(participant.birthDate);

    let age = null;
    // TODO: Warn or prevent user from saving a participant record without setting a test type.
    if (job?.testType) {
      age = calculateAge(job.testType, birthDate, testDate);
    }

    const preparedParticipant = {
      jobId: participant.jobId,
      createdById: participant.createdById,
      isNew: participant.isNew,
      siteId: participant.siteId,
      id: participant.databaseId,
      sampleId: participant.sampleId,
      custSampleNum: participant.custSampleNum,
      sex: participant.sex,
      groupNum: parseInt(participant.groupNum),
      testDateTime: testDate.toISOString(),
      birthDate: birthDate.toISOString(),
      participantData: JSON.stringify(participantData),
      zip: participant.zip,
      proctorId: participant.proctorId,
      age
    };

    return preparedParticipant;
  };

  const submitRecord = (participant: any) => {
    if (participant.isNew) {
      const preparedPart = prepareParticipant(participant);
      createParticipant({
        variables: { ...preparedPart },
        onCompleted: (response, error) => {
          const preparedNewPart = prepareParticipantFunc({
            ...response.createParticipant,
            id: participant.id,
            databaseId: response.createParticipant.id
          });
          const editableParticipantData = [...participantData];
          editableParticipantData.push(preparedNewPart);
          setParticipantData(editableParticipantData);

          setNewParticipantData(
            newParticipantData.filter((p: any) => p.id !== participant.id)
          );

          if (error && error.length > 0) {
            Sentry.captureException(error);
            // setErrorMessages(
            //   'There was an error submitting the participant record.'
            // );
          }
        },
        onError: (error) => {
          Sentry.captureException(error);
          // setErrorMessage(
          //   'There was an error submitting the participant record.'
          // );
        }
      });
    } else {
      const preparedPart = prepareParticipant(participant);
      updateParticipant({
        variables: { ...preparedPart },
        onCompleted: (response, error) => {
          if (error && error.length > 0) {
            Sentry.captureException(error);
            // setErrorMessage(
            //   'There was an error submitting the participant record.'
            // );
            return;
          }

          const editableParticipantData = participantData.map(
            (participant: any) => {
              if (participant.databaseId === response.updateParticipant.id) {
                const result = prepareParticipantFunc({
                  ...response.updateParticipant,
                  id: participant.id,
                  databaseId: response.updateParticipant.id
                });
                return result;
              }
              return participant;
            }
          );
          setParticipantData(editableParticipantData);
        },
        onError: (error) => {
          Sentry.captureException(error);
          // setErrorMessage(
          //   'There was an error submitting the participant record.'
          // );
        }
      });
    }
  };

  if (job?.status !== 'In Process') {
    return (
      <>
        <StyledDataTable
          columns={participantsColumns}
          rows={participantData}
          processRow={processRow<any>(participantData, setParticipantData)}
          checkboxSelection={false}
        />
      </>
    );
  }

  const stubParticipant = {
    jobId: `${job?.id}`,
    createdById: user?.id ?? '',
    isNew: true,
    groupNum: job.ageGroups[0].groupNumber ?? undefined,
    siteId: '', // this is defined by site selector field
    proctorId: '',
    testDateTime: new Date()
  };

  const validateRow = (participant: any) => {
    if (!propertyDefined(participant, 'sampleId')) return false;
    if (!propertyDefined(participant, 'sex')) return false;
    if (!propertyDefined(participant, 'groupNum')) return false;
    if (!propertyDefined(participant, 'testDateTime')) return false;
    if (!propertyDefined(participant, 'birthDate')) return false;
    if (!propertyDefined(participant, 'siteId')) return false;
    if (!propertyDefined(participant, 'proctorId')) return false;
    if (!propertyDefined(participant, 'sampleId')) return false;
    if (!propertyDefined(participant, 'sampleId')) return false;
    if (!sampleIdsAreUnique(participantData, participant, participant.sampleId))
      return false;

    const groupNumber = parseInt(participant.groupNum);
    if (
      isNaN(groupNumber) ||
      !ageGroupIsValid(job, participant.age, groupNumber)
    )
      return false;
    if (
      !birthDateBeforeTestDate(participant.birthDate, participant.testDateTime)
    )
      return false;
    return true;
  };

  const deleteRow = async (id: string) => {
    const participant = participantData.find((p: any) => p.id === id);

    if (!participant) {
      setNewParticipantData(newParticipantData.filter((p: any) => p.id !== id));
      return true;
    }

    await deleteParticipant({
      variables: { id: participant.databaseId },
      onError: (error) => {
        Sentry.captureException(error);
        // setErrorMessage('There was an error deleting the participant record.');
      },
      onCompleted: (_response) => {
        const editableParticipantData = participantData.filter(
          (participant: any) => {
            return participant.id !== id;
          }
        );
        setParticipantData(editableParticipantData);
      }
    });
    return true;
  };

  return (
    <>
      {showAgeCalculatorDialog && (
        <AgeCalculatorDialog
          onClose={() => setShowAgeCalculatorDialog(false)}
        />
      )}
      {showValidators && (
        <ValidatorsDialog
          hideDialog={() => {
            setShowValidators(false);
          }}
          job={job}
          participants={participants}
          sites={sites}
        />
      )}
      {showDeleteConfirmationDialog && (
        <DeleteConfirmationDialog
          objectName='this participant'
          onCancel={() => {
            setShowDeleteConfirmationDialog(false);
            setParticipantIdToDelete(null);
          }}
          onDelete={async () => {
            if (participantIdToDelete) {
              await deleteRow(participantIdToDelete);
              setShowDeleteConfirmationDialog(false);
              setParticipantIdToDelete(null);
            }
          }}
        />
      )}
      {participants && (
        <DataTableEditableCrud
          errorFlags={errorFlags}
          recordType='participants'
          columns={participantsColumns}
          rows={[...participantData, ...newParticipantData]}
          onUpdate={updateRecord}
          onSubmit={submitRecord}
          onAdd={onAddNewRow}
          onValidate={validateRow}
          onDelete={(id: string) => {
            setShowDeleteConfirmationDialog(true);
            setParticipantIdToDelete(id);
          }}
          $onColumnResize={onColumnResize}
          toolbarButtons={
            <>
              <Button
                onClick={() => setShowAgeCalculatorDialog(true)}
                variant='outlined'
              >
                Age Calculator
              </Button>
              <Button
                onClick={() => {
                  refreshParticipants();
                  setShowValidators(true);
                }}
                variant='outlined'
              >
                Check Percentages
              </Button>
              {user && (
                <ButtonWithPermission
                  role={user.role}
                  type='sites'
                  operation='create'
                  componentProps={{
                    onClick: () => navigate('/sites'),
                    variant: 'outlined'
                  }}
                >
                  Add Site
                </ButtonWithPermission>
              )}
            </>
          }
        />
      )}
      <Toaster>
        {_.uniqBy(errorFlags, (flag) => flag.message)
          .filter((flag) => !flag.hidden)
          .map((flag, index) => {
            return (
              <Toast
                key={`${index}`}
                label={flag.message}
                onClose={() => dispatchErrorFlag({ type: 'hide', flag })}
              />
            );
          })}
      </Toaster>
    </>
  );
};

export default ParticipantsPanel;
