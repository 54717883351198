import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateProctorMutation } from './__generated__/UpdateProctorMutation.graphql';

const updateProctorMutation = graphql`
  mutation UpdateProctorMutation(
    $id: ID!
    $key: String!
    $firstName: String
    $lastName: String
    $state: String
    $regionIds: [ID]
  ) {
    updateProctor(
      input: {
        id: $id
        key: $key
        firstName: $firstName
        lastName: $lastName
        state: $state
        regionIds: $regionIds
      }
    ) {
      id
      key
      firstName
      lastName
      state
      regions {
        id
        name
      }
    }
  }
`;

const useUpdateProctorMutation = () =>
  useMutation<UpdateProctorMutation>(updateProctorMutation);

export { useUpdateProctorMutation };
