import { Button as MuiButton, styled, ButtonProps } from '@mui/material';

const StyledButton = styled(MuiButton)`
  border-radius: 0;
  font-size: 17px;
  gap: 8px;
  white-space: nowrap;
`;

const Button = ({ children, ...props }: ButtonProps) => (
  <StyledButton color='secondary' variant='contained' {...props}>
    {children}
  </StyledButton>
);

export default Button;
