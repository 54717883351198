import { GridValidRowModel, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Delete as DeleteIcon } from '@mui/icons-material';

import EditableTestListDataTableProps from './EditableTestListDataTableProps';
import DataTableEditable from '../DataTableEditable';

const processRow =
  <T extends { id?: string }>(
    currentState: readonly T[],
    onChange: (newState: T[]) => void
  ) =>
  (newRow: GridValidRowModel) => {
    const newState = [...currentState];
    const index = newState.map((row) => row.id).indexOf(newRow.id);
    newState[index] = newRow as T;
    onChange(newState);
    return newRow;
  };

const addRow =
  <T extends { id?: string }>(
    currentState: readonly T[],
    onChange: (newState: T[]) => void
  ) =>
  () => {
    const row = {} as T;
    const newState = [...currentState];
    row.id = String(currentState.length);
    newState.push(row);
    onChange(newState);
  };

const deleteRow = <T extends { id?: string }>(
  id: string,
  currentState: readonly T[],
  onChange: (newState: T[]) => void
) => {
  const newState = [...currentState];
  const indexRemoved = newState.map((row) => row.id).indexOf(id);
  newState.splice(indexRemoved, 1);
  onChange(newState);
};

const EditableTestListDataTable = <T extends { id?: string }>({
  label,
  addButtonText,
  columns,
  rows,
  disabled,
  onChange
}: EditableTestListDataTableProps<T>) => {
  const columnsWithActions: any[] = [...columns];

  if (!disabled)
    columnsWithActions.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      flex: 1,
      getActions: ({ id }: { id: string }) => {
        return [
          <>
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Delete'
              onClick={() => deleteRow(id, rows, onChange)}
              color='inherit'
            />
          </>
        ];
      }
    });

  return (
    <DataTableEditable
      label={label}
      columns={columnsWithActions}
      rows={rows}
      isEditable={true}
      processRow={processRow<T>(rows, onChange)}
      addButtonText={addButtonText}
      addRow={addRow<T>(rows, onChange)}
      disabled={disabled}
    />
  );
};

export default EditableTestListDataTable;
