import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
  Button,
  Divider,
  Box
} from '@mui/material';

import Job from '../../models/Job';
import Participant from '../../models/Participant';
import AgeGroup from '../../models/AgeGroup';
import Site from '../../models/Site';

const ValidatorsDialog = ({
  hideDialog,
  job,
  participants,
  sites
}: {
  hideDialog: () => void;
  job: Job;
  participants: Participant | null[];
  sites: readonly Site[];
}) => {
  type GroupStats = {
    totalParticipantsInGroup: number;
    percentAllParticipants: number;
    desiredPercentAllParticipants: number;
    resultAllParticipants: number;
    ageMin: number;
    ageMax: number;
  };

  type GroupToValidate = {
    [groupNum: number]: GroupStats;
  };

  type GroupResult = {
    [groupNum: number]: {
      resultAllParticipants: number;
    };
  };

  type Proctors = {
    [proctor: string]: number;
  };

  const validateProctors = () => {
    const proctorList: Proctors = {};
    const proctorResult: string[] = [];
    participants.forEach((participant: Participant) => {
      // proctor tally
      if (participant.proctor) {
        if (participant.proctor in proctorList) {
          proctorList[participant.proctor]++;
        } else {
          proctorList[participant.proctor] = 1;
        }
      }
    });

    Object.entries(proctorList).forEach(([key, value]) => {
      if (job?.maxParticipantsPerProctor) {
        if (value / participants.length > job.maxParticipantsPerProctor / 100) {
          proctorResult.push(key);
        }
      }
    });

    return proctorResult;
  };

  const validateSites = () => {
    const siteList: { [proctor: string]: number } = {};
    const siteResult: string[] = [];
    participants.forEach((participant: Participant) => {
      // proctor tally
      if (participant.siteId) {
        if (participant.siteId in siteList) {
          siteList[participant.siteId]++;
        } else {
          siteList[participant.siteId] = 1;
        }
      }
    });

    Object.entries(siteList).forEach(([key, value]) => {
      if (job?.maxParticipantsPerSite) {
        if (value / participants.length > job.maxParticipantsPerSite / 100) {
          siteResult.push(key);
        }
      }
    });

    return siteResult;
  };

  const validateTotalGenderDistribution = () => {
    const numberOfMales = participants.filter((participant: any) => {
      return participant.sex.toLowerCase() === 'm';
    }).length;

    // Children target is always 50% and adult target is always 30%.
    const target = job.testType?.toLowerCase() === 'adult' ? 0.3 : 0.5;

    const percentMale = numberOfMales / participants.length;
    const diff = percentMale - target;
    const result = diff === 0;

    return { result, diff, percentMale, target };
  };

  const validateAgeGroupGenderDifference = () => {
    const ageGroupStats: {
      groupNumber: number;
      males: number;
      females: number;
      diff: number;
      valid: boolean;
    }[] = [];
    participants.forEach((participant: Participant) => {
      // Exit early if group number or sex is not defined.
      if (!participant.groupNum || !participant.sex) return;

      // Create stats if it does not exist.
      if (!ageGroupStats[participant.groupNum]) {
        ageGroupStats[participant.groupNum] = {
          groupNumber: participant.groupNum,
          males: 0,
          females: 0,
          diff: 0,
          valid: false
        };
      }
      if (participant.sex.toLowerCase() === 'm') {
        ageGroupStats[participant.groupNum].males++;
      } else {
        ageGroupStats[participant.groupNum].females++;
      }
    });

    // Calculate difference.
    ageGroupStats.forEach((ageGroup) => {
      ageGroup.diff =
        Math.abs(ageGroup.males - ageGroup.females) /
        (ageGroup.males + ageGroup.females);
      ageGroup.valid = ageGroup.diff < 0.1;
    });

    // Difference in gender for each age group is < 10% for child tests.
    const result =
      job.testType?.toLowerCase() === 'child'
        ? ageGroupStats.every((ageGroup) => ageGroup.valid)
        : true;

    return { result, ageGroupStats };
  };

  const validateParticipantsData = () => {
    const groupsToValidate: GroupToValidate = {};
    const participantsInWrongGroup: Participant[] = [];
    const groupResults: GroupResult = {};

    job.ageGroups.forEach((group: AgeGroup) => {
      // reference object to store in-progress calculations
      if (group.groupNumber) {
        groupsToValidate[group.groupNumber] = {
          totalParticipantsInGroup: 0,
          percentAllParticipants: 0,
          desiredPercentAllParticipants: group.groupPercentage / 100,
          resultAllParticipants: 0,
          ageMin: group.beginAge,
          ageMax: group.endAge
        };
      }
    });

    if (participants) {
      participants.forEach((participant: Participant) => {
        if (participant.groupNum && participant.sex) {
          // get selected group for participant
          const currentGroup = groupsToValidate[participant.groupNum];

          // get target percentages for current group
          // const desiredMalePercentage = currentGroup.desiredMalePercentage;
          const desiredPercentAllParticipants =
            currentGroup.desiredPercentAllParticipants;

          // increment running tally for total participants in group
          currentGroup.totalParticipantsInGroup++;

          // calculate running percentage total group participants vs job desired number fo participants.
          const percentAllParticipants =
            currentGroup.totalParticipantsInGroup / participants.length;
          currentGroup.percentAllParticipants = percentAllParticipants;

          // set running result fields (-1 = needs more, 1 = too many, 0 = passes validation)

          // number in group vs total all participants
          if (percentAllParticipants < desiredPercentAllParticipants) {
            currentGroup.resultAllParticipants = -1;
          } else if (percentAllParticipants > desiredPercentAllParticipants) {
            currentGroup.resultAllParticipants = 1;
          } else {
            currentGroup.resultAllParticipants = 0;
          }
          // persist running tallies for next iteration
          groupsToValidate[participant.groupNum] = currentGroup;
          // prepare result object
          groupResults[participant.groupNum] = {
            // resultMale: currentGroup.resultMale,
            resultAllParticipants: currentGroup.resultAllParticipants
          };

          // validate participant is in correct group based on age
          if (participant.age) {
            if (
              participant.age < currentGroup.ageMin ||
              participant.age > currentGroup.ageMax
            ) {
              participantsInWrongGroup.push(participant);
            }
          }
        }
      });
    }

    return { groupResults, participantsInWrongGroup };
  };

  // validate total number of participants vs job desired number of participants
  const validateNumParticipants = () => {
    if (job.numberOfParticipants) {
      const numRequired = job.numberOfParticipants;
      const numParticipants = participants.length;
      return numRequired - numParticipants;
    }
    return 0;
  };

  const totalGenderDistributionResult = validateTotalGenderDistribution();
  const ageGroupGenderDifference = validateAgeGroupGenderDifference();
  const groupResult = validateParticipantsData();
  const numberParticipantsResult = validateNumParticipants();
  const groupValidationUI: React.ReactElement[] = [];

  const proctorsUI = validateProctors().map((proctor) => {
    return <li key={`proctor_${proctor}`}>{proctor}</li>;
  });

  const sitesUI = validateSites().map((siteId) => {
    const siteName =
      sites.find((site) => site.id === siteId)?.name ?? 'Unknown';
    return <li key={`site_${siteId}`}>{siteName}</li>;
  });

  const incorrectGroupSelections = groupResult.participantsInWrongGroup.map(
    (participant) => {
      return (
        <li
          key={`${participant.id}_wrongGroup`}
        >{`Sample # ${participant.sampleId}`}</li>
      );
    }
  );

  // generate markup around validated data
  Object.entries(groupResult.groupResults).forEach((result) => {
    let groupPercentageText = null;
    if (result[1].resultAllParticipants > 0) {
      groupPercentageText = 'Too many participants';
    } else if (result[1].resultAllParticipants < 0) {
      groupPercentageText = 'Too few participants';
    }
    if (groupPercentageText) {
      groupValidationUI.push(
        <>
          <Typography>{`Group ${result[0]}`}</Typography>
          <ul>
            {groupPercentageText && (
              <li key={`item-${result[0]}-percentage`}>
                {groupPercentageText}
              </li>
            )}
          </ul>
        </>
      );
    }
  });

  const everythingIsValid =
    numberParticipantsResult <= 0 &&
    incorrectGroupSelections.length == 0 &&
    proctorsUI.length == 0 &&
    sitesUI.length == 0 &&
    totalGenderDistributionResult.result &&
    ageGroupGenderDifference.result &&
    groupValidationUI.length == 0;

  // things to check
  /*
    - num participants
    - gender validation
    - age group validation
    - age group percentage
    - percent per site (BIRDDOG-89)
    - percent per proctor (BIRDDOG-88)
  */
  return (
    <Dialog open>
      <DialogTitle>{'Validation Results'}</DialogTitle>
      <Divider />
      <DialogContent>
        {numberParticipantsResult > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Number of Participants:
            </Typography>
            <ul>
              <li>
                <Typography>{`Need ${numberParticipantsResult} more participants`}</Typography>
              </li>
            </ul>
          </>
        )}
        {incorrectGroupSelections.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Participants in Wrong Groups:
            </Typography>
            <ul>{incorrectGroupSelections}</ul>
          </>
        )}
        {proctorsUI.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Proctors with too many participants:
            </Typography>
            <ul>{proctorsUI}</ul>
          </>
        )}
        {sitesUI.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Sites with too many participants:
            </Typography>
            <ul>{sitesUI}</ul>
          </>
        )}
        {!totalGenderDistributionResult.result && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Total Sex Distribution
            </Typography>
            <Box sx={{ marginLeft: '16px', marginBottom: '16px' }}>
              <Typography>
                {totalGenderDistributionResult.diff < 0
                  ? 'Too many females.'
                  : 'Too many males.'}
              </Typography>
              <Typography>{`Found ${
                totalGenderDistributionResult.percentMale * 100
              }% male but require ${
                totalGenderDistributionResult.target * 100
              }%.`}</Typography>
            </Box>
          </>
        )}
        {!ageGroupGenderDifference.result && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Age Group Sex Distribution
            </Typography>
            <Box sx={{ marginLeft: '16px', marginBottom: '16px' }}>
              {ageGroupGenderDifference.ageGroupStats
                .filter((ageGroup) => !ageGroup.valid)
                .map((ageGroup) => {
                  return (
                    <Typography>
                      Age Group {ageGroup.groupNumber} has {ageGroup.males}
                      &nbsp;males and {ageGroup.females} females, <br />a
                      difference of greater than 10%.
                    </Typography>
                  );
                })}
            </Box>
          </>
        )}
        {groupValidationUI.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              Group Validation:
            </Typography>
            <List subheader={<li />}>{groupValidationUI}</List>
          </>
        )}
        {everythingIsValid && (
          <Typography>All participant data is valid.</Typography>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={hideDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ValidatorsDialog;
