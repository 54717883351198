import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetJobCategoriesQuery } from './__generated__/GetJobCategoriesQuery.graphql';

const getJobCategoriesQuery = graphql`
  query GetJobCategoriesQuery {
    getJobCategories {
      id
      name
      description
      reportType
      parentId
      methodsOfUse {
        id
        description
      }
      procedures {
        id
        description
      }
      easeOfUseRatings {
        id
        description
      }
      ageGroups {
        id
        groupNumber
        beginAge
        endAge
        groupPercentage
      }
    }
  }
`;

const useGetJobCategoriesQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetJobCategoriesQuery>(
    getJobCategoriesQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getJobCategories;
};

export { useGetJobCategoriesQuery };
