import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography
} from '@mui/material';
import Button from '../../components/Button';
import Job from '../../models/Job';
import Participant from '../../models/Participant';
import AgeGroup from '../../models/AgeGroup';
import { RequiredFieldsDialogProps } from './RequiredFieldsDialogProps';
const RequiredFieldsDialog = ({
  hideDialog,
  missingFields
}: RequiredFieldsDialogProps) => {
  const createList = (arr: string[]) => {
    return (
      <ul>
        {arr.map((strVal) => {
          return <li key={strVal}>{strVal}</li>;
        })}
      </ul>
    );
  };
  if (missingFields) {
    return (
      <Dialog open>
        <DialogTitle>{'Missing Required Fields'}</DialogTitle>
        <DialogContent>
          {missingFields.general.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>General:</Typography>
              {createList(missingFields.general)}
            </>
          )}
          {missingFields.testSettings.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>
                Test Settings:
              </Typography>
              {createList(missingFields.testSettings)}
            </>
          )}
          {missingFields.testLists.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>Test Lists:</Typography>
              {createList(missingFields.testLists)}
            </>
          )}
          {missingFields.samplePicture.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>
                Sample Picture:
              </Typography>
              {createList(missingFields.samplePicture)}
            </>
          )}
          {missingFields.participants.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>Participants:</Typography>
              {createList(missingFields.participants)}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};
export default RequiredFieldsDialog;
