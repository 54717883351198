import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateRegionMutation } from './__generated__/UpdateRegionMutation.graphql';

const updateRegionMutation = graphql`
  mutation UpdateRegionMutation($id: ID!, $name: String!, $enabled: Boolean!) {
    updateRegion(input: { id: $id, name: $name, enabled: $enabled }) {
      id
      name
      enabled
    }
  }
`;

const useUpdateRegionMutation = () =>
  useMutation<UpdateRegionMutation>(updateRegionMutation);

export { useUpdateRegionMutation };
