import { ButtonProps, Toolbar } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import styled from '@emotion/styled';

import DataToolbarProps from './DataToolbarProps';
import { useAuthentication } from '../../api/authentication';
import Button from '../Button';
import SearchInput from '../SearchInput';
import SelectInput from '../SelectInput';
import { withPermission } from '../Permissions';

const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  padding: 8px;
`;

const ButtonWithPermission = withPermission<ButtonProps>(Button);

const DataToolbar = ({
  className,
  dataType,
  searchOptions,
  categories,
  statuses,
  defaultStatus,
  isDisable,
  children,
  selectedId,
  onSearchChange,
  onStatusChange,
  onCategoryChange,
  onAdd,
  onEdit,
  onView,
  onDelete
}: DataToolbarProps) => {
  const { user } = useAuthentication();

  return (
    <Toolbar className={className}>
      <ToolbarSection>
        <SearchInput searchOptions={searchOptions} onChange={onSearchChange} />
        {categories && (
          <SelectInput
            label='Category'
            options={['Any', ...categories.map((category) => category.name)]}
            onSelect={(categoryName) => {
              if (onCategoryChange) {
                const category = categories.find(
                  (category) => category.name === categoryName
                );
                if (categoryName === 'Any' || !category) onCategoryChange(null);
                else onCategoryChange(category);
              }
            }}
          />
        )}
        {statuses && (
          <SelectInput
            label='Status'
            options={statuses}
            defaultValue={defaultStatus}
            onSelect={onStatusChange}
          />
        )}
      </ToolbarSection>
      <ToolbarSection>
        {children}
        {user && (
          <>
            <ButtonWithPermission
              role={user.role}
              type={dataType}
              operation='create'
              componentProps={{ onClick: onAdd }}
            >
              Add <AddCircleOutline />
            </ButtonWithPermission>
            <ButtonWithPermission
              role={user.role}
              type={dataType}
              operation='update'
              componentProps={{ onClick: onEdit, disabled: !selectedId }}
            >
              Edit
            </ButtonWithPermission>
            {onView && (
              <ButtonWithPermission
                role={user.role}
                type={dataType}
                operation='read'
                componentProps={{ onClick: onView, disabled: !selectedId }}
              >
                Open
              </ButtonWithPermission>
            )}
            <ButtonWithPermission
              role={user.role}
              type={dataType}
              operation={isDisable ? 'disable' : 'delete'}
              componentProps={{ onClick: onDelete, disabled: !selectedId }}
            >
              {isDisable ? 'Disable' : 'Delete'}
            </ButtonWithPermission>
          </>
        )}
      </ToolbarSection>
    </Toolbar>
  );
};

const StyledDataToolbar = styled(DataToolbar)`
  & {
    padding: 0;
  }
  margin: 0 92px;
  display: flex;
  justify-content: space-between;
`;

export default StyledDataToolbar;
