import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateCustomerCategoryMutation } from './__generated__/CreateCustomerCategoryMutation.graphql';

const createCustomerCategoryMutation = graphql`
  mutation CreateCustomerCategoryMutation(
    $name: String!
    $description: String
  ) {
    createCustomerCategory(input: { name: $name, description: $description }) {
      id
      name
      description
    }
  }
`;

const useCreateCustomerCategoryMutation = () =>
  useMutation<CreateCustomerCategoryMutation>(createCustomerCategoryMutation);

export { useCreateCustomerCategoryMutation };
