import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteRegionMutation } from './__generated__/DeleteRegionMutation.graphql';

const deleteRegionMutation = graphql`
  mutation DeleteRegionMutation($id: ID!) {
    deleteRegion(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteRegionMutation = () =>
  useMutation<DeleteRegionMutation>(deleteRegionMutation);

export { useDeleteRegionMutation };
