import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetProctorsQuery } from './__generated__/GetProctorsQuery.graphql';

const getProctorsQuery = graphql`
  query GetProctorsQuery {
    getProctors {
      id
      key
      firstName
      lastName
      state
      regions {
        id
        name
        enabled
      }
    }
  }
`;

const useGetProctorsQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetProctorsQuery>(
    getProctorsQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getProctors;
};

export { useGetProctorsQuery };
