import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetJobsQuery } from './__generated__/GetJobsQuery.graphql';

const getJobsQuery = graphql`
  query GetJobsQuery {
    getJobs {
      id
      key
      customerId
      customer {
        id
        key
        name
        categoryId
      }
      description
      notes
      jobCategoryId
      jobCategory {
        id
        name
        description
        reportType
        parentId
        methodsOfUse {
          description
        }
        procedures {
          description
        }
        easeOfUseRatings {
          description
        }
        ageGroups {
          groupNumber
          beginAge
          endAge
          groupPercentage
        }
      }
      preparedById
      preparedBy {
        id
        email
        username
        role
        isActive
      }
      status
      regions {
        id
        name
        enabled
      }
      participants {
        id
        jobId
        createdById
        siteId
        sampleId
        custSampleNum
        sex
        groupNum
        testDateTime
        birthDate
        age
        zip
        participantData
        proctorId
      }
      samplePicture
      sampleType
      submitter
      testType
      participantType
      requiredParticipants
      numberOfParticipants
      maxParticipantsPerSite
      maxParticipantsPerProctor
      customerTest
      reportTree
      testObjectId
      requiredTorque
      disclaimer
      otherInfo
      reportHistory
      testDescription
      objectives
      procedureNotes
      resultsDiscussion
      endDate
      methodsOfUse {
        description
      }
      procedures {
        description
      }
      easeOfUseRatings {
        description
      }
      ageGroups {
        groupNumber
        beginAge
        endAge
        groupPercentage
      }
      updatedAt
      updatedBy {
        email
        role
        isActive
        username
      }
      parent {
        id
        key
      }
      children {
        id
        key
      }
    }
  }
`;

const useGetJobsQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetJobsQuery>(
    getJobsQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getJobs;
};

export { useGetJobsQuery };
