interface Procedure {
  id?: string;
  description: string;
}

const defaultProcedures = [
  { id: '0', description: 'Pkg1 Close' },
  { id: '1', description: 'Pkg2 Close' },
  { id: '2', description: 'Pkg1 Open' },
  { id: '3', description: 'Pkg2 Open' },
  { id: '4', description: 'RC 1' },
  { id: '5', description: 'RC 2' }
];

export default Procedure;
export { defaultProcedures };
