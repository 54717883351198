/**
 * @generated SignedSource<<e07868ba0dd0337a2f4feb24a6caec3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetJobCategoriesQuery$variables = {};
export type GetJobCategoriesQuery$data = {
  readonly getJobCategories: ReadonlyArray<{
    readonly ageGroups: ReadonlyArray<{
      readonly beginAge: number;
      readonly endAge: number;
      readonly groupNumber: number;
      readonly groupPercentage: number;
      readonly id: string;
    }>;
    readonly description: string | null;
    readonly easeOfUseRatings: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
    }>;
    readonly id: string;
    readonly methodsOfUse: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
    }>;
    readonly name: string;
    readonly parentId: string | null;
    readonly procedures: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
    }>;
    readonly reportType: string | null;
  }>;
};
export type GetJobCategoriesQuery = {
  response: GetJobCategoriesQuery$data;
  variables: GetJobCategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JobCategory",
    "kind": "LinkedField",
    "name": "getJobCategories",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MethodOfUse",
        "kind": "LinkedField",
        "name": "methodsOfUse",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Procedure",
        "kind": "LinkedField",
        "name": "procedures",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EaseOfUseRating",
        "kind": "LinkedField",
        "name": "easeOfUseRatings",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AgeGroup",
        "kind": "LinkedField",
        "name": "ageGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beginAge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupPercentage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetJobCategoriesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetJobCategoriesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6e1c4504db99c24c053ac0cf9c955926",
    "id": null,
    "metadata": {},
    "name": "GetJobCategoriesQuery",
    "operationKind": "query",
    "text": "query GetJobCategoriesQuery {\n  getJobCategories {\n    id\n    name\n    description\n    reportType\n    parentId\n    methodsOfUse {\n      id\n      description\n    }\n    procedures {\n      id\n      description\n    }\n    easeOfUseRatings {\n      id\n      description\n    }\n    ageGroups {\n      id\n      groupNumber\n      beginAge\n      endAge\n      groupPercentage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae4ee5626c6f216e7cc7c6246d703bb4";

export default node;
