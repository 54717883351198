import graphql from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';

import { GetUsersQuery } from './__generated__/GetUsersQuery.graphql';

const getUsersQuery = graphql`
  query GetUsersQuery {
    getUsers {
      id
      email
      username
      fullName
      title
      role
      isActive
      signatureImage
      regions {
        id
        name
        enabled
      }
    }
  }
`;

const useGetUsersQuery = ({
  fetchKey
}: {
  fetchKey?: string | number | undefined;
}) => {
  return useLazyLoadQuery<GetUsersQuery>(
    getUsersQuery,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' }
  ).getUsers;
};

export { useGetUsersQuery };
