import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { DeleteSiteMutation } from './__generated__/DeleteSiteMutation.graphql';

const deleteSiteMutation = graphql`
  mutation DeleteSiteMutation($id: ID!) {
    deleteSite(id: $id) {
      id @deleteRecord
    }
  }
`;

const useDeleteSiteMutation = () =>
  useMutation<DeleteSiteMutation>(deleteSiteMutation);

export { useDeleteSiteMutation };
