import { styled } from '@mui/material';

const Toaster = styled('div')`
  position: absolute;
  bottom: 48px;
  left: 32px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default Toaster;
