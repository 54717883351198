import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CopyJobMutation } from './__generated__/CopyJobMutation.graphql';

const copyJobMutation = graphql`
  mutation CopyJobMutation($id: ID!, $key: String!) {
    copyJob(input: { id: $id, key: $key }) {
      id
    }
  }
`;

const useCopyJobMutation = () => useMutation<CopyJobMutation>(copyJobMutation);

export { useCopyJobMutation };
