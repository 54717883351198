import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button
} from '@mui/material';
import { useForm } from 'react-hook-form';

import CopyJobDialogProps from './CopyJobDialogProps';

const CopyJobDialog = ({ job, onCancel, onSubmit }: CopyJobDialogProps) => {
  const defaultValues = {
    key: ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>Copy Job - {job.key}</DialogTitle>
      <DialogContent>
        <TextField
          label='New ID *'
          type='text'
          margin='dense'
          helperText={errors.key ? 'Field is required.' : ''}
          error={!!errors.key}
          fullWidth
          autoFocus
          {...register('key', { required: true })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            if (job.id) {
              onSubmit({ id: job.id, ...fields });
            }
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyJobDialog;
