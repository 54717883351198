import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Toolbar,
  Button,
  Menu,
  MenuItem,
  styled,
  ButtonProps
} from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';

// TODO - Replace with actual logo.
import logo from './logo.png';
import { useAuthentication } from '../../api/authentication';
import { withPermission } from '../Permissions';
import HeaderProps from './HeaderProps';

const TitleBar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 76px;
  & {
    padding: 0 40px;
  }
`;

const MyAccountButton = styled(Button)`
  text-transform: none;
  font-size: 20px;
  gap: 4px;
`;

const MyAccountIcon = styled(AccountCircleOutlined)`
  width: 32px;
  height: 32px;
`;

const NavBar = styled(Toolbar)`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  align-items: center;
  height: 43px;
  gap: 80px;
  & {
    min-height: unset;
    padding: 0 40px;
  }
`;

const NavButton = styled(Button)`
  text-transform: none;
  font-size: 15px;
  color: white;
`;

const ButtonWithPermission = withPermission<ButtonProps>(NavButton);

const Header = ({ user }: HeaderProps) => {
  const [accountMenuAnchor, setAccountMenuAnchor] =
    useState<HTMLButtonElement | null>();
  const { logout } = useAuthentication();
  const navigate = useNavigate();

  return (
    <>
      <TitleBar>
        <img width={202} height={52} src={logo} />
        <MyAccountButton
          onClick={(event) => setAccountMenuAnchor(event.currentTarget)}
        >
          <MyAccountIcon />
          My Account
        </MyAccountButton>
        <Menu
          anchorEl={accountMenuAnchor}
          open={!!accountMenuAnchor}
          onClose={() => setAccountMenuAnchor(null)}
        >
          <MenuItem
            onClick={() => {
              logout();
              navigate('/login');
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </TitleBar>
      <NavBar>
        <ButtonWithPermission
          role={user?.role}
          type='customers'
          operation='read'
          componentProps={{ onClick: () => navigate('/customers') }}
        >
          Customers
        </ButtonWithPermission>
        <ButtonWithPermission
          role={user?.role}
          type='jobs'
          operation='read'
          componentProps={{ onClick: () => navigate('/jobs') }}
        >
          Jobs
        </ButtonWithPermission>
        <ButtonWithPermission
          role={user?.role}
          type='sites'
          operation='read'
          componentProps={{ onClick: () => navigate('/sites') }}
        >
          Sites
        </ButtonWithPermission>
        {user?.role === 'admin' && (
          <>
            <ButtonWithPermission
              role={user?.role}
              type='regions'
              operation='read'
              componentProps={{ onClick: () => navigate('/regions') }}
            >
              Regions
            </ButtonWithPermission>
            <ButtonWithPermission
              role={user?.role}
              type='proctors'
              operation='read'
              componentProps={{ onClick: () => navigate('/proctors') }}
            >
              Proctors
            </ButtonWithPermission>
          </>
        )}
        <ButtonWithPermission
          role={user?.role}
          type='users'
          operation='read'
          componentProps={{ onClick: () => navigate('/users') }}
        >
          Users
        </ButtonWithPermission>
      </NavBar>
    </>
  );
};

export default Header;
