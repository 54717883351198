interface HierarchicalCategory {
  id?: string;
  name: string;
  parentId: string | null;
  children?: HierarchicalCategory[];
}

const convertToTree = <T extends HierarchicalCategory>(
  categories: readonly T[]
) => {
  categories = categories.map((category): T => ({ ...category, children: [] }));
  categories.forEach((category) => {
    const parent = categories.find((parent) => parent.id === category.parentId);
    if (!parent || !parent.children) return;
    parent.children.push(category);
  });
  return categories.filter((category) => !category.parentId);
};

export default HierarchicalCategory;
export { convertToTree };
