import { useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

import LinkedGridEditDateCellProps from './LinkedGridEditDateCellProps';

const StyledInput = styled('input')`
  font-family: 'Open Sans';
  height: 100%;
`;

const LinkedGridEditDateCell = ({
  field,
  linkedField,
  linkedFieldSetter,
  centuryPrefix,
  ...props
}: LinkedGridEditDateCellProps) => {
  const apiRef = useGridApiContext();
  const datePickerRef = useRef<HTMLInputElement>(null);

  let date = '';

  // If value is a single character then we are entering edit mode via
  // alphanumeric button press and should not parse it as a date.
  if (props?.value?.length !== 1 && Date.parse(props?.value)) {
    date = format(new Date(props?.value), 'MM/dd/yyyy');
  } else date = props.value;

  // If value is a single character and invalid, set cell value to empty string.
  useEffect(() => {
    if (props?.value?.length === 1) {
      apiRef.current.setEditCellValue({
        id: props.id,
        field,
        value: ''
      });
    }
  }, []);

  // Focus on the input when the cell first enters edit mode.
  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.focus();
    }
  }, [datePickerRef]);

  return (
    <StyledInput
      ref={datePickerRef}
      defaultValue={date}
      onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRow = { ...props.row };
        if (event.target.value) {
          const [month, day, year] = event.target.value.split(/[-/]+/);

          if (!month || !day || !year) {
            return;
          }

          // The autocompleted year is determined by whether the participant is
          // an adult or a child. Adult birth years start with 19 by default,
          // and children birth years always start with 20.
          let newYear = year;
          if (centuryPrefix && year.length === 2) {
            newYear = centuryPrefix + year;
          } else if (year.length === 2) {
            // For other uses where century prefix is not provided, like the test date, we'll default to something reasonable.
            newYear = '20' + year;
          }

          if (
            isNaN(Number(newYear)) ||
            isNaN(Number(month)) ||
            Number(month) > 12 ||
            isNaN(Number(day)) ||
            Number(month) > 31
          ) {
            apiRef.current.setEditCellValue(
              { id: props.id, field, value: '' },
              event
            );
            return;
          }

          const newDate = new Date();
          newDate.setFullYear(Number(newYear), Number(month) - 1, Number(day));
          newDate.setHours(12, 0, 0, 0);

          if (newDate.toString() === 'Invalid Date') {
            apiRef.current.setEditCellValue(
              { id: props.id, field, value: '' },
              event
            );
            return;
          }

          apiRef.current.setEditCellValue(
            { id: props.id, field, value: newDate },
            event
          );
          newRow[linkedField] = linkedFieldSetter(props.row, newDate);
          await apiRef.current.updateRows([newRow]);
        }
      }}
    />
  );
};

export default LinkedGridEditDateCell;
