import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { UpdateTestObjectMutation } from './__generated__/UpdateTestObjectMutation.graphql';

const updateTestObjectMutation = graphql`
  mutation UpdateTestObjectMutation($id: ID!, $name: String!) {
    updateTestObject(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

const useUpdateTestObjectMutation = () =>
  useMutation<UpdateTestObjectMutation>(updateTestObjectMutation);

export { useUpdateTestObjectMutation };
