import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';

import AlertDialogProps from './AlertDialogProps';

const AlertDialog = ({
  title,
  content,
  cancelLabel,
  submitLabel,
  onCancel,
  onSubmit
}: AlertDialogProps) => {
  return (
    <Dialog maxWidth='xs' open>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel}</Button>
        <Button onClick={onSubmit}>{submitLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
