import { ComponentType } from 'react';
import permissions from 'config/permissions';

import withPermissionProps from './withPermissionProps';

const withPermission =
  <T extends Record<string, any>>(WrappedComponent: ComponentType<T>) =>
  ({
    role,
    type,
    operation,
    children,
    componentProps
  }: withPermissionProps & { componentProps?: T }) => {
    const permissionsIndex = permissions as { [key: string]: any };
    if (role && permissions && permissionsIndex[role][type][operation])
      return (
        <WrappedComponent {...(componentProps as T)}>
          {children}
        </WrappedComponent>
      );
    else return null; // Do not render if we do not have permission to.
  };

export default withPermission;
