import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

Sentry.init({
  dsn: 'https://85492e38eab442c59a0d83abb30c8775@o427779.ingest.sentry.io/4504793033146368',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production'
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
