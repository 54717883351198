/**
 * @generated SignedSource<<7866d40c6260ef6822a8f90ba811109b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserMutation$variables = {
  email: string;
  fullName?: string | null;
  id: string;
  isActive?: boolean | null;
  password?: string | null;
  regionIds?: ReadonlyArray<string | null> | null;
  role?: string | null;
  signatureImage?: string | null;
  title?: string | null;
  username?: string | null;
};
export type UpdateUserMutation$data = {
  readonly updateUser: {
    readonly email: string;
    readonly fullName: string | null;
    readonly id: string;
    readonly isActive: boolean | null;
    readonly regions: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly role: string;
    readonly signatureImage: string | null;
    readonly title: string | null;
    readonly username: string | null;
  };
};
export type UpdateUserMutation = {
  response: UpdateUserMutation$data;
  variables: UpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fullName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isActive"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signatureImage"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          },
          {
            "kind": "Variable",
            "name": "fullName",
            "variableName": "fullName"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "isActive",
            "variableName": "isActive"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          },
          {
            "kind": "Variable",
            "name": "regionIds",
            "variableName": "regionIds"
          },
          {
            "kind": "Variable",
            "name": "role",
            "variableName": "role"
          },
          {
            "kind": "Variable",
            "name": "signatureImage",
            "variableName": "signatureImage"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          },
          {
            "kind": "Variable",
            "name": "username",
            "variableName": "username"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signatureImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserMutation",
    "selections": (v11/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateUserMutation",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "ae7b7d3d512916d0ee08da3c94fb4988",
    "id": null,
    "metadata": {},
    "name": "UpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserMutation(\n  $id: ID!\n  $email: String!\n  $username: String\n  $fullName: String\n  $title: String\n  $password: String\n  $role: String\n  $isActive: Boolean\n  $regionIds: [ID]\n  $signatureImage: String\n) {\n  updateUser(input: {id: $id, email: $email, username: $username, fullName: $fullName, title: $title, password: $password, role: $role, isActive: $isActive, regionIds: $regionIds, signatureImage: $signatureImage}) {\n    id\n    email\n    username\n    fullName\n    title\n    role\n    isActive\n    signatureImage\n    regions {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42b24d743eb54a3fb9f450baa642eea3";

export default node;
