import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  InputLabel
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import RegionDialogProps from './RegionDialogProps';

const RegionDialog = ({ region, onCancel, onSubmit }: RegionDialogProps) => {
  // if (!region) return null;
  const defaultValues = {
    name: region?.name || '',
    enabled: region?.enabled || true
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <Dialog open>
      <DialogTitle>{region ? 'Edit Region' : 'Add Region'}</DialogTitle>
      <DialogContent>
        <TextField
          label='Name'
          type='text'
          margin='dense'
          helperText={errors.name ? 'Field is required.' : ''}
          error={!!errors.name}
          fullWidth
          autoFocus
          {...register('name', { required: true })}
        />
        <InputLabel>Active</InputLabel>
        <Controller
          name='enabled'
          control={control}
          render={({ field }) => {
            return (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleSubmit((fields) => {
            onSubmit({ id: region?.id, ...fields });
          })}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegionDialog;
