import { Box, Button, IconButton, styled } from '@mui/material';
import { Clear } from '@mui/icons-material';

import SignaturePickerProps from './SignaturePickerProps';
import { fileToBase64 } from '../../utils/fileHandlers';

const StyledBox = styled(Box)`
  display: flex;
  position: relative;
  aspect-ratio: 4.5 / 1;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled('img')`
  max-height: 100%;
`;

const ClearButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const SignaturePicker = ({
  signatureImage,
  onUpload,
  onClear
}: SignaturePickerProps) => {
  return (
    <StyledBox>
      {!signatureImage && (
        <Button component='label'>
          Upload
          <input
            hidden
            accept='image/*'
            type='file'
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (!event.target.files || !event.target.files[0]) {
                return;
              }
              const file = event.target.files[0];
              const base64String = await fileToBase64(file);
              onUpload(base64String);
            }}
          />
        </Button>
      )}
      {signatureImage && <StyledImage src={signatureImage} />}
      {signatureImage && (
        <ClearButton onClick={onClear}>
          <Clear />
        </ClearButton>
      )}
    </StyledBox>
  );
};

export default SignaturePicker;
