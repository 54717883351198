// Calculate child participant age in months based on formula found here:
// https://www.law.cornell.edu/cfr/text/16/1700.20
const calculateChildAge = (testDate: Date, birthDate: Date) => {
  const years = testDate.getFullYear() - birthDate.getFullYear();
  const months = testDate.getMonth() - birthDate.getMonth();
  const days = testDate.getDate() - birthDate.getDate();
  let age = years * 12 + months;
  if (days >= 16) {
    age++;
  } else if (days <= -16) {
    age--;
  }
  return age;
};

const calculateAdultAge = (testDate: Date, birthDate: Date) => {
  let age = testDate.getFullYear() - birthDate.getFullYear();
  const months = testDate.getMonth() - birthDate.getMonth();
  if (
    months < 0 ||
    (months === 0 && testDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export { calculateChildAge, calculateAdultAge };
