/**
 * @generated SignedSource<<2bbbb6cbb7315731173f803c637f61ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateParticipantMutation$variables = {
  age?: number | null;
  birthDate?: string | null;
  createdById?: string | null;
  custSampleNum?: string | null;
  groupNum?: number | null;
  id: string;
  jobId?: string | null;
  participantData?: string | null;
  proctorId: string;
  sampleId?: string | null;
  sex?: string | null;
  siteId?: string | null;
  testDateTime?: string | null;
  zip?: string | null;
};
export type UpdateParticipantMutation$data = {
  readonly updateParticipant: {
    readonly age: number | null;
    readonly birthDate: string | null;
    readonly createdById: string;
    readonly custSampleNum: string | null;
    readonly groupNum: number | null;
    readonly id: string;
    readonly job: {
      readonly id: string;
      readonly key: string;
    } | null;
    readonly jobId: string | null;
    readonly participantData: string | null;
    readonly proctorId: string | null;
    readonly sampleId: string | null;
    readonly sex: string | null;
    readonly site: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly siteId: string;
    readonly testDateTime: string | null;
    readonly zip: string | null;
  };
};
export type UpdateParticipantMutation = {
  response: UpdateParticipantMutation$data;
  variables: UpdateParticipantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "age"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "birthDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdById"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "custSampleNum"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupNum"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "participantData"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "proctorId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sampleId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sex"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "testDateTime"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zip"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "age",
            "variableName": "age"
          },
          {
            "kind": "Variable",
            "name": "birthDate",
            "variableName": "birthDate"
          },
          {
            "kind": "Variable",
            "name": "createdById",
            "variableName": "createdById"
          },
          {
            "kind": "Variable",
            "name": "custSampleNum",
            "variableName": "custSampleNum"
          },
          {
            "kind": "Variable",
            "name": "groupNum",
            "variableName": "groupNum"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "jobId",
            "variableName": "jobId"
          },
          {
            "kind": "Variable",
            "name": "participantData",
            "variableName": "participantData"
          },
          {
            "kind": "Variable",
            "name": "proctorId",
            "variableName": "proctorId"
          },
          {
            "kind": "Variable",
            "name": "sampleId",
            "variableName": "sampleId"
          },
          {
            "kind": "Variable",
            "name": "sex",
            "variableName": "sex"
          },
          {
            "kind": "Variable",
            "name": "siteId",
            "variableName": "siteId"
          },
          {
            "kind": "Variable",
            "name": "testDateTime",
            "variableName": "testDateTime"
          },
          {
            "kind": "Variable",
            "name": "zip",
            "variableName": "zip"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "Participant",
    "kind": "LinkedField",
    "name": "updateParticipant",
    "plural": false,
    "selections": [
      (v14/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdById",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jobId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siteId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sampleId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "custSampleNum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sex",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupNum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "testDateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "birthDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "age",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "zip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "participantData",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "proctorId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateParticipantMutation",
    "selections": (v15/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v11/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v10/*: any*/),
      (v4/*: any*/),
      (v12/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v13/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateParticipantMutation",
    "selections": (v15/*: any*/)
  },
  "params": {
    "cacheID": "bec56fc5a04c84ad65d85de13c193584",
    "id": null,
    "metadata": {},
    "name": "UpdateParticipantMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateParticipantMutation(\n  $id: ID!\n  $jobId: ID\n  $createdById: ID\n  $siteId: ID\n  $sampleId: String\n  $custSampleNum: String\n  $sex: String\n  $groupNum: Int\n  $testDateTime: String\n  $birthDate: String\n  $age: Int\n  $zip: String\n  $participantData: String\n  $proctorId: ID!\n) {\n  updateParticipant(input: {id: $id, jobId: $jobId, createdById: $createdById, siteId: $siteId, sampleId: $sampleId, custSampleNum: $custSampleNum, sex: $sex, groupNum: $groupNum, testDateTime: $testDateTime, birthDate: $birthDate, age: $age, zip: $zip, participantData: $participantData, proctorId: $proctorId}) {\n    id\n    job {\n      id\n      key\n    }\n    createdById\n    site {\n      id\n      name\n    }\n    jobId\n    siteId\n    sampleId\n    custSampleNum\n    sex\n    groupNum\n    testDateTime\n    birthDate\n    age\n    zip\n    participantData\n    proctorId\n  }\n}\n"
  }
};
})();

(node as any).hash = "93675778fdb35784879d38005588c396";

export default node;
