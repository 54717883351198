import { Checkbox, FormControlLabel, styled } from '@mui/material';
import HierarchicalTreePickerProps from './HierarchicalTreePickerProps';

interface CategoryListProps {
  depth: number;
}

const TreeNode = styled('div')<CategoryListProps>`
  padding-left: ${({ depth }) => (depth > 0 ? '16px' : '0')};
  width: calc(100% - 16px * ${({ depth }) => depth});
`;

const HierarchicalTreePicker = ({
  categories,
  selectedCategories,
  onCategoryClick,
  depth
}: HierarchicalTreePickerProps) => {
  return (
    <>
      {categories.map((category) => {
        return (
          <TreeNode key={category.id} depth={depth}>
            <FormControlLabel
              label={category.name}
              control={
                <Checkbox
                  checked={selectedCategories.some(
                    (selectedCategory) => category.id === selectedCategory.id
                  )}
                  onClick={() => onCategoryClick(category)}
                />
              }
            />
            {category.children && (
              <HierarchicalTreePicker
                categories={category.children}
                selectedCategories={selectedCategories}
                onCategoryClick={onCategoryClick}
                depth={depth + 1}
              />
            )}
          </TreeNode>
        );
      })}
    </>
  );
};

export default HierarchicalTreePicker;
