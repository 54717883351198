import graphql from 'babel-plugin-relay/macro';
import { useMutation } from 'react-relay';

import { CreateJobCategoryMutation } from './__generated__/CreateJobCategoryMutation.graphql';

const createJobCategoryMutation = graphql`
  mutation CreateJobCategoryMutation(
    $name: String!
    $description: String
    $reportType: String
    $parentId: ID
    $methodsOfUse: [CreateMethodOfUseInput]
    $procedures: [CreateProcedureInput]
    $easeOfUseRatings: [CreateEaseOfUseRatingInput]
    $ageGroups: [CreateAgeGroupInput]
  ) {
    createJobCategory(
      input: {
        name: $name
        description: $description
        reportType: $reportType
        parentId: $parentId
        methodsOfUse: $methodsOfUse
        procedures: $procedures
        easeOfUseRatings: $easeOfUseRatings
        ageGroups: $ageGroups
      }
    ) {
      id
      name
      description
      reportType
      parentId
      methodsOfUse {
        description
      }
      procedures {
        description
      }
      easeOfUseRatings {
        description
      }
      ageGroups {
        groupNumber
        beginAge
        endAge
        groupPercentage
      }
    }
  }
`;

const useCreateJobCategoryMutation = () =>
  useMutation<CreateJobCategoryMutation>(createJobCategoryMutation);

export { useCreateJobCategoryMutation };
